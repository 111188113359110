<template>
  <div class="d-flex w-100">
    <div class="link d-flex justify-content-start w-100 align-items-center" v-if="!isEdit">
      <el-button class="mx-2" type="text" icon="el-icon-document"> </el-button>

      <el-tooltip
        class="item"
        effect="dark"
        :content="`${value}`"
        placement="top-start"
      >
        <span
          class="text-truncate cursor-pointer"
          @click="previewFile"
        >
          {{ value }}.{{ ext }} </span
        >
      </el-tooltip>
      <el-tooltip
      class="item"
      effect="dark"
      :content="`编辑 ${value}`"
      placement="top-start"
    >
      <el-button
        v-if="[0, 5, 9].includes(user.role)"
        type="text"
        icon="el-icon-edit"
        class="mx-2"
        @click="editItem"
      >
      </el-button>
    </el-tooltip>
      <el-tooltip
        class="item"
        effect="dark"
        :content="`下载文件 ${value}`"
        placement="top-start"
      >
      <!-- v-if="[0, 5, 9].includes(user.role)" -->

        <el-button
          type="text"
        class="mx-2"
          icon="el-icon-download"
          @click="dwonloadItem"
        >
        </el-button>
      </el-tooltip>
    </div>
    <div class="w-100" v-else>
      <el-input :value="value" @input="chnageName"  style="width: 200px;"> </el-input>
      <el-button type="text" class="mx-2" @click="cancel"> 取消 </el-button>
      <el-button type="text" class="mx-2" @click="confirm"> 完成 </el-button>
    </div>
   
  </div>
</template>

<script>
export default {
  data() {
    return {
      isEdit: false,
      old_name:"",
      filename:""
    };
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    url: {
      type: String,
      default: "",
    },
    ext: {
      type: String,
      default: "",
    },
  },
  watch: {
    name(val){

      console.log("watch name",val)

      this.filename=this.name

    }
  },

  methods: {
    editItem(){
        this.old_name=this.value;
        this.isEdit=!this.isEdit;

        console.log(this.old_name)
    },
    chnageName(val){
      this.$emit("input",val)
    },
    previewFile() {
      this.$emit("preview")
  
    },
    dwonloadItem(){
      this.$emit("download")
    },
    confirm(){
      if(!this.value.trim()){
        return this.$message.error("文件名称不能为空")
      }
      this.isEdit=false;
      this.$emit("confirm")

    },
    cancel(){
        this.isEdit=false;
        this.value=this.old_name;
    }
  },
};
</script>
