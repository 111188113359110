import * as dayjs from "dayjs";

export function formatDate(val) {
  if (!val) {
    return null;
  }
  return dayjs(val).format("YYYY-MM-DD HH:mm:ss");
}

export function formatDateT(val) {
  if (!val) {
    return null;
  }
  let times = null;
  if (!Array.isArray(val)) {
    times = val && val.split(",");
  } else {
    times = val;
  }
  let timeStr = "";
  times.map((v) => {
    timeStr += " " + dayjs(v).format("YYYY-MM-DD");
    return v;
  });
  return timeStr;
}







export function formatDateH(val) {
  if (!val) {
    return null;
  }
  let times = null;
  if (!Array.isArray(val)) {
    times = val && val.split(",");
  } else {
    times = val;
  }
  let timeStr = "";
  times.map((v) => {
    timeStr += " " + dayjs(v).format("MM月DD日");
    return v;
  });
  return timeStr;
}

export function formatDateY(val) {
  if (!val) {
    return null;
  }
  return dayjs(val).format("YYYY-MM-DD");
}

export function formatMoney(num) {
  if (!num) {
    return 0;
  }
  num = num.toFixed(2);
  num = parseFloat(num);
  num = num.toLocaleString();
  return num; //返回的是字符串23,245.12保留2位小数
}

export function formatMoneyW(num) {
  if (!num) {
    return 0;
  }
  if (typeof num !== "number") {
    return num; // 如果不是数字，则直接返回原始值
  }
  if (num >= 10000) {
    const formattedAmount = (num / 10000).toFixed(2); // 将金额除以10000，并保留两位小数
    return formattedAmount + " 万元"; // 添加单位“万”
  }else {
    
  }
  return num.toString()+'元';
}

export function formatMoneyWNum(num) {
  if (!num) {
    return 0;
  }
  const formattedAmount = (Number(num) / 10000).toFixed(0); // 将金额除以10000，并保留两位小数
  return formattedAmount // 添加单位“万”
}


export function validMobile(val){
  const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
  return reg.test(val)
}
export function isMobile (){
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
}
