import Vue from "vue";
import Dictionary from "./Dictionary";
import InputHtml from "./InputHtml";
// import ElCalendar from "./ElCalendar";
import ImportButton from "./ImportButton";
import Import from "./Import";
import Menu from "./Menu";


Vue.component('import', Import); 
Vue.component('icon-menu', Menu); 
Vue.component('dictionary', Dictionary); 
Vue.component('avue-input-html', InputHtml); 
// Vue.component('avue-el-calendar', ElCalendar); 
Vue.component('avue-import-button', ImportButton); 


