<template>
  <div :class="{ 'pl-0': isArray }">
    <el-form
      class="mb-2 row"
      v-for="(value, i) in values"
      :key="`form-${i}`"
      v-bind="getFormProps()"
    >
      <el-form-item
        v-for="item in option.column.filter(v => v.display !== false)"
        v-bind="getFormItemProps(item)"
        :key="item.prop"
        class="col"
        
        :class="{[`col-sm-${(item.span || 12) / 2}`]: true}"
      >
        <slot :name="item.prop">
          <component
            :is="getCom(item)"
            :value="_.get(value, item.prop)"
            @input="v => changeValue(i, item, v)"
            v-bind="getProps(item)"
          >
          </component>
        </slot>
      </el-form-item>
      <el-form-item v-if="isArray">
        <el-button @click="remove(i)" v-if="option.delBtn !== false">删除</el-button>
        <el-button @click="add(i)" v-if="option.addBtn !== false">添加</el-button>
      </el-form-item>
    </el-form>
    <el-form-item v-if="isArray">
      <el-button @click="add(values.length - 1)"  v-if="option.addBtn !== false">添加</el-button>
    </el-form-item>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "FormBuilder",
  props: {
    option: {},
    value: {},
    type: {}
  },
  computed: {
    values() {
      return this.isArray ? this.value : [this.value];
    },
    isArray() {
      return this.type === "dynamic";
    }
  },
  methods: {
    getFormProps() {
      let extraProps = {};
      if (this.isArray) {
        // console.log(this.type, this.isArray)
        Object.assign(extraProps, {
          labelPosition: "right",
          labelWidth: "80px"
        });
      } else {
        Object.assign(extraProps, {
          labelPosition: "right",
          labelWidth: "80px"
        });
      }
      return {
        size: "mini",
        ...extraProps,
        ..._.omit(this.option, ["column"]),
        class: {
          border: this.isArray,
          "p-2": this.isArray
        },
        model: this.value
      };
    },
    getFormItemProps(item) {
      let extraProps = {};
      if (this.isArray) {
        // console.log(this.type, this.isArray)
        // Object.assign(extraProps, {
        //   labelPosition: "right",
        //   labelWidth: "100px"
        // });
      } else {
        Object.assign(extraProps, {
          // labelPosition: "top",
          // labelWidth: "80px"
        });
      }
      return {
        ...extraProps,
        ..._.omit(item, ["dicData", "type"])
      };
    },
    getCom(item) {
      if (item.component) {
        return item.component;
      }
      const types = {
        dynamic: 'FormBuilder',
        number: 'el-input-number',
        daterange: 'el-date-picker',
        datetime: 'el-date-picker',
        dates: 'el-date-picker',
        textarea: 'el-input',
      }
      return types[item.type] || `avue-${item.type || "input"}`
    },
    getProps(item) {
      return {
        ...item,
        dic: item.dicData,
        option: item.children
      };
    },
    emitInput(newValues) {
      const newValue = this.isArray ? newValues : newValues[0];
      this.$emit("input", newValue);
    },
    add(i) {
      let newValues = this.deepClone(this.values);
      newValues.splice(i + 1, 0, {});
      this.emitInput(newValues);
    },
    remove(i) {
      let newValues = this.deepClone(this.values);
      newValues.splice(i, 1);
      this.emitInput(newValues);
    },
    changeValue(i, item, value) {
      const oldValue = _.get(this.values, [i, item.prop]);
      const isChanged = !_.isEqual(oldValue, value);
      if (!isChanged) {
        // console.log([oldValue, value]);
        return;
      }
      let newValues = this.deepClone(this.values);
      _.set(newValues, [i, item.prop], this.deepClone(value));
      this.emitInput(newValues);
    }
  }
};
</script>

<style>

.el-date-editor.el-input, .el-date-editor.el-input__inner {
  width: 100%;
}
</style>