<template>
  <div class="customer">
    <!-- <icon-menu></icon-menu> -->
    <el-row :gutter="24" class="card-item ml-1 mb-1">
      <el-col v-for="item in customerData.data" :xs="24" :md="item.span" :lg="{ span: '4-8' }" :sm="12"
        :key="item.title" class="py-2">
        <el-card style="min-height: 100px" class="card mt-2 d-flex justify-content-center">
          <div class="d-flex justify-content-between align-items-center flex-auto" @click="to(item)">
            <div>
              <el-image :src="item.url" class="img-size"></el-image>
            </div>
            <div class="cursor-pointer">
              <div class="option-title pb-2">{{ item.title }}</div>
              <div class="d-flex">
                <div style="min-width: 120px">
                  <span class="option-count text-primary pr-1">{{
                    item.count
                  }}</span>
                  <span class="fs-10">{{ item.unit }}</span>
                  <div class="fs-10">{{ item.label1 }}</div>
                </div>
                <div>
                  <!-- <span class="option-count text-primary pr-1">{{
                      item.target_value
                    }}</span>
                    <span class="fs-10">{{ item.unit }}</span>
                    <div class="fs-10">{{ item.label2 }}</div> -->
                </div>
              </div>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>

    <div class="d-flex align-items-center justify-content-between pb-2">
      <div class="d-flex pb-2">
        <div class="px-3" v-if="!history_type && user.role !== 0 && user.role !== 6">
          <el-radio-group v-model="permission" size="small">
            <el-radio-button :loading="loading" label="me">{{
              user.role == 5 ? "团队客户" : "我的客户"
            }}</el-radio-button>
            <el-radio-button :loading="loading" label="other">其他客户</el-radio-button>
          </el-radio-group>
        </div>
        <div class="pl-3" @click="screen">
          <el-button :loading="loading" type="primary">近三月未成交</el-button>
        </div>
      </div>
      <!-- <div class="d-flex align-items-center sort-order pr-3">
        <div class="pb-1 pr-1">排序方式：</div>
        <el-radio-group v-model="sortOrder">
          <el-radio class="mr-3" :label="1">客户等级</el-radio>
          <el-radio :label="2">创建日期</el-radio>
        </el-radio-group>
      </div> -->
    </div>

    <avue-crud v-model="form" :data="data" :option="option" @row-save="create" @row-update="update" @row-del="remove"
      :before-open="beforeOpen" :page.sync="page" @on-load="changPage" :upload-preview="preview" @search-change="search"
      :before-close="afterClose" :table-loading="loading" @sort-change="sortChange" @selection-change="selectionChange">
      <template slot="contacts.reorder" slot-scope="{ row }">
        <el-tag>{{ row.reorder }}</el-tag>
      </template>

      <template slot="business_scope" slot-scope="{ row }">
        <el-tooltip effect="dark" :content="business_scope_name(row)" placement="top-start" v-if="row.business_scope">
          <div class="text-truncate cursor-pointer" style="max-width: 30vw">
            {{ business_scope_name(row) }}
          </div>
        </el-tooltip>
      </template>

      <template slot="contacts.reorder" slot-scope="{ row }">
        <el-tag>{{ row.reorder }}</el-tag>
      </template>
      <template slot="name" slot-scope="{ row }">
        <el-tooltip effect="dark" :content="row.name" placement="top-start">
          <!-- <div class="text-primary">{{ row.name }}</div> -->
          <div class="text-truncate text-primary cursor-pointer">
            {{ row.name }}
          </div>
        </el-tooltip>
      </template>

      <template slot="total" slot-scope="scope">
        <!-- <div class="text-primary">{{ scope.row.name }}</div> -->
        <!-- $router.push(`/schedules/list?customerId=${scope.row._id}&new=true`) -->
        <div class="text-truncate text-primary cursor-pointer" @click="openTotal(scope.row)">
          {{ scope.row.total }}
        </div>
      </template>

      <template slot="remain" slot-scope="scope">
        <!-- <div class="text-primary">{{ scope.row.name }}</div> -->
        <div class="text-truncate text-primary cursor-pointer" @click="
          $router.push(
            `/schedules/list?customerId=${scope.row._id}&payBack_type=remain&new=true`
          )
          ">
          {{ scope.row.remain }}
        </div>
      </template>

      <template slot="yearTotal" slot-scope="scope">
        <!-- <div class="text-primary">{{ scope.row.name }}</div> -->
        <div class="text-truncate text-primary cursor-pointer" @click="
          $router.push(
            `/schedules/list?customerId=${scope.row._id}&cooperateDate=year&new=true`
          )
          ">
          {{ scope.row.yearTotal }}
        </div>
      </template>

      <template slot="user" slot-scope="scope">
        <div v-if="!Array.isArray(scope.row.user)">
          {{ scope.row.user && scope.row.user.realname }}
        </div>
        <span v-else>{{
          (scope.row.user || []).map((v) => v.realname).join(",")
        }}</span>
      </template>
      <template slot="old_user" slot-scope="scope">
        <div v-if="!Array.isArray(scope.row.old_user)">
          {{ scope.row.old_user && scope.row.old_user.realname }}
        </div>
        <span v-else>{{
          (scope.row.old_user || []).map((v) => v.realname).join(",")
        }}</span>
      </template>

      <template slot="contacts" slot-scope="scope">
        <el-tooltip effect="dark" :content="(scope.row.contacts || []).map((v) => v.name).join('，')"
          placement="top-start">
          <div class="cursor-pointer text-truncate">
            <span class="pr-1 contact" v-for="(row, index) in scope.row.contacts.slice(0, 1)" :key="index">{{ row.name
              }}</span><span v-if="scope.row.contacts.length > 1">...</span>
          </div>
        </el-tooltip>
      </template>

      <template slot="mobile" slot-scope="scope">
        <el-tooltip effect="dark" :content="(scope.row.contacts || []).map((v) => v.mobile).join('，')"
          placement="top-start">
          <div style="line-height: 22px" v-for="(row, index) in scope.row.contacts.slice(0, 1)" :key="index">
            {{ row.mobile }}
          </div>
          <div class="cursor-pointer text-truncate">
            <span class="pr-1 contact" v-for="(row, index) in scope.row.contacts.slice(0, 1)" :key="index">{{ row.mobile
              }}</span>
          </div>
        </el-tooltip>
      </template>

      <!-- <template slot="contacts" slot-scope="scope"> {{scope.row.name}}</template>  -->
      <template slot-scope="scope" slot="menu">
        <el-button icon="iconfont icon-genjinjilu" @click="followUp(scope)" :size="scope.size"
          :type="scope.type">跟进记录</el-button>
        <el-button v-if="
          (user.role == 0 || (user.role == 5 && permission === 'me')) &&
          _.get(scope.row, 'user.length', 0) > 0
        " icon="iconfont icon-zhuanyikehu" @click="relieve(scope)" :size="scope.size"
          :type="scope.type">解除负责人</el-button>
        <el-button v-if="user.role == 0 || (user.role == 5 && permission === 'me')" icon="iconfont icon-fenpei1"
          @click="distribution(scope)" :size="scope.size" :type="scope.type">分配负责人</el-button>
        <el-button v-if="
          (user.role == 1 || user.role == 4 || user.role == 5) &&
          _.get(scope.row, 'user.length', 0) == 0
        " icon="iconfont icon-fuzeren1" @click="takeOver(scope)" :size="scope.size"
          :type="scope.type">接管负责人</el-button>
        <el-button @click="coData(scope.row)" icon="el-icon-document" type="text">数据详情</el-button>
      </template>
      <!-- <template #menu-left="{}">
        <el-button @click="addRow">添加10条</el-button>
      </template> -->
      <template #header>
        <div v-if="user.role == 0">
          <el-button class="mb-2" @click="editRow">批量解除负责人</el-button>
          <el-button class="mb-2" @click="batchModify">批量分配负责人</el-button>
        </div>
      </template>
    </avue-crud>
    <div class="d-flex">
      <import :doImport="doImport" :parseItems="parseItems" class="pr-1 import"></import>

      <el-form v-if="user.role == 0 && IsPC" class="pl-2 import" ref="form" label-width="80px" inline
        @submit.native.prevent="doImportFollowUp">
        <div class="d-flex mb-3">
          <div class="d-flex align-items-center">
            <div class="pr-3 pl-1 fs-9 import-ex">导入跟进记录</div>
            <div class="a-upload">
              <span>点击上传</span>
              <input @input="parseFile" type="file" />
            </div>
          </div>
          <div class="ml-2">
            <el-button size="small" type="primary" native-type="submit">导入</el-button>
          </div>
        </div>
      </el-form>
      <div v-if="user.role == 0 && IsPC" class="exportCustomer ml-3">
        <el-button size="small" type="success" plain @click="exportCustomer">导出客户</el-button>
      </div>

      <div v-if="user.role == 0 && IsPC" class="exportCustomer ml-3">
        <el-button size="small" type="success" plain @click="exportCustomertotal">导出客户累计金额</el-button>
      </div>
      <!-- && history_type==9 -->
      <div v-if="user.role == 0" class="exportCustomer ml-3">
        <el-button size="small" type="success" plain @click="exportRereservoir">导出蓄水池客户</el-button>
      </div>
      <!-- <div class="ml-2" @click="exprot">
        <el-button size="small" type="primary">导出</el-button>
      </div>-->
    </div>
    <el-dialog title="跟进记录" :visible.sync="dialogVisible" width="74%" :before-close="dataClose">
      <el-card class="mb-4">
        <el-form :inline="true">
          <el-form-item label="跟进日期">
            <el-date-picker v-model="record.time" type="datetime" placeholder="选择日期时间"></el-date-picker>
          </el-form-item>

          <el-form-item label="沟通方式">
            <el-select v-model="record.contact_way" placeholder="请选择">
              <el-option v-for="item in options" :key="item.id" :label="item.label" :value="item.label"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="联系人">
            <el-select filterable v-model="record.contact" placeholder="请选择">
              <el-option v-for="item in formRow.contacts" :key="item.id" :label="item.name"
                :value="item.name"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="推荐教师">
            <el-select filterable v-model="record.teachers" multiple placeholder="请选择">
              <el-option v-for="item in teacherOptions" :key="item._id" :label="item.name"
                :value="item._id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="跟进人">
            <el-select filterable v-model="record.user" placeholder="请选择">
              <el-option v-for="item in superintendents" :key="item.id" :label="item.realname"
                :value="item._id"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="跟进类型">
            <el-radio v-model="record.followUp_type" label="daily" size="large">日常业务对接</el-radio>
            <el-radio v-model="record.followUp_type" label="keyPoint" size="large">重点业务机会</el-radio>
          </el-form-item>

          <el-input v-model="record.content" type="textarea" placeholder="请输入沟通内容"></el-input>

          <div class="text-center mt-2">
            <el-button type="primary" @click="onSubmit()">提交</el-button>
          </div>
        </el-form>
        <!-- <avue-form v-model="record" :option="records"> </avue-form> -->
      </el-card>
      <div class="block">
        <el-timeline v-if="_.get(formRow, 'history.length', 0) > 0">
          <!-- <div v-for="v in data" :key="v._id"> -->
          <el-timeline-item v-for="(item, index) in history" :key="item._id" :timestamp="item.submitedAt | formatDate"
            placement="top">
            <el-card>
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <div class="history-info">
                    <div class="pr-2" v-if="item.realname">
                      跟进人：
                      <span>{{ item.realname }}</span>
                      <!-- <span>{{ _.get(formRow, "history_user[0].realname") }}</span> -->
                    </div>

                    <div class="pr-2" v-else-if="item.user">
                      跟进人：
                      <span>{{ _.get(item, "user.realname") }}</span>
                    </div>

                    <div class="pr-2" v-else>
                      跟进人：
                      <!-- {{item}}

                      {{formRow}}-->
                      <!-- <span>{{ _.get(formRow, "user[0].realname") }}</span> -->
                      <span>{{
                        _.get(formRow, "history_user[0].realname")
                      }}</span>
                    </div>
                    跟进日期：
                    <span class="createdAt">
                      {{ item.time | formatDateY }}
                    </span>
                    <div class="pdl-3">
                      <span>沟通方式：</span>
                      <span class="text-danger font-weight-bold">
                        {{ item.contact_way }}
                      </span>
                    </div>
                    <div class="pdl-3">
                      <span>联系人：</span>
                      <span>{{ item.contact }}</span>
                    </div>

                    <div class="pdl-3">
                      <span>跟进类型：</span>
                      <span>{{ followUpType(item.followUp_type) }}</span>
                    </div>
                  </div>
                  <div class="pt-3">{{ item.content }}</div>
                </div>
                <div class="pr-4">
                  <el-button @click="detach(index)" type="danger" size="mini" icon="el-icon-delete" circle></el-button>
                </div>
              </div>
            </el-card>
          </el-timeline-item>
        </el-timeline>
        <div v-else class="text-center pb-2">暂无记录</div>
      </div>
    </el-dialog>

    <el-dialog title="分配负责人" :visible.sync="dialogPrincipal" width="30%" :before-close="handleClose">
      <div>
        <avue-form v-model="obj" :option="dialogOption" @submit="submit"></avue-form>
      </div>
    </el-dialog>

    <el-dialog title="批量分配负责人" :visible.sync="dialogAssigningUser" width="30%" :before-close="closeAssigningUser">
      <div>
        <avue-form v-model="assigningUser" :option="dialogOption" @submit="assigningSubmit"></avue-form>
      </div>
    </el-dialog>

    <el-dialog title="导出" :visible.sync="dialogExport" width="50%" :before-close="handleClose">
      <avue-form v-model="exportForm" :option="exportOption" @submit="exportSubmit"></avue-form>
    </el-dialog>

    <el-dialog title="导出" :visible.sync="dialogtotalExport" width="50%" :before-close="handleCloseTotal">
      <avue-form v-model="exportotaltForm" :option="exportOption" @submit="exportTotalSubmit"></avue-form>
    </el-dialog>
    <el-dialog title="导出" :visible.sync="showRreservoir" width="50%" :before-close="showRreservoirhandleClose">
      <avue-form v-model="reservoirFrom" :option="exportOption" @submit="exportSubmitR"></avue-form>
    </el-dialog>

    <el-dialog :title="obj.name" :visible.sync="dialogData" width="80%" align="center" :before-close="dataClose">
      <div class="" v-loading="coiLoading">
        <div class="text-right mb-3">
          <span class="mr-2 fs-9">当前数据年份</span>
          <el-date-picker style="width: 120px" v-model="customerQuery.year" type="year" :clearable="false"
            value-format="yyyy" placeholder="请选择年份" />
        </div>
        <div class="mb-4">
          <avue-data-panel :option="customerOption"></avue-data-panel>
        </div>
        <el-row>
          <el-col :span="24" :xl="24" :md="24" :lg="24">
            <div ref="chart1" style="height: 280px; width: 100%"></div>
          </el-col>
          <el-col :span="24" :xl="24" :md="24" :lg="24">
            <div ref="chart2" style="height: 280px; width: 100%"></div>
          </el-col>
        </el-row>
        <el-table class="mt-3" :data="customers" stripe border style="width: 100%">
          <el-table-column prop="_id.name" label="终端客户">
            <template slot-scope="{ row }">
              <span v-if="row.terminal_name">{{ row.terminal_name }}</span>
              <span v-else>客户已删除</span>
            </template>
          </el-table-column>
          <el-table-column prop="teacher" label="讲师">
            <template slot-scope="{ row }">
              <span v-if="row.teacher">{{ row.teacher.name }}</span>
              <span v-else>讲师已删除</span>
            </template>
          </el-table-column>

          <el-table-column prop="times" label="上课日期">
            <template slot-scope="{ row }">
              <el-tooltip effect="dark" :content="_.get(row, 'times', [])
                  .map((v) => $filters.formatDateT(v))
                  .join('，')
                " placement="top-start">
                <!-- <div v-for="(item, i) in _.get(row, 'times', [])" :key="i">
                  {{ $filters.formatDateT(item) }}
                </div>-->

                <div class="cursor-pointer" :class="IsPC ? 'text-truncate1' : 'text-truncate'" style="max-width: 20vw">
                  <!-- {{ scope.row.times && scope.row.times.join("<br>") | formatDateT
                  }}-->

                  <div class="times" v-for="(item, i) in _.get(row, 'times', []).slice(0, 4)" :key="i">
                    <span :key="i">{{ $filters.formatDateT(item) }}</span>
                    <!-- <br :key="`br-` + i" /> -->
                  </div>
                </div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="post" label="课程名称"> </el-table-column>
          <el-table-column prop="category" label="授课类别"> </el-table-column>
          <el-table-column prop="order_amount" label="订单总额">
          </el-table-column>
        </el-table>

        <div class="mt-3">
          <el-pagination background @current-change="handleCurrentChange" :current-page.sync="customerQuery.page"
            layout="total, prev, pager, next" :total="customerPage.total">
          </el-pagination>
        </div>
      </div>
    </el-dialog>

    <el-dialog title="累计金额数据" :visible.sync="showTotal" width="50%" :before-close="closeTotal">
      <avue-crud :data="totalData" :option="totalOption">
        <template slot-scope="scope" slot="menu">
          <el-button @click="watchTotal(scope.row)" type="text">查看</el-button>
        </template>
      </avue-crud>

      <p class="my-2">总计：{{ totalAmount }}</p>
    </el-dialog>
  </div>
</template>

<script>
  import * as dayjs from "dayjs";
  const importField = [
    {
      label: "客户名称",
      value: "客户名称",
    },
    {
      label: "客户状态",
      value: "客户状态",
    },
    {
      label: "客户来源",
      value: "客户来源",
    },
    {
      label: "客户等级",
      value: "客户等级",
    },
    {
      label: "所属城市",
      value: "所属城市",
    },
    {
      label: "详细地址",
      value: "详细地址",
    },
    {
      label: "备注",
      value: "备注",
    },
    {
      label: "联系人",
      value: "联系人",
    },
    {
      label: "跟进记录",
      value: "跟进记录",
    },
    {
      label: "距上次联系天数",
      value: "距上次联系天数",
    },
    {
      label: "距上次下单天数",
      value: "距上次下单天数",
    },
    {
      label: "今年累计合作金额",
      value: "今年累计合作金额",
    },
    {
      label: "累计合作金额",
      value: "累计合作金额",
    },
    {
      label: "负责人",
      value: "负责人",
    },
    {
      label: "创建时间",
      value: "创建时间",
    },
  ];
  export default {
    data() {
      var mobile = (rule, value, callback) => {
        const reg = /^1[2|3|4|5|6|7|8|9][0-9]\d{8}$/;
        const landline = /0\d{2,3}-\d{7,8}/;
        if (reg.test(value) || landline.test(value)) {
          callback();
        } else {
          callback(new Error("请输入正确的手机号"));
        }
      };
      return {
        showTotal: false,
        showTotalQuery: {
          customer: "",
        },
        totalAmount: 0,
        totalData: [],
        totalOption: {
          size: "mini",
          align: "center",
          border: true,
          index: true,
          indexLabel: "序号",
          searchMenuSpan: 4,
          header: true,
          dialogClickModal: false,
          emptyBtn: false,
          border: true,
          menuWidth: 160,
          translate: false,
          editBtn: false,
          addBtn: false,
          refreshBtn: false,
          searchShowBtn: false,
          columnBtn: false,
          copyBtn: false,
          delBtn: false,
          column: [
            {
              label: "年份",
              prop: "year",
              border: true,
              slot: true,
            },
            {
              label: "总金额",
              prop: "total",
              border: true,
              span: 24,
              slot: true,
            },
          ],
        },
        dataDate: {
          dataMonth: new Date(),
        },
        showRreservoir: false,
        customerQuery: {
          year: new Date(),
          limit: 10,
          page: 1,
          where: {
            type: null,
          },
        },
        assigningUser: {},
        dialogAssigningUser: false,
        customerIds: [],
        customerOption: {},
        hascustomer: false,
        customerCount: null,
        teachingDay: null,
        customerData: [],
        sortOrder: 1,
        dialogEdit: false,
        dialogData: false,
        coiLoading: false,
        dialogExport: false,
        history_type: null,
        obj: {},
        cities: [],
        grade: null,
        wname: null,
        userName: null,
        ids: null,
        createdAt: null,
        permission: "me",
        customerPage: {
          total: 0,
        },
        customers: [],
        customerId: undefined,
        query: {
          sort: { grade: 1, name: 1 },
          collation: { locale: "zh" },
          limit: 20,
          where: {
            isBlacklist: 0,
            // days_without_order: { $gt: 90 },
          },
        },
        where: null,
        from: {},
        page: {
          total: 0,
          pageSize: 20,
        },
        form: {
          dynamic: [
            {
              reorder: 1,
              select: 1,
            },
            {
              reorder: 2,
              select: 2,
            },
          ],
        },
        formRow: {},
        record: {
          contact: "",
          contact_way: "",
          time: "",
          content: "",
          followUp_type: "daily",
          submitedAt: new Date(),
          teachers: [],
        },
        exportOption: {
          translate: false,
          column: [
            {
              label: "日期范围",
              prop: "daterange",
              type: "daterange",
              startPlaceholder: "选择导出的开始时间",
              endPlaceholder: "选择导出的结束时间",
              span: 12,
              valueFormat: "timestamp",
              rules: [
                {
                  required: true,
                  message: "请选择导出的日期范围",
                  trigger: "blur",
                },
              ],
            },
            {
              label: "导出内容",
              prop: "field",
              type: "tree",
              span: 12,
              multiple: true,
              rules: [
                {
                  required: true,
                  message: "请选择导出的内容",
                  trigger: "blur",
                },
              ],
              dicData: importField,
            },
          ],
        },
        options: [],
        dialogVisible: false,
        dialogPrincipal: false,
        data: [],
        loading: true,
        superintendents: [],
        users: [],
        exportForm: {
          daterange: [],
          field: [],
        },
        reservoirFrom: {
          daterange: [],
          field: [],
        },
        teacherOptions: [],
        option: {
          reserveSelection: true,
          selection: true,
          border: true,
          menu: true,
          tree: true,
          expandLevel: 2,
          headerAlign: "center",
          dialogWidth: "90%",
          menuWidth: 300,
          searchMenuSpan: 5,
          viewBtn: true,
          align: "center",
          index: true,
          indexLabel: "序号",
          dialogClickModal: false,
          translate: false,
          column: [
            {
              label: "客户名称",
              prop: "name",
              props: {
                lable: "lable",
                value: "value",
              },
              search: true,
              // slot: true,
              sortable: true,
              allowCreate: true,
              defaultfirstOption: true,
              filterable: true,
              remote: true, //开启远程访问
              dicFlag: false,
              dicUrl: "customers/option/name?name={{key}}",
              rules: [
                {
                  required: true,
                  message: "请输入客户名称",
                  trigger: "blur",
                },
              ],
            },

            // {
            //   label: "联系人",
            //   prop: "name",
            //   props: {
            //     lable: "lable",
            //     value: "value",
            //   },
            //   search: true,
            // },

            // {
            //   label: "客户名称",
            //   prop: "searchName",
            //   props: {
            //     lable: "lable",
            //     value: "value",
            //   },
            //   display:false,
            //   search: true,
            //   hide:true,
            //   rules: [
            //     {
            //       required: true,
            //       message: "请输入客户名称",
            //       trigger: "blur",
            //     },
            //   ],
            // },

            {
              label: "联系人",
              children: [
                {
                  label: "姓名",
                  display: false,
                  search: true,
                  prop: "contacts",
                  slot: true,
                  formatter: (row) => {
                    // return this._.map(row.contacts, "name").join("<br>");
                    console.log(this._.get(row, "contacts", [])
                      .map((v) => v.name)
                      .join("<br> "))
                    return this._.get(row, "contacts", [])
                      .map((v) => v.name)
                      .join("<br> ");
                    return row.contacts[0] && row.contacts[0].name;
                  },
                },
                {
                  label: "手机号",
                  display: false,
                  // width: "180px",
                  prop: "mobile",
                  slot: true,
                  formatter: (row) => {
                    return this._.get(row, "contacts", [])
                      .map((v) => v.mobile)
                      .join("<br/>");
                  },
                },
              ],
            },

            {
              label: "负责人",
              search: true,
              type: "select",
              props: {
                label: "realname",
                value: "_id",
              },
              prop: "user",
              dicData: [],
              searchFilterable: true,
              // dicQuery: {
              //   query: {
              //     limit: 0,
              //     where: {
              //       role: { $in: [1, 4] },
              //     },
              //   },
              // },
              span: 8,
              slot: true,
              display: false,
            },
            
            {
              label: "今年累计合作(金额)",
              prop: "yearTotal",
              display: false,
              slot: true,
              sortable: true,
              width: "180px",
            },
            {
              label: "累计合作(金额)",
              prop: "total",
              display: false,
              slot: true,
              sortable: true,
              width: "180px",
            },
            {
              label: "未回款",
              prop: "remain",
              display: false,
              slot: true,
              sortable: true,
              width: "100px",
            },
            // {
            //   label: "客户类型",
            //   prop: "type",
            //   type: "radio",
            //   props: {
            //     label: "label",
            //     value: "label",
            //   },
            //   dicUrl: `dictionaries/type/customer.type`,
            //   hide: true,
            //   showColumn: false,
            // },
            // {
            //   label: "客户状态",
            //   prop: "state",
            //   type: "select",
            //   props: {
            //     label: "label",
            //     value: "label",
            //   },
            //   dicUrl: `dictionaries/type/customer.state`,
            //   hide: true,
            //   showColumn: false,
            // },

            {
              label: "客户来源",
              props: {
                label: "label",
                value: "label",
              },
              prop: "source",
              type: "select",
              dicUrl: `dictionaries/type/customer.source`,
              hide: true,
              showColumn: false,
            },
            {
              label: "客户等级",
              search: true,
              prop: "grade",
              props: {
                label: "label",
                value: "label",
              },
              sortable: true,
              dicUrl: `dictionaries/type/customer.grade`,
              type: "radio",
              width: "100px",
              searchFilterable: true,
              // showColumn: false,
            },
            {
              label: "终端类型",
              prop: "business_scope",
              type: "select",
              props: {
                label: "label",
                value: "label",
              },
              multiple: true,
              dicUrl: `dictionaries/type/customer.business_scope`,
              // hide: true,
              slot: true,
              searchMultiple: true,
              search: true,
              searchLabelWidth: 100,

              // showColumn: false,
            },
            // {
            //   label: "客户阶段",
            //   prop: "stage",
            //   type: "radio",
            //   props: {
            //     label: "label",
            //     value: "label",
            //   },
            //   dicUrl: `dictionaries/type/customer.stage`,
            //   hide: true,
            //   showColumn: false,
            // },
            {
              label: "年份",
              prop: "search_year",
              display: false,
              showColumn: false,
              search: true,
              type: "year",
              hide: true,
            },
            {
              label: "距上次联系天数",
              prop: "days_not_contacted",
              width: "180px",
              sortable: true,
              display: false,
            },
            {
              label: "距上次下单天数",
              prop: "days_without_order",
              width: "180px",
              sortable: true,
              display: false,
            },
            {
              label: "历史负责人",
              search: true,
              type: "select",
              props: {
                label: "realname",
                value: "_id",
              },
              prop: "old_user",
              dicData: [],
              span: 8,
              slot: true,
              display: false,
            },

            {
              label: "所属城市",
              prop: "region",
              type: "cascader",
              filterable: true,
              props: {
                label: "value",
                value: "value",
              },
              formatter: (row, value) => value && value.join("-"),
              // dicData:this.$store.state.cities,
              dicUrl: "getCity",
              checkStrictly: true,
              hide: true,
              showColumn: false,
            },
            {
              label: "详细地址",
              prop: "address",
              hide: true,
              showColumn: false,
            },
            {
              label: "年度合同",
              multiple: true,
              prop: "contractImg",
              type: "upload",
              dataType: "array",
              listType: "picture-card",
              action: "upload",
              span: 24,
              hide: true,
              showColumn: false,
            },
            {
              label: "年度合同时间",
              multiple: true,
              prop: "contractTimes",
              type: "daterange",
              span: 8,
              hide: true,
              showColumn: false,
            },
            {
              label: "年度合同附件",
              prop: "file",
              type: "upload",
              loadText: "附件上传中，请稍等",
              span: 18,
              hide: true,
              showColumn: false,
              action: "upload",
            },
            {
              label: "备注",
              prop: "info",
              type: "textarea",
              span: 24,
              hide: true,
              showColumn: false,
            },
            // {
            //   label: "导入联系人",
            //   type: "upload",
            //   loadText: "附件上传中，请稍等",
            //   span: 24,
            //   propsHttp: {
            //     res: "res",
            //   },
            //   // tip: "只能上传ex",
            //   action: "customers/import",
            // },
            // {
            //   label: "导入联系人",
            //   type: "import-button",
            // },
            {
              label: "添加联系人",
              prop: "contacts",
              type: "dynamic",
              hide: true,
              showColumn: false,
              span: 24,
              rules: [
                {
                  required: true,
                  message: "请添加联系人",
                  trigger: "blur",
                },
              ],
              children: {
                column: [
                  {
                    width: 200,
                    label: "排序",
                    prop: "reorder",
                    slot: true,
                  },
                  {
                    label: "姓名",
                    prop: "name",
                    rules: [
                      {
                        required: true,
                        message: "请填写姓名",
                        trigger: "blur",
                      },
                    ],
                  },
                  {
                    label: "手机号",
                    prop: "mobile",
                    rules: [
                      {
                        required: true,
                        message: "请填写姓名",
                        trigger: "blur",
                      },
                    ],
                    rules: [
                      { validator: mobile, trigger: "blur", required: true },
                    ],
                  },
                  {
                    label: "邮箱",
                    prop: "email",
                  },
                  {
                    label: "职务",
                    prop: "job",
                  },
                  {
                    label: "备注",
                    prop: "info",
                  },
                ],
              },
            },
            {
              label: "开票管理",
              prop: "invoices",
              type: "dynamic",
              hide: true,
              showColumn: false,
              span: 24,
              children: {
                column: [
                  {
                    label: "单位",
                    prop: "company",
                  },
                  {
                    label: "税号",
                    prop: "duty_paragraph",
                  },
                  // {
                  //   label: "城市",
                  //   prop: "city",
                  //   type: "cascader",
                  //   props: {
                  //     label: "value",
                  //     value: "value",
                  //   },
                  //   formatter: (row, value) => value && value.join("-"),
                  //   dicUrl: "getCity",
                  // },

                  {
                    label: "手机号",
                    prop: "mobile",
                  },
                  {
                    label: "开户行",
                    prop: "bank",
                  },
                  {
                    label: "专票税率",
                    type: "number",
                    prop: "special_ticket",
                  },
                  {
                    label: "普票税率",
                    type: "number",
                    prop: "general_ticket",
                  },
                  {
                    label: "开户行账号",
                    prop: "account",
                  },
                  {
                    label: "详细地址",
                    prop: "address",
                    type: "textarea",
                  },
                ],
              },
            },
            {
              label: "加入黑名单",
              prop: "isBlacklist",
              span: 24,
              type: "switch",
              props: {
                label: "label",
                value: "value",
              },
              dicData: [
                {
                  label: "否",
                  value: 0,
                },
                {
                  label: "是",
                  value: 1,
                },
              ],
              hide: true,
              showColumn: false,
            },
            {
              label: "创建时间",
              prop: "createdAt",
              type: "date",
              display: true,
              disabled: true,
              sortable: true,
              format: "yyyy-MM-dd",
              valueFormat: "yyyy-MM-dd",
            },
          ],
        },
        importData: [],
        exportotaltForm: {
          daterange: [],
          field: [],
        },
        dialogtotalExport: false,

      };
    },

    computed: {
      business_scope_name() {
        return (row) => {
          return Array.isArray(row.business_scope)
            ? row.business_scope.join(" | ")
            : row.business_scope;
        };
      },
      dialogOption() {
        return {
          translate: false,
          column: [
            {
              label: "负责人",
              prop: "user",
              filterable: true,
              props: {
                label: "realname",
                value: "_id",
              },
              type: "tree",
              multiple: true,
              rules: [
                {
                  required: true,
                  message: "请选择负责人",
                  trigger: "blur",
                },
              ],
              span: 24,
              dicUrl: "users/director",
              // dicQuery: {
              //   query: {
              //     limit: 0,
              //   },
              // },
              // dicData: importField,
            },
          ],
        };
      },
      history() {
        const rows = this.formRow.history;
        rows.sort((a, b) => {
          return Date.parse(b.submitedAt) - Date.parse(a.submitedAt);
        });
        return rows;
      },
    },

    watch: {
      "customerQuery.year"() {
        this.coData(this.obj);
        this.$message({
          message: `已为您切换至${this.dayjs(this.customerQuery.year).format(
            "YYYY"
          )}年数据`,
          type: "success",
          align: "center",
          center: true,
        });
      },
      sortOrder() {
        this.fetch();
      },
      grade() {
        this.fetch();
      },
      "form.name": {
        immediate: false,
        handler(value) {
          // this.isDay = true;
          // console.log(value);
        },
      },
      permission() {
        this.fetchCustomer();
      },
    },

    methods: {
      watchTotal(row) {
        if (!row.year) {
          return false;
        }
        this.$router.push(
          `/schedules/list?customerId=${row.customer}&new=true&totalYear=${row.year}`
        );
      },
      batchModify() {
        if (this.customerIds.length > 0) {
          this.dialogAssigningUser = true;
        } else {
          this.$message.error("请先批量选择客户");
        }


      },
      async assigningSubmit(row, done) {
        await this.$http.post(`customers/batchModify_user`, {
          ids: this.customerIds,
          users: row.user,
        });
        this.$message.success("批量分配成功");
        this.fetch();
        done();
        this.dialogAssigningUser = false;
        setTimeout(() => {
          this.assigningUser = [];
        }, 500);
      },
      async editRow() {
        if (this.customerIds.length > 0) {
          try {
            await this.$confirm("是否确认批量解除负责人？");
          } catch (e) {
            return;
          }
          await this.$http.post(`customers/secure_user`, this.customerIds);
          this.$message.success("解除成功");
          this.fetch();
        } else {
          this.$message.error("请先批量选择客户");
        }
      },
      selectionChange(list) {
        this.customerIds = list.map((v) => {
          return v._id;
        });
        // console.log(this.customerIds);
        // this.$message.success("选中的数据" + JSON.stringify(list));
      },
      handleCurrentChange(val) {
        this.customerQuery.page = val;
        this.coData(this.obj);
      },
      async initChart1() {
        let chartDom = this.$refs.chart1;
        let chartDom2 = this.$refs.chart2;
        let myChart = this.$echarts.init(chartDom);

        myChart.setOption(this.teachingDay, true);
        let myChart2;
        if (chartDom2) {
          myChart2 = this.$echarts.init(chartDom2);
        }
        myChart2 && myChart2.setOption(this.customerCount, true);

        window.addEventListener("resize", function () {
          myChart.resize();
          myChart2 && myChart2.resize();
        });
      },
      async coData(row) {
        this.obj = row;
        this.dialogData = true;
        this.coiLoading = true;
        const res = await this.$http.post(`customers/customerData/${row._id}`, {
          params: {
            query: JSON.stringify(this.customerQuery),
          },
        });
        this.coiLoading = false;
        this.customerPage.total = res.data.total;
        this.customers = res.data.cooperativeCustomers;
        this.teachingDay = res.data.teachingDay;
        this.customerCount = res.data.customer;
        this.customerOption = res.data.customerOption;
        this.hascustomer = res.data.hascustomer;

        this.customerOption.data.map((v) => {
          if (v.to) {
            v.click = () => this.fetchWhere(v.to);
          }
        });
        // this.coach = res.data.coach;
        // console.log(res.data.teachingDays)
        this.$nextTick(() => {
          this.initChart1();
        });
      },
      fetchWhere(to) {
        console.log(22);
        console.log(to);
        this.customerQuery.where.type = to;
        this.coData(this.obj);
        // console.log(this.customerQuery)
      },
      async sortChange(e) {
        const { order, prop } = e;
        this.query.sort = {
          [prop]: order == "ascending" ? 1 : -1,
        };
        // this.query.sort[prop] = order == "ascending" ? 1 : -1;
        this.$nextTick(() => {
          this.fetch();
        });
      },
      followUpType(val) {
        let type = null;
        if (val == "daily") {
          type = "日常业务对接";
        } else if (val == "keyPoint") {
          type = "重点业务机会";
        }
        return type;
      },
      async exportSubmit(row, done) {
        const res = await this.$http.post(
          "/customers/exportCustomer/",
          {
            ...row,
            history_type: this.history_type,
          },
          {
            responseType: "arraybuffer",
          }
        );
        const link = document.createElement("a");
        let blob = new Blob([res.data], { type: "application/x-xls" });
        link.href = URL.createObjectURL(blob);
        const title = `客户数据-${dayjs().format("YYYY-MM-DD")}`;
        link.download = `${title}.xlsx`;
        link.click();
        document.body.appendChild(link);
        done();
      },

      async exportTotalSubmit(row, done) {
        const res = await this.$http.post(
          "/customers/exportCustomertotal/",
          {
            ...row,
            history_type: this.history_type,
          },
          {
            responseType: "arraybuffer",
          }
        );
        const link = document.createElement("a");
        let blob = new Blob([res.data], { type: "application/x-xls" });
        link.href = URL.createObjectURL(blob);
        const title = `客户累计金额数据-${dayjs().format("YYYY-MM-DD")}`;
        link.download = `${title}.xlsx`;
        link.click();
        document.body.appendChild(link);
        done();
      },

      async exportSubmitR(row, done) {
        const res = await this.$http.post(
          "/customers/exportCustomerR/",
          {
            ...row,
            history_type: this.history_type,
          },
          {
            responseType: "arraybuffer",
          }
        );
        const link = document.createElement("a");
        let blob = new Blob([res.data], { type: "application/x-xls" });
        link.href = URL.createObjectURL(blob);
        const title = `蓄水池客户数据-${dayjs().format("YYYY-MM-DD")}`;
        link.download = `${title}.xlsx`;
        link.click();
        document.body.appendChild(link);
        done();
        this.showRreservoir = false;
        this.$message.success("导出成功");
      },
      exportCustomer() {
        const startMonth = dayjs(this.value).startOf("year").toDate();
        const endMonth = dayjs(this.value).endOf("year").toDate();
        this.$set(this.exportForm.daterange, 0, startMonth);
        this.$set(this.exportForm.daterange, 1, endMonth);
        this.dialogExport = true;
      },

      exportCustomertotal() {
        const startMonth = dayjs(this.value).startOf("year").toDate();
        const endMonth = dayjs(this.value).endOf("year").toDate();
        this.$set(this.exportotaltForm.daterange, 0, startMonth);
        this.$set(this.exportotaltForm.daterange, 1, endMonth);
        this.dialogtotalExport = true;
      },

      exportRereservoir() {
        const startMonth = dayjs(this.value).startOf("year").toDate();
        const endMonth = dayjs(this.value).endOf("year").toDate();
        this.$set(this.reservoirFrom.daterange, 0, startMonth);
        this.$set(this.reservoirFrom.daterange, 1, endMonth);
        this.showRreservoir = true;
      },

      showRreservoirhandleClose() {
        this.showRreservoir = false;
      },

      // async exprot() {
      //   await this.$http.post("customers/export", {});
      // },

      preview(file, column, done) {
        const ext = file.url.split(".").pop();
        if (["jpg", "jpeg", "png", "gif"].includes(ext)) {
          done();
        } else {
          window.open(file.url);
        }
      },
      rowCell(row, index) {
        console.log(row, index);
      },
      beforeOpen(done, type) {
        if (this.form.isOperate == false && this.user.role !== 0) {
          //this.$message.error("您无权限操作");
          //return;
        }
        if (["add", "edit"].includes(type)) {
          const field = this.option.column.find((v) => v.prop === "name");
          // field.type = "text";
        }
        done();
      },

      afterClose(done, type) {
        const field = this.option.column.find((v) => v.prop === "name");
        field.type = "";
        done();
      },
      async submit(row, done) {
        this.$clear(this.obj);


        await this.$http.put(`customers/${this.obj._id}`, this.obj);
        this.$message.success("提交成功");
        this.fetch();
        done();
        this.dialogPrincipal = false;
        setTimeout(() => {
          this.obj.user = [];
        }, 500);
      },

      closeAssigningUser(done) {
        done();
        setTimeout(() => {
          this.assigningUser = [];
        }, 500);
      },

      handleClose(done) {
        done();
        setTimeout(() => {
          this.obj.user = [];
          this.assigningUser = [];
        }, 500);
      },
      handleCloseTotal(done) {
        done();
        this.dialogtotalExport = false;

      },
      dataClose(done) {
        done();
      },

      distribution(scope) {
        if (scope.row.user) {
          let old_user = scope.row.old_user || [];
          old_user = old_user.concat(scope.row.user || [])
          old_user = Array.from(new Set(old_user))
        this.obj.old_user = old_user;
          const users = scope.row.user.map((v) => {
            return v._id;
          });
          this.obj.user = users;
        }
        
        this.obj._id = scope.row._id;
        this.dialogPrincipal = true;
      },
      async screen() {
        const res = await this.$http.get("customers/unsettled");
        this.ids = { _id: { $nin: res.data.ids }, isBlacklist: 0 };
        this.fetch(this.ids);
      },
      handleGet() {
        window.open("/cdn/demo.xlsx");
      },
      async doImportFollowUp() {
        if (this.importData.length > 0) {
          const loading = this.$loading({
            lock: true,
            text: "导入中，请稍后。请勿关闭刷新页面",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          await this.$http.post("customers/follow_up/import", this.importData);
          loading.close();
          this.fetch();
          this.$message.success("导入成功");
        } else {
          this.$message.error("上传文件为空");
        }
      },
      async parseFile(e) {
        const file = e.target.files[0];
        const { results } = await this.$Export.xlsx(file);
        const items = results.map((v) => {
          return {
            name: v["客户名称"],
            history: [
              {
                contact: v["联系人"],
                contact_way: v["沟通方式"],
                time: v["跟进日期"],
                content: v["沟通内容"],
              },
            ],
          };
        });
        this.$message.success("上传成功,请导入");
        this.importData = items;
      },
      async doImport() {
        if (this.importData.length > 0) {
          const loading = this.$loading({
            lock: true,
            text: "导入中，请稍后。请勿关闭刷新页面",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          await this.$http.post("customers/import", this.importData);
          loading.close();
          this.fetch();
          this.$message.success("导入成功");
        } else {
          this.$message.error("上传文件为空");
        }
      },
      openTotal(row) {
        this.showTotalQuery.customer = row;
        if (!this.showTotalQuery.customer) {
          return this.$message.error("客户id不能为空");
        }
        this.showTotal = true;

        this.fetchTotal();
      },
      async fetchTotal() {
        const { data } = await this.$http.get("customers/total/list", {
          params: {
            customer: this.showTotalQuery.customer._id,
            year: this.query.where.search_year
          },
        });
        this.totalData = data;
        this.totalAmount = data.reduce((total, cur) => {
          return (total += cur.total);
        }, 0);
      },
      closeTotal() {
        this.showTotal = false;
        this.showTotalQuery.customer = "";
      },
      async parseItems(e) {
        const file = e.target.files[0];
        const { results } = await this.$Export.xlsx(file);
        const items = results.map((v) => {
          return {
            name: v["客户名称"],
            contacts: [
              {
                name: v["联系人姓名"],
                mobile: v["联系人电话"],
                email: v["联系人邮箱"],
                job: v["联系人职务"],
                info: v["联系人备注"],
              },
            ],
            region: v["所属城市"],
            address: v["详细地址"],
            state: v["客户状态"],
            stage: v["客户阶段"],
            type: v["客户类型"],
            grade: v["客户等级"],
            source: v["客户来源"],
            info: v["备注"],
            user: v["负责人"],
          };
        });
        this.$message.success("上传成功,请导入");
        this.importData = items;
        // const items = [];
        // results.slice(10, 15).map((v) => {
        //   const [
        //     客户名称,
        //     联系人姓名,
        //     联系人电话,
        //     联系人邮箱,
        //     联系人备注,
        //     所属城市,
        //     详细地址,
        //   ] = Object.values(v);
        //   const item = {
        //     name: 客户名称,
        //     contacts: [
        //       {
        //         name: 联系人姓名,
        //         mobile: 联系人电话,
        //         email: 联系人邮箱,
        //         info: 联系人备注,
        //       },
        //     ],
        //     region: [所属城市],
        //     address: 详细地址,
        //   };
        //   items.push(item);
        // });
      },

      // uploadAfter(res, done, loading) {
      //   this.customerId = res._id;
      //   let contacts = this.findObject(this.option.column, "contacts");

      //   // let column= contacts.children.column
      //   // column.forEach(v=>{
      //   //   column.push(v)
      //   // })
      //   // this.form.contacts = res.contacts;
      //   done();
      //   // console.log(contacts);
      // },
      async search(where, done) {

        done();
        if (where.contacts) {
          where[`contacts.name`] = { $regex: where.contacts };
          delete where.contacts;
        }
        if (where.name) {
          where.name = { $regex: where.name };
          this.where = { $regex: where.name };
        }
        if (where.grade) {
          where.grade = { $regex: where.grade };
          this.grade = where.grade;
        }
        if (where.user) {
          where.user = where.user;
          // this.grade = where.grade;
          this.wname = where.user;
        }
        if (where.user == "空") {
          where.user = [];
        }
        where.isBlacklist = 0;
        if (this.user.role !== 0) {
          if (
            !where.name &&
            !where.user &&
            !where.grade &&
            !where.business_scope
          ) {
            if (this.permission == "me") {
              if (this.user.role == 5) {
                where.user = {
                  $in: 5,
                };
              } else {
                where.user = 1;
              }
            } else {
              if (this.user.role == 5) {
                where.user = {
                  $nin: [this.user._id, ...this.user.userInfo],
                };
              } else {
                where.user = { $nin: [this.user._id] };
              }
            }
          }
        }
        // if (this.user.role == 5) {
        //   if (!where.name && !where.user && !where.grade) {
        //     where.user = { $in: [...this.user.userInfo, this.user._id] };
        //   }
        // }
        this.query.where = where;
        this.query.page = 1;
        if (this.ids) {
          this.fetch(this.ids);
        } else if (this.createdAt) {
          this.fetch(this.createdAt);
        } else if (this.userName) {
          this.fetch();
        } else {
          this.fetch();
        }
      },
      async changPage({ pageSize, currentPage }) {
        this.query.page = currentPage;
        this.query.limit = pageSize;
        if (this.history_type == 6) {
          const res = await this.$http.get("customers/unsettled");
          this.ids = { _id: { $nin: res.data.ids }, isBlacklist: 0 };
          return this.fetch(this.ids);
        }
        this.fetch();
        // if (this.ids) {
        //   this.fetch(this.ids);
        // } else {
        //   this.fetch();
        // }
      },
      async detach(index) {
        try {
          await this.$confirm("是否确认删除？");
        } catch (e) {
          return;
        }
        this.$clear(this.formRow);
        this.formRow.history.splice(index, 1);
        await this.$http.put(`customers/${this.formRow._id}`, this.formRow);
        this.$message.success("删除成功");
        this.fetch();
      },
      async onSubmit() {
        this.$clear(this.formRow, true);
        this.record.submitedAt = new Date();
        if (this.record && this.record.user == undefined) {
          this.record.user = this.user._id;
        }
        this.formRow.history.push(this.record);
        await this.$http.put(`customers/${this.formRow._id}`, this.formRow);
        const row = await this.$http.get(`customers/${this.formRow._id}`);
        this.formRow = row.data;
        this.$message.success("提交成功");
        this.record = {};
        this.fetch();
      },
      followUp(scope) {
        if (scope.row.isOperate == false && this.user.role !== 0) {
          this.$message.error("无操作权限");
        } else {
          this.formRow = scope.row;
          this.dialogVisible = true;
        }
      },

      // 筛选出期间段未联系的客户
      async fetchTime() {
        // this.history_type = this.$route.query.history_type;
        if (this.history_type == 6 && !this.wname) {
          if (!this.where && this.user.role !== 0 && !this.grade) {
            this.query.where = {
              user: 1,
              isBlacklist: 0,
            };
          }
          if (!this.where && this.user.role == 5 && !this.grade && !this.wname) {
            this.query.where = {
              user: 5,
              isBlacklist: 0,
            };
          }
        }

        const current = this.dayjs().toDate();
        const showWeek = async (current, timeFirst, timeEnd) => {
          let grade, name, user, where, total;
          if (this.grade) {
            grade = this.grade;
          }
          if (this.where) {
            name = this.where.$regex;
          }
          if (this.history_type == 9) {
            total = { $eq: 0 };
          }
          if (this.user.role == 0) {
            if (this.wname) {
              user = { $in: [this.wname] };
            }
          } else if (this.history_type !== "9") {
            user = 1;
          }

          if (this.history_type !== "9" && this.user.role == 5) {
            if (!this.wname) {
              user = 5;
            } else {
              user = { $in: [this.wname] };
            }
          }
          const isBlacklist = 0;
          if (current !== "notContacted") {
            where = {};
            const to = this.dayjs(current).subtract(...timeFirst);
            let from = {};
            if (timeEnd) {
              from = this.dayjs(current).subtract(...timeEnd);
              where = { $gte: from, $lte: to, $not: { $gte: to } };
            } else if (this.history_type == 7) {
              where = 7;
            } else {
              where = 6;
            }
          }
          const wheres = {
            "history.time":
              current !== "notContacted" ? where : { $exists: false },
            name,
            grade,
            user,
            total,
            isBlacklist,
          };
          this.query.where = wheres;
        };
        if (this.history_type == 1) {
          showWeek(current, [1, "week"], [2, "week"]);
        } else if (this.history_type == 2) {
          showWeek(current, [2, "week"], [1, "month"]);
        } else if (this.history_type == 3) {
          showWeek(current, [1, "month"], [2, "month"]);
        } else if (this.history_type == 4) {
          showWeek(current, [2, "month"]);
        } else if (this.history_type == 5) {
          showWeek("notContacted");
        } else if (this.history_type == 7) {
          showWeek(current, [1, "month"]);
        } else if (this.history_type == 6) {
          showWeek(current, [3, "month"]);
        } else if (this.history_type == 9) {
          showWeek(current, [1, "month"]);
        }
      },

      state() {
        if (!this.history_type && this.user.role !== 0 && this.user.role !== 6) {
          if (this.user.role !== 5 && this.permission == "me") {
            this.userName = {
              user: 1,
              isBlacklist: 0,
            };
          } else if (this.permission == "other" && this.user.role == 5) {
            this.userName = {
              user: { $nin: [this.user._id, ...this.user.userInfo] },
              isBlacklist: 0,
            };
          } else if (this.permission == "me" && this.user.role == 5) {
            this.userName = {
              user: 5,
              isBlacklist: 0,
            };
          } else {
            this.userName = {
              user: { $nin: [this.user._id] },
              isBlacklist: 0,
            };
          }
          this.fetch(this.userName);
        }
      },

      async fetchUser() {
        const ret = await this.$http.get(`users`, {
          params: {
            query: {
              limit: 0,
              where: {
                role: { $in: [1, 4, 5, 0] },
              },
            },
          },
        });
        const superintendent = ret.data.data;
        this.superintendents = superintendent;

        const res = await this.$http.get(`users/director`);
        const users = res.data;
        users.unshift({
          _id: "空",
          realname: "空",
        });
        this.users = users;

        const field = this.option.column.find((v) => v.prop === "user");
        field.dicData = this.users;
      },

      async fetchCustomer() {
        this.history_type = this.$route.query.history_type;
        // console.log( this.history_type)
        if (this.user.role == 6) {
          this.option.menu = false;
        }
        if (this.history_type == 6) {
          this.screen();
        } else if (this.history_type == 8) {
          let user = {};
          this.user.role == 5
            ? (user = 5)
            : this.user.role == 0
              ? (user = { $exists: true })
              : (user = this.user._id);

          this.createdAt = {
            createdAt: 8,
            user,
            isBlacklist: 0,
          };

          this.fetch(this.createdAt);
        }

        this.state();
      },

      async fetchTeacher() {
        const { data } = await this.$http.get("teachers/option");
        this.teacherOptions = data;
      },

      monthCustomer() { },

      async fetchTest() {
        const res = await this.$http.get("customers/test");
      },

      fetchTree() {
        this.exportForm.field = importField.map((v) => {
          return v.value;
        });
        this.reservoirFrom.field = importField.map((v) => {
          return v.value;
        });
        this.exportotaltForm.field = importField.map((v) => {
          return v.value;
        });
      },

      async fetch(where = {}) {
        this.fetchTime();
        this.loading = true;
        if (this.history_type == 6) {
          delete where._id;
          where.history_type = this.history_type;
        }
        Object.assign(this.query.where, where);
        const customerId = this.$route.query.customerId;
        if (
          customerId &&
          !this.where &&
          !this.grade &&
          !this.wname &&
          !this.ids
        ) {
          const res = await this.$http.get(`customers/${customerId}`);
          let data = res.data;
          for (let i = data.history.length - 1; i >= 0; i--) {
            if (data.history[i].followUp_type !== "keyPoint") {
              data.history.splice(i, 1);
            }
          }

          this.formRow = data;
          this.query.where = { _id: this.formRow._id };
          // this.query.sort = {'history.followUp_type':-1}
          this.dialogVisible = true;
        }

        if (this.sortOrder == 1) {
          this.query.sort = { ...this.query.sort };
        } else {
          this.query.sort = { ...this.query.sort };
        }
        this.query.collation = { locale: "zh" };
        const res = await this.$http.get("customers", {
          params: {
            query: this.query,
          },
        });
        this.page.total = res.data.total;
        const customers = res.data.data;
        const form = [...this.user.userInfo, this.user._id];

        console.log(customers.length, "customers");
        const yCustomers = customers.map((v) => {
          if (
            v.user &&
            v.user.find((v) =>
              this.user.role == 5 ? form.includes(v._id) : v._id == this.user._id
            )
          ) {
            return v;
          } else if (this.user.role !== 0) {
            for (const item of v.contacts) {
              delete item.mobile;
            }
            v.isOperate = false;
            return v;
          }
        });

        // let yCustomers = [];
        // let nCustomers = [];
        // customers.forEach((v) => {
        //   if (v.user && v.user.find((v) => v._id == this.user._id)) {
        //     yCustomers.push(v);
        //   } else {
        //     nCustomers.push({
        //       _id: v._id,
        //       name: v.name,
        //       user: v.user,
        //     });
        //   }
        // });

        if (this.user.role == 0) {
          this.data = customers;
        } else {
          this.data = yCustomers;
        }

        this.loading = false;

        this.data = this.data.map((v) => {
          return {
            ...v,
            id: v._id,
          };
        });

        // console.log(this.data,'data')

        const ret = await this.$http.get(`home/customer_option`);
        let cardDate = ret.data;
        this.customerData = cardDate.customerData;
        // console.log(this.customerData);
        // this.customerData.data.map((v) => {
        //   if (v.to) {
        //     v.click = () => this.$router.push(v.to);
        //   }
        // });

        // this.data = Ycustomers

        // this.data = res.data.data;
        // this.fetchCustomer();
      },
      to(item) {
        if (item.to) {
          const path = this.$route.fullPath;

          if (path !== item.to) {
            this.$router.push(item.to);
          }
          this.fetchCustomer();
          if (this.history_type == 9 || this.history_type == 7) {
            this.fetch();
          }
          // this.fetch();
        }
      },
      async fetchType() {
        const res = await this.$http.get("dictionaries/type/contact.way");
        this.options = res.data.sort();
      },
      async remove(row) {
        if (this.user.role !== 0) {
          this.$message.error("无操作权限");
        } else {
          try {
            await this.$confirm("是否确认删除？");
          } catch (e) {
            return;
          }
          await this.$http.delete(`customers/${row._id}`);
          this.$message.success("删除成功");
          this.fetch();
        }
      },
      async takeOver(scope) {
        try {
          await this.$confirm("是否确认接管负责人？");
        } catch (e) {
          return;
        }
        this.$clear(scope.row);
        scope.row.user = this.user._id;
        await this.$http.put(`customers/${scope.row._id}`, scope.row);
        this.$message.success("提交成功");
        this.fetch();
      },
      async relieve(scope) {
        try {
          await this.$confirm("是否确认解除负责人？");
        } catch (e) {
          return;
        }
        this.$clear(scope.row);
        scope.row.user = [];
        await this.$http.put(`customers/${scope.row._id}`, scope.row);
        this.$message.success("解除成功");
        this.fetch();
      },
      async update(row, index, done) {
        this.$clear(this.form);
        if (!row.isBlacklist && row.isBlacklist !== 0) {
          row.isBlacklist = 0;
        }
        await this.$http.put(`customers/${this.form._id}`, this.form);
        this.$message.success("更新成功");
        this.fetch();
        done();
      },
      async create(row, done) {
        try {
          if (!row.isBlacklist && row.isBlacklist !== 0) {
            row.isBlacklist = 0;
          }
          await this.$http.post("customers/isExist", row);

          await this.$http.post("customers", row);
          this.$message.success("创建成功");
          this.fetch();
          done();
        } catch { }
      },
    },
    created() {
      this.fetchCustomer();
      // this.fetchCity()
      // this.fetch();
      this.fetchUser();
      this.fetchType();
      this.fetchTree();
      this.fetchTeacher();
      // this.fetchTest();
    },
  };
</script>

<style>
  @media only screen and (max-width: 768px) {
    .contact {
      display: inline !important;
    }

    .history-info {
      display: inline !important;
    }

    .pdl-3 {
      padding-left: 0 !important;
    }

    .el-input--suffix .el-input__inner {
      padding-right: 10px !important;
    }

    /* .el-input__inner {
    padding: 0 10px !important;
  } */
  }

  .el-button [class*="iconfont"]+span {
    margin-left: 5px;
  }

  .createdAt {
    color: #555;
  }

  .block {
    height: 50vh;
    overflow: scroll;
  }

  .block::-webkit-scrollbar {
    width: 0 !important;
  }

  .el-input--suffix .el-input__inner {
    padding-right: 24px;
  }

  .customer .el-dialog__body .el-form-item__label {
    width: auto !important;
  }

  .customer .el-dialog__body .el-form-item__content {
    margin-left: auto !important;
  }

  .history-info {
    display: flex;
  }

  .pdl-3 {
    padding-left: 1rem;
  }

  .sort-order label {
    margin-bottom: 0 !important;
  }
  .customer {
    overflow: hidden
  }
</style>
