<template>
  <div class="w-100 h-100">
    <avue-crud ref="flie_crud" :data="fileData" :option="fileOption" :page.sync="page" @on-load="changPage"
      :table-loading="loading" @search-change="search" @selection-change="selectionChange">
      <template slot="menuLeft">
        <el-button v-if="
          [0, 1, 2, 3, 4, 5, 6, 7].includes(user.role) && type === 'outline'
        " type="primary" size="small" icon="el-icon-upload2" @click="addOutline">上传大纲
        </el-button>

        <el-button v-if="
          [0, 1, 2, 3, 4, 5, 6, 7].includes(user.role) &&
          (type === 'company' || type === 'certificate' || type === 'video' || type === 'marketing' || type === 'business_case' || type === 'lecture' || type === 'process' || type === 'contract')
        " type="primary" size="small" icon="el-icon--upload2" @click="addProduct">上传资料
        </el-button>
        <el-button v-if="[0, 1, 2, 3, 4, 5, 6, 7, 9].includes(user.role)" type="primary" size="small"
          icon="el-icon-download" @click="batchDownload">批量下载
        </el-button>
      </template>
      <template slot="teacher" slot-scope="scope">
        <span v-if="scope.row.teacher">{{ scope.row.teacher.name }}</span>
      </template>

      <template slot="name" slot-scope="scope">
        <div class="d-flex align-items-center w-100 justify-content-center">
          <!-- <el-button class="mx-2" type="text" icon="el-icon-document">
          </el-button> -->

          <edit-text @confirm="updateFilename(scope.row)" @preview="previewFile(scope.row)"
            @download="dwonloadItem(scope.row)" v-model="scope.row.filename" :ext="scope.row.ext" :id="scope.row._id"
            :url="scope.row.url" v-if="scope.row.url_type !== 2" />

          <el-tooltip class="item" effect="dark" :content="`${scope.row.url}`" placement="top-start" v-else>
            <span class="text-truncate cursor-pointer" @click="previewFile(scope.row)">
              {{ scope.row.url }}</span>
          </el-tooltip>
        </div>
      </template>

      <template slot="createdAt" slot-scope="scope">
        <div>
          {{ formatDateY(scope.row.createdAt) }}
        </div>
      </template>

      <template slot="show_to_customer" slot-scope="scope">
        <div>
          <el-switch @change="setShow(scope.row)" :value="scope.row.show_to_customer"></el-switch>
        </div>
      </template>
      <template slot="is_top" slot-scope="scope">
        <div>
          <el-switch @change="setTop(scope.row)" :value="scope.row.is_top == 1"></el-switch>
        </div>
      </template>
      <template slot="menu" slot-scope="scope">
        <el-button type="text" v-if="!isCustomer" icon="el-icon-edit" @click="editFile(scope.row)">编辑</el-button>
        <el-button type="text" icon="el-icon-delete" @click="remove(scope.row)" v-if="!isCustomer">删除</el-button>
      </template>
    </avue-crud>

    <el-dialog title="上传大纲" :visible.sync="showOutline" width="50%" :before-close="closeOutline">
      <avue-form v-model="outlineForm" :option="outlineOption" @submit="outlineSubmit" ref="outlineForm"
        class="outline-form" :upload-after="uploadAfter" :upload-before="uploadBefore">
        <!-- <template slot="files">
        <div>
          
          <el-switch
          ></el-switch>
        </div>
      </template> -->
      </avue-form>
    </el-dialog>

    <el-dialog title="上传文件" :visible.sync="showProduct" width="50%" :before-close="closeProduct">
      <avue-form v-model="productForm" :option="productOption" @submit="productSubmit" ref="productForm"
        class="product-form" :upload-after="uploadAfter" :upload-before="uploadBefore">
      </avue-form>
    </el-dialog>

    <el-dialog title="编辑" :visible.sync="isEdit" width="50%" :before-close="closeEditBox">
      <avue-form v-model="editForm" :option="fileOption" @submit="fileSubmit" :upload-after="uploadAfter"
        :upload-before="uploadBefore">
      </avue-form>
    </el-dialog>
  </div>
</template>

<script>
  import { formatDateY, formatDate } from "../filters";
  import { ossClient } from "../plugins/upload";
  import { Docxer } from "../plugins/docx";
  import EditText from "./EditText.vue";
  import _ from "lodash"

  export default {
    props: {
      type: {
        type: String,
        default: "string",
      },
    },
    data() {
      return {
        isEdit: false,
        page: {
          total: 0,
          currentPage: 1,
          pageSize: 10,
          pageSizes: [10, 20, 30, 40, 50],
        },
        outlineForm: {},
        productForm: {},
        select_list: [],
        loading: false,
        fileData: [],
        showOutline: false,
        showProduct: false,
        outlineOption: {
          translate: false,
          column: [
            {
              label: "讲师姓名",
              prop: "teacher",
              border: true,
              type: "select",
              props: {
                label: "name",
                value: "_id",
              },
              span: 24,
              slot: true,
              dicUrl: "teachers/option",
              filterable: true,
              search: this.type == "outline",
              width: 100,
            },
            {
              label: "文件",
              prop: "files",
              type: "upload",
              multiple: true,
              dataType: "String",
              action: "upload",
              span: 24,
              limit: 100,
              showColumn: false,
              slot: true,
            },
          ],
        },

        dataQuery: {
          limit: 10,
          page: 1,
        },
        filter_query: {
          name: "",
          teacher: "",
        },
        file_names: {},
        fileModel: {},
        editForm: {},
      };
    },

    computed: {
      fileExt() {
        return (row) => {
          return row.name && row.name.split(".").pop();
        };
      },
      fileName() {
        return (row) => {
          return row.name && row.name.split(".")[0];
        };
      },
      fileOption() {
        const is_show = ['outline', 'certificate', 'video'].includes(this.type);
        return {
          size: "mini",
          align: "center",
          border: true,
          selection: true,
          index: true,
          indexLabel: "序号",
          searchMenuSpan: 4,
          header: true,
          dialogClickModal: false,
          emptyBtn: false,
          border: true,
          menuWidth: 160,
          translate: false,
          editBtn: false,
          addBtn: false,
          delBtn: false,
          refreshBtn: false,
          searchShowBtn: false,
          columnBtn: false,
          copyBtn: false,
          menu: !this.isCustomer,
          column: [
            {
              label: "讲师姓名",
              prop: "teacher",
              border: true,
              type: "select",
              props: {
                label: "name",
                value: "_id",
              },
              span: 24,
              slot: true,
              dicUrl: "teachers/option",
              searchFilterable: true,
              filterable: true,
              search: is_show,
              width: 100,
              showColumn: this.type === "outline" || this.type == 'video' || this.type == "certificate",
              hide: !is_show,
              display: this.type === "outline" || this.type === "video" || this.type == "certificate",
            },
            {
              label: "文件",
              prop: "name",
              span: 24,
              limit: 1,
              showColumn: false,
              search: true,
              display: false,
              sortable: true,
            },
            {
              label: "链接地址",
              prop: "url",
              span: 24,
              limit: 1,
              showColumn: false,
              type: "input",
              display: this.type === 'video' && this.editForm.url_type === 2,
              hide: true,
              rules: [
                {
                  required: true,
                  message: "请填写链接地址",
                  trigger: "blur",
                },
                {
                  required: true,
                  trigger: "blur",
                  validator: (rule, value, callback) => {
                    const httpPattern = /^https?:\/\//i;
                    if (!httpPattern.test(value)) {
                      callback(new Error("非法地址"));
                    } else {
                      callback();
                    }
                  },
                },
              ],
            },
            {
              label: "文件",
              prop: "url",
              type: "upload",
              dataType: "string",
              action: "upload",
              span: 24,
              showColumn: false,
              hide: true,
              limit: 1,
              display: this.type === 'video' ? (this.editForm.url_type === 1 || this.editForm.url_type === 3) : true,
            },
            {
              label: "上传时间",
              prop: "createdAt",
              border: true,
              span: 24,
              slot: true,
              width: 120,
              display: false,
            },
            {
              label: "是否给客户展示",
              prop: "show_to_customer",
              span: 24,
              limit: 1,
              showColumn: false,
              display: false,
              hide: this.user.role !== 0,
              width: 120,

            },
            {
              label: "是否置顶",
              prop: "is_top",
              span: 24,
              limit: 1,
              showColumn: false,
              display: false,
              hide: this.user.role !== 0,
              width: 120,

            },
          ],
        };
      },
      productOption() {
        let accept = "";
        if (this.type === "video") {
          accept = "video/mp4";

          if (this.productForm.url_type === 3) {
            accept = "*"
          }
        }

        const show = ['certificate', 'video'].includes(this.type)
        return {
          translate: false,
          column: [
            {
              label: "讲师姓名",
              prop: "teacher",
              border: true,
              type: "select",
              props: {
                label: "name",
                value: "_id",
              },
              span: 24,
              slot: true,
              dicUrl: "teachers/option",
              filterable: true,
              search: true,
              width: 100,
              display: show,
            },

            {
              label: "链接方式",
              prop: "url_type",
              type: "radio",
              span: 16,
              display: this.type === 'video',
              dicData: [
                {
                  label: "上传文件",
                  value: 1,
                },
                {
                  label: "西瓜视频链接",
                  value: 2,
                },
                {
                  label: "上传excel",
                  value: 3,
                },
              ],
            },
            {
              label: "文件",
              prop: "files",
              type: "upload",
              multiple: true,
              dataType: "String",
              action: "upload",
              span: 24,
              showColumn: false,
              limit: 100,
              display: this.type === 'video' ? (this.productForm.url_type === 1 || this.productForm.url_type === 3) : true,
              accept
            },
            // https://v.youku.com/v_show/id_XNjM5ODExNzYyNA==.html
            {
              label: "链接地址",
              prop: "links",
              type: "textarea",
              span: 24,
              minRows: 2,
              hide: true,
              display: this.type === 'video' ? this.productForm.url_type === 2 : false,
              showColumn: false,
              placeholder: "请输入链接地址,回车换行符可以输入多个"
            },
          ],
        };
      },
    },
    components: {
      EditText,
    },

    methods: {
      addOutline() {
        this.showOutline = true;

        this.$nextTick(() => {
          const oi = document.querySelector(".outline-form .el-upload__input");
          // oi.webkitdirectory = true;
        });
      },
      setShow(row) {
        row.show_to_customer = !row.show_to_customer;

        this.$http.post("file/status", {
          file: row._id,
          status: row.show_to_customer,
        });
      },
      setTop(row) {
        row.is_top = row.is_top === 1 ? 999 : 1;
        this.$http.post("file/top_status", {
          file: row._id,
          status: row.is_top,
        });
      },
      addProduct() {
        this.showProduct = true;
        // this.$nextTick(() => {
        //   const oi = document.querySelector(".product-form .el-upload__input");
        //   oi.webkitdirectory = true;

        // });
      },
      closeProduct() {
        this.showProduct = false;
        this.productForm = {};
      },
      closeEditBox() {
        this.isEdit = false;
        this.editForm = {};
      },
      uploadAfter(res, done) {
        this.file_names[res.url] = res.originalname;
        done();
      },
      uploadBefore(res, done) {
        done();
      },

      async outlineSubmit(row, done) {

        this.$nextTick(async () => {
          // console.log({
          //   files: row.files.map((v) => {
          //     return {
          //       name: this.file_names[v.value],
          //       url: v.value,
          //     };
          //   }),
          // })
          const { data } = await this.$http.post("file/batch/upload", {
            ...row,
            files: row.files.map((v) => {
              return {
                name: this.file_names[v.value],
                url: v.value,
              };
            }),
            type: this.type,
          });
          done();

          this.$message.success("上传成功");
          this.outlineForm = {};
          this.showOutline = false;
          this.file_names = {};

          this.$refs.outlineForm.resetForm();
          this.fetchData();
        });
      },
      async productSubmit(row, done) {
        const files = row.files ?? [];
        const links = row.links;
        const { data } = await this.$http.post("file/batch/upload", {
          ...row,
          files: files.map((v) => {
            return {
              // name: this.file_names[v],
              name: v.label,
              url: v.value,
            };
          }),
          type: this.type,
          links
        });
        done();

        this.$message.success("上传成功");
        this.productForm = {};
        this.showProduct = false;
        this.file_names = {};

        this.$refs.productForm.resetForm();
        this.fetchData();
      },
      closeOutline() {
        this.showOutline = false;
        this.outlineForm = {};
      },
      async fetchData() {
        const where = {};

        if (this.filter_query.name) {
          where.name = {
            $regex: this.filter_query.name,
          };
          // where.name=this.filter_query.name;
        }
        if (this.isCustomer) {
          where.show_to_customer = true;
        }
        if (this.filter_query.teacher) {
          where.teacher = this.filter_query.teacher;
        }
        const { data } = await this.$http.get("file", {
          params: {
            query: {
              ...this.dataQuery,
              where: {
                type: this.type,
                ...where,
              },
            },
          },
        });
        this.page.total = data.total;
        this.fileData = data.data.map((v) => {
          let name = v.name || "";
          const names = name.split(".");
          const ext = names.pop()
          return {
            ...v,
            filename: names.join(""),
            ext,
          };
        });
      },
      formatDateY,
      async remove(row) {
        await this.$confirm("是否确认删除此大纲？");
        await this.$http.delete(`file/${row._id}`);

        this.$message.success("删除成功");
        this.fetchData();
      },
      updateFilename(row) {
        if (!row.filename) {
          return this.$message.error("文件名称不能为空");
        }

        if (!row.id) {
          return this.$message.error("id不能为空");
        }

        if (!row.ext) {
          return this.$message.error("文件参数错误");
        }
        this.isEdit = false;
        const filename = row.filename + "." + row.ext;
        this.$http.put("file/update_name/" + row._id, {
          name: filename,
        });
      },
      async previewFile(file) {
        const url = file.url;
        let ext = url.split(".").pop();
        ext = ext.toLocaleLowerCase()

        if (file.url_type === 2) {
          return window.open(file.url)
        }
        const isDoc = ["doc", "docx", "ppt", "pptx", "xlsx"].includes(ext);
        const isPdf = ["pdf"].includes(ext);
        const isImage = ["png", "jpeg", "jpg"].includes(ext);
        const isVideo = ['mp4'].includes(ext)
        if (isImage) {
          return this.$ImagePreview([file], 0, {
            closeOnClickModal: true,
            interval: 1000,
          });
        }

        if (isPdf || isVideo) {
          return window.open(url);
        }

        if (!isDoc && !isImage && isVideo) {
          return this.$message.error("文件类型暂不支持预览");
        }

        if (isDoc) {
          return Docxer.viewDoc(url);
        }


      },
      editFile(row) {

        if (!row._id) {
          return this.$message("参数错误");
        }
        this.file_names = {};

        if (this.type == "outline" || this.type == "video" || this.type == "certificate") {
          this.editForm = {
            ...row,
            teacher: row.teacher?._id ?? '',
          };
        } else {
          this.editForm = {
            ...row,
          };
        }

        this.isEdit = true;
      },
      async fileSubmit(row, done) {
        if (!this.editForm._id) {
          return this.$message("参数错误");
        }

        if (!this.editForm.teacher && this.type === "outline") {
          return this.$message("讲师不能为空");
        }

        if (!this.editForm.url) {
          return this.$message.error("文件不能为空");
        }

        if (this.type === "company") {
          delete this.editForm.teacher;
        }

        if (!this.editForm.teacher) {
          delete this.editForm.teacher;
        }

        this.$clear(this.editForm);

        if (this.file_names[this.editForm.url]) {
          this.editForm.name = this.file_names[this.editForm.url];
        }
        await this.$http.put(`file/${this.editForm._id}`, this.editForm);
        this.$message.success("更新成功");
        this.editForm = {};
        this.isEdit = false;
        done();

        this.fetchData();
      },
      selectionChange(vals) {
        this.select_list = vals;
      },
      search(val, done) {
        this.filter_query.name = val.name || "";
        this.filter_query.teacher = val.teacher || "";
        done();
        this.fetchData();
      },
      getFolderName() {
        let text = "";
        switch (this.type) {
          case "outline":
            text = "课程大纲";
            break;
          case "outline":
            text = "课程大纲";
            break;
          case "outline":
            text = "课程大纲";
            break;
          case "company":
            text = "公司产品";
            break;
          case "certificate":
            text = "头像证书";
            break;
          case "video":
            text = "视频片段";
            break;
        }
        return text;
      },
      async batchDownload() {
        let urls = this.select_list.map((v) => {
          return {
            filename: v.teacher ? v.teacher.name + "/" + v.name : v.name,
            url: v.url,
          };
        });
        urls = urls.reduce((arr, cur) => {
          const has = arr.find((v) => v.filename === cur.filename);
          return (arr = arr.concat(has ? [] : [cur]));
        }, []);

        if (!urls.length) {
          return this.$message.error(`请选择${this.getFolderName()}`);
        }
        await ossClient.batchDownload(
          urls,
          () => {
            this.addBehavior({
              user: this.user._id,
              files: this.select_list.map((v) => v._id),
              action: "download",
              data: {
                key: this.type,
              },
            });
            if (this.$refs.flie_crud) {
              this.$refs.flie_crud.toggleSelection();
            }
            this.select_list = [];
            this.$message.success("下载成功");
          },
          () => {
            // loading.text = "压缩中";
            this.$message.error("下载失败,请重试");
          },
          `${this.getFolderName()}`
        );
      },
      changPage({ pageSize, currentPage }) {
        this.dataQuery.page = currentPage;
        this.dataQuery.limit = pageSize;
        this.fetchData();
      },

      async dwonloadItem(item) {
        if (!item.url) {
          return false;
        }
        this.loading = true;
        await ossClient.donwloadSingleFile(item.url, item.name);
        this.loading = false;
        this.addBehavior({
          user: this.user._id,
          files: [item._id],
          action: "download",
          data: {
            key: this.type,
          },
        });
      },
    },
    created() {
      this.fetchData();
    },
  };
</script>
