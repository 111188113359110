<template>
  <div class="commonpool">
    <avue-crud
      v-model="form"
      :data="data"
      :option="option"
      :page.sync="page"
      @row-update="update"
      @row-del="remove"
      @on-load="changPage"
      @search-change="search"
      @row-save="create"
      :upload-after="uploadAfter"
    >
      <template slot="user" slot-scope="scope">
        <div>{{ scope.row.user && scope.row.user.realname }}</div>
      </template>

      <template slot="name" slot-scope="scope">
        <el-popover placement="top-start" trigger="hover" slot="reference">
          <div class="text-truncate text-primary">{{ scope.row.name }}</div>
          <div slot="reference">
            <div class="text-truncate text-primary cursor-pointer">
              {{ scope.row.name }}
            </div>
          </div>
        </el-popover>
      </template>
      <!-- <template slot="contacts" slot-scope="scope"> {{scope.row.name}}</template>  -->
      <template slot-scope="scope" slot="menu">
        <el-button
          @click="followUp(scope)"
          icon="el-icon-check"
          :size="scope.size"
          :type="scope.type"
          >跟进记录</el-button
        >
      </template>
    </avue-crud>
    <el-dialog
      title="跟进记录"
      :visible.sync="dialogVisible"
      width="70%"
      :before-close="handleClose"
    >
      <el-card class="mb-4">
        <el-form :inline="true">
          <el-form-item label="跟进日期">
            <el-date-picker
              v-model="record.time"
              type="datetime"
              placeholder="选择日期时间"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="沟通方式">
            <el-select v-model="record.contact_way" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.label"
                :value="item.label"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="联系人">
            <el-select filterable v-model="record.contact" placeholder="请选择">
              <el-option
                v-for="item in formRow.contacts"
                :key="item.id"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit()">提交</el-button>
          </el-form-item>
          <el-input
            v-model="record.content"
            type="textarea"
            placeholder="请输入沟通内容"
          ></el-input>
        </el-form>
        <!-- <avue-form v-model="record" :option="records"> </avue-form> -->
      </el-card>
      <div class="block">
        <el-timeline
          v-if="Array.isArray(formRow.history) && formRow.history.length > 0"
        >
          <!-- <div v-for="v in data" :key="v._id"> -->
          <el-timeline-item
            v-for="(item, index) in history"
            :key="item._id"
            :timestamp="item.submitedAt | formatDate"
            placement="top"
          >
            <el-card>
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <div class="d-flex">
                    <div class="pr-2">
                      负责人：
                      <span> {{ formRow.user.realname }}</span>
                    </div>
                    跟进日期：<span class="createdAt">{{
                      item.time | formatDateY
                    }}</span>
                    <div class="pl-3">
                      <span>沟通方式：</span>
                      <span class="text-danger font-weight-bold">{{
                        item.contact_way
                      }}</span>
                    </div>
                    <div class="pl-3">
                      <span>联系人：</span> <span>{{ item.contact }}</span>
                    </div>
                  </div>
                  <div class="pt-3">{{ item.content }}</div>
                </div>
                <div class="pr-4">
                  <el-button
                    @click="detach(index)"
                    type="danger"
                    size="mini"
                    icon="el-icon-delete"
                    circle
                  ></el-button>
                </div>
              </div>
            </el-card>
          </el-timeline-item>
        </el-timeline>
        <div v-else class="text-center pb-2">暂无记录</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      customerId: undefined,
      query: {
        limit: 10,
      },
      searchName: null,
      page: {
        total: 0,
      },
      form: {
        isBlacklist: 1,
      },
      formRow: {},
      record: {
        contact: "",
        contact_way: "",
        time: "",
        content: "",
        submitedAt: new Date(),
      },
      options: [],
      dialogVisible: false,
      data: [],
      option: {
        border: true,
        tree: true,
        expandLevel: 2,
        headerAlign: "center",
        dialogWidth: "90%",
        menuWidth: 220,
        searchMenuSpan: 5,
        align: "center",
        index: true,
        indexLabel: "序号",
        dialogClickModal: false,
        translate: false,
        title: "表格的标题",
        column: [
          {
            label: "客户名称",
            prop: "name",
            search: true,
            slot: true,
            rules: [
              {
                required: true,
                message: "请输入客户名称",
                trigger: "blur",
              },
            ],
          },

          {
            label: "联系人",
            children: [
              {
                label: "姓名",
                display: false,
                prop: "contacts",
                formatter: (row) => {
                  return this._.map(row.contacts, "name").join("<br>");
                  return this._.get(row, "contacts", [])
                    .map((v) => v.name)
                    .join("<br> ");
                  return row.contacts[0] && row.contacts[0].name;
                },
              },
              {
                label: "手机号",
                display: false,
                prop: "mobile",
                formatter: (row) => {
                  return this._.get(row, "contacts", [])
                    .map((v) => v.mobile)
                    .join("<br/>");
                },
              },
            ],
          },

          {
            label: "负责人",
            prop: "user",
            span: 8,
            slot: true,
            display: false,
          },

          // {
          //   label: "客户类型",
          //   prop: "type",
          //   type: "radio",
          //   props: {
          //     label: "label",
          //     value: "label",
          //   },
          //   dicUrl: `dictionaries/type/customer.type`,
          //   showColumn: false,
          // },
          {
            label: "客户状态",
            prop: "state",
            type: "select",
            props: {
              label: "label",
              value: "label",
            },
            dicUrl: `dictionaries/type/customer.state`,
            showColumn: false,
          },

          {
            label: "客户来源",
            props: {
              label: "label",
              value: "label",
            },
            prop: "source",
            type: "select",
            dicUrl: `dictionaries/type/customer.source`,
            showColumn: false,
          },
          {
            label: "客户等级",
            prop: "grade",
            props: {
              label: "label",
              value: "label",
            },
            dicUrl: `dictionaries/type/customer.grade`,
            type: "radio",
            // showColumn: false,
          },
          // {
          //   label: "客户阶段",
          //   prop: "stage",
          //   type: "radio",
          //   props: {
          //     label: "label",
          //     value: "label",
          //   },
          //   dicUrl: `dictionaries/type/customer.stage`,
          //   showColumn: false,
          // },
          // {
          //   label: "业务评价",
          //   prop: "comment",
          //   display: false,
          // },
          {
            label: "距上次联系天数",
            prop: "days_not_contacted",
            display: false,
          },
          {
            label: "距上次下单天数",
            prop: "days_without_order",
            display: false,
          },
          {
            label: "累计合作(金额)",
            prop: "total",
            display: false,
          },
          {
            label: "所属城市",
            prop: "region",
            filterable: true,
            type: "cascader",
            props: {
              label: "value",
              value: "value",
            },
            formatter: (row, value) => value && value.join("-"),
            dicUrl: "getCity",
            checkStrictly: true,
            showColumn: false,
          },
          {
            label: "详细地址",
            prop: "address",
            showColumn: false,
          },
          {
            label: "备注",
            prop: "info",
            type: "textarea",
            span: 24,
            showColumn: false,
          },
          // {
          //   label: "导入联系人",
          //   type: "upload",
          //   loadText: "附件上传中，请稍等",
          //   span: 24,
          //   propsHttp: {
          //     res: "res",
          //   },
          //   // tip: "只能上传ex",
          //   action: "customers/import",
          // },
          // {
          //   label: "导入联系人",
          //   type: "import-button",
          // },
          {
            label: "添加联系人",
            prop: "contacts",
            type: "dynamic",
            span: 24,
            children: {
              column: [
                {
                  label: "姓名",
                  prop: "name",
                },
                {
                  label: "手机号",
                  prop: "mobile",
                },
                {
                  label: "邮箱",
                  prop: "email",
                },
                {
                  label: "职务",
                  prop: "job",
                },
                {
                  label: "备注",
                  prop: "info",
                },
              ],
            },
          },
          {
            label: "开票管理",
            prop: "invoices",
            type: "dynamic",
            span: 24,
            children: {
              column: [
                {
                  label: "单位",
                  prop: "company",
                },
                {
                  label: "税号",
                  prop: "duty_paragraph",
                },
                {
                  label: "城市",
                  prop: "city",
                  type: "cascader",
                  props: {
                    label: "value",
                    value: "value",
                  },
                  formatter: (row, value) => value && value.join("-"),
                  dicUrl: "getCity",
                },
                {
                  label: "详细地址",
                  prop: "address",
                },
                {
                  label: "手机号",
                  prop: "mobile",
                },
                {
                  label: "开户行",
                  prop: "bank",
                },
                {
                  label: "开户行账号",
                  prop: "account",
                },
              ],
            },
          },
          {
            label: "加入黑名单",
            prop: "isBlacklist",
            span: 24,
            type: "switch",
            props: {
              label: "label",
              value: "value",
            },
            dicData: [
              {
                label: "否",
                value: 0,
              },
              {
                label: "是",
                value: 1,
              },
            ],
            // display: false,
            showColumn: false,
          },
        ],
      },
      // records: {
      //   translate: false,
      //   column: [
      //     {
      //       label: "跟进时间",
      //       prop: "time",
      //       type: "datetime",
      //       span: 8,
      //       format: "yyyy-MM-dd hh:mm:ss",
      //       mock: {
      //         type: "datetime",
      //         format: "yyyy-MM-dd hh:mm:ss",
      //         now: true,
      //       },
      //     },
      //     {
      //       label: "沟通方式",
      //       prop: "type",
      //       props: {
      //         label: "label",
      //         value: "label",
      //       },
      //       type: "select",
      //       dicUrl: `dictionaries/type/contact.way`,
      //       showColumn: false,
      //     },

      //     {
      //       label: "联系人",
      //       prop: "contact",
      //       type: "select",
      //       props: {
      //         label: "name",
      //         value: "name",
      //       },
      //       // dicData: this.contacts,
      //       showColumn: false,
      //     },
      //   ],
      // },
      // importModel: {
      //   file: null,
      // },
    };
  },

  computed: {
    history() {
      const rows = this.formRow.history;
      rows.sort((a, b) => {
        return Date.parse(b.time) - Date.parse(a.time);
      });
      return rows;
    },
  },
  methods: {
    async create(row, done) {
      row.isBlacklist = 1;
      await this.$http.post("customers", row);
      this.$message.success("创建成功");
      this.fetch();
      done();
    },
    async remove(row) {
      try {
        await this.$confirm("是否确认删除？");
      } catch (e) {
        return;
      }
      await this.$http.delete(`customers/${row._id}`);
      this.$message.success("删除成功");
      this.fetch();
    },
    async update(row, index, done) {
      await this.$http.put(`customers/${row._id}`, row);
      this.$message.success("更新成功");
      this.fetch();
      done();
    },
    uploadAfter(res, done, loading) {
      this.customerId = res._id;
      let contacts = this.findObject(this.option.column, "contacts");

      // let column= contacts.children.column
      // column.forEach(v=>{
      //   column.push(v)
      // })
      this.form.contacts = res.contacts;
      done();
      // console.log(contacts);
    },
    async search(where, done) {
      done();
      if (where.name) {
        this.searchName = { $regex: where.name };
      }
      this.fetch();
    },
    async changPage({ pageSize, currentPage }) {
      this.query.page = currentPage;
      this.query.limit = pageSize;
      this.fetch();
    },
    async detach(index) {
      try {
        await this.$confirm("是否确认删除？");
      } catch (e) {
        return;
      }
      this.$clear(this.formRow);
      this.formRow.history.reverse().splice(index, 1);
      this.formRow.history.reverse();
      await this.$http.put(`customers/${this.formRow._id}`, this.formRow);
      this.$message.success("删除成功");
      this.fetch();
    },
    async onSubmit() {
      this.$clear(this.formRow, true);
      this.record.submitedAt = new Date();
      this.formRow.history.push(this.record);
      await this.$http.put(`customers/${this.formRow._id}`, this.formRow);
      this.$message.success("提交成功");
      this.record = {};
      this.fetch();
    },
    followUp(scope) {
      this.formRow = scope.row;
      this.dialogVisible = true;
    },
    handleClose(done) {
      done();
    },
    async fetch() {
      let where = {};
      where.isBlacklist = 1;
      if (this.user.role !== 0) {
        where.user = { $in: [this.user._id, null] };
      }
      if (this.user.role == 5) {
        where.user = { $in: [...this.user.userInfo, this.user._id, null] };
      }
      if (this.searchName) {
        where.name = this.searchName;
      }
      const res = await this.$http.get("customers", {
        params: {
          query: {
            ...this.query,
            where,
          },
        },
      });
      this.page.total = res.data.total;
      this.data = res.data.data;
    },
    async fetchType() {
      const res = await this.$http.get("dictionaries/type/contact.way");
      this.options = res.data;
    },
  },
  created() {
    this.fetch();
    this.fetchType();
    // this.$store.dispatch('fetchUser')
  },
};
</script>

<style>
.createdAt {
  color: #555;
}

.block {
  height: 50vh;
  overflow: scroll;
}
.block::-webkit-scrollbar {
  width: 0 !important;
}
.commonpool .el-input--suffix .el-input__inner {
  padding-right: 24px !important;
}

.blacklist .el-dialog__body .el-form-item__label {
  width: auto !important;
}

.blacklist .el-dialog__body .el-form-item__content {
  margin-left: auto !important;
}
</style>
