<template>
  <el-row>
    {{record.isEdit}}
    <el-col :span="24" v-if="record.isEdit">
      <el-card class="mb-4">
        <el-form :inline="true">
          <el-form-item label="沟通日期">
            <el-date-picker
              v-model="record.time"
              type="datetime"
              placeholder="选择日期时间"
            ></el-date-picker>
          </el-form-item>

          <el-form-item label="沟通方式">
            <el-select v-model="record.contact_way" placeholder="请选择">
              <el-option
                v-for="item in ways"
                :key="item.id"
                :label="item.label"
                :value="item.label"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-input
            v-model="record.desc"
            type="textarea"
            placeholder="请输入沟通内容"
          ></el-input>

          <div class="text-center mt-2">
            <el-button type="primary" @click="onSubmit()">提交</el-button>
          </div>
        </el-form>
        <!-- <avue-form v-model="record" :option="records"> </avue-form> -->
      </el-card>
    </el-col>
    <el-col :span="24" v-else>
      <el-card>
        <div class="content">
          <div class="text">
            <p>沟通日期{{ record.desc }}</p>
            <p>{{ record.desc }}</p>
            <p>{{ record.desc }}</p>
          </div>
          <div class="action">
            <el-button>编辑</el-button>
            <el-button>删除</el-button>
          </div>
        </div>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
export default {
  data() {
    return {
      isEdit: false,
      options: [],
    };
  },
  props: {
    record: {
      type: Object,
      default: () => {},
    },
    ways: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    onSubmit() {
      this.$emit("submit", {
        ...this.record,
        isEdit: false,
      });
      this.isEdit=false;
    },
  },
};
</script>
