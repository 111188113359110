<template>
  <avue-crud
    :data="data"
    :option="option"
    @row-save="create"
    @row-update="update"
    @row-del="remove"
    :page.sync="page"
    @on-load="changPage"
    @search-change="search"
  ></avue-crud>
</template>

<script>
export default {
  data() {
    return {
      query: {
        limit: 10,
      },
      page: {
        total: 0,
      },
      data: [],
      option: {
        border: true,
        index: true,
        searchMenuSpan: 5,
        indexLabel: "序号",
        translate: false,
        column: [
          {
            label: "选项类型",
            prop: "type",
            props: {
              label: "label",
              value: "value",
            },
            span: 12,
            dicUrl: `type/name`,
            type: "select",
            rules: [
              {
                required: true,
                message: "请选择类型",
                trigger: "blur",
              },
            ],
          },
          {
            label: "选项名",
            prop: "label",
            span: 12,
            search: true,
            rules: [
              {
                required: true,
                message: "请输入选项名",
                trigger: "blur",
              },
            ],
          },
          {
            label: "存储值",
            prop: "value",
            type: "number",
          },
        ],
      },
    };
  },
  methods: {
    async changPage({ pageSize, currentPage }) {
      this.query.page = currentPage;
      this.query.limit = pageSize;
      this.fetch();
    },
    search(where, done) {
      done();
      if (where.label) {
        where.label = { $regex: where.label };
      }
      this.query.where = where;
      this.fetch();
    },
    async fetch() {
      const res = await this.$http.get("dictionaries", {
        params: {
          query: this.query,
        },
      });
      this.page.total = res.data.total;
      this.data = res.data.data;
    },
    async remove(row) {
      try {
        await this.$confirm("是否确认删除？");
      } catch (e) {
        return;
      }
      await this.$http.delete(`dictionaries/${row._id}`);
      this.$message.success("删除成功");
      this.fetch();
    },
    async update(row, index, done) {
      await this.$http.put(`dictionaries/${row._id}`, row);
      this.$message.success("更新成功");
      this.fetch();
      done();
    },
    async create(row, done) {
      await this.$http.post("dictionaries", row);
      this.$message.success("创建成功");
      this.fetch();
      done();
    },
  },
  created() {
    this.fetch();
  },
};
</script>

<style>
</style>