<template>
  <div>
    <div class="a-upload">
      <input
        type="file"
        width="741"
        height="500"
        @input="(e) => (file = e.target.files)"
        ref="upFile"
        value="保存"
        @change="save()"
      />上传文件
    </div>
    <div class="w-100">仅限导入xlsx、csv格式的文件</div>
  </div>
</template>

<script>
import XLSX from "xlsx";
export default {
  props: {
    id: String,
  },
  data() {
    return {
      file: {},
    };
  },
  methods: {
    async save() {
      const work = await this.readXLSX(this.file[0]);
      console.log(work.Sheets[Object.keys(work.Sheets)[0].toString()]);
      const data = work.Sheets[Object.keys(work.Sheets)[0].toString()];
      let ref = data["!ref"];
      let refNum = data["!ref"][data["!ref"].length - 1];
      let enRef = ref.match(/[A-Z]/g);
      let enSet = new Set(enRef);
      enRef = Array.from(enSet);
      console.log(enRef);
      let enRefBig = [];
      let firstEn = enRef.shift();
      let lastEn = enRef.pop();
      if (lastEn) {
        firstEnCode = firstEn.charCodeAt();
        lastEnCode = lastEn.charCodeAt();
      }
      let objArr = new Array();

      for (let i = 2; i <= refNum; i++) {
        let arr = [];
        for (let j = 0; j < enRef.length; j++) {
          const value =
            (data[`${enRef[j]}${i}`] && data[`${enRef[j]}${i}`].w) || "";
          arr.push(value);
        }
        objArr.push(arr);
      }
      let otherObj = [];
      let obj = {
        name: "",
        mobile: "",
        email: "",
        job: "",
        info: "",
      };

      for (let v of objArr) {
        for (let index = 0; index < 5; index++) {
          obj[Object.keys(obj)[index]] = v[`${index}`] || "";
        }
        otherObj.push(JSON.parse(JSON.stringify(obj)));
      }

      this.$emit("contacts", otherObj);
      console.log(otherObj);
    },
    readXLSX(file) {
      console.log(file);
      let nameSplit = file.name.split(".");
      let format = nameSplit[nameSplit.length - 1];
      if (!["xlsx", "csv"].includes(format)) {
        return false;
      }
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = function (evt) {
          let data = evt.target.result; // 读到的数据
          var workbook = XLSX.read(data, { type: "binary" });
          resolve(workbook);
        };
      });
    },
  },
};
</script>

<style>
.a-upload {
  padding: 4px 16px;
  height: 32px;
  line-height: 22px;
  font-size: 12px;
  position: relative;
  cursor: pointer;
  color: rgb(103, 194, 58);
  background: rgb(240, 249, 235);
  border: 1px solid rgb(194, 231, 176);
  border-radius: 4px;
  overflow: hidden;
  display: inline-block;
}
.a-upload input {
  position: absolute;
  font-size: 100px;
  right: 0;
  top: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  cursor: pointer;
}
.a-upload:hover {
  color: #fff;
  background: #85ce61;
  border-color: #85ce61;
  text-decoration: none;
}
</style>