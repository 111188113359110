<template>
  <div v-if="user.role == 0 && IsPC">
    <el-form
      class="pl-2"
      ref="form"
      label-width="80px"
      inline
      @submit.native.prevent="doImport"
    >
      <div class="d-flex mb-3">
        <div class="d-flex align-items-center">
          <div class="pr-3 pl-1 fs-9 import-ex">导入表格</div>
          <div class="a-upload">
            <span>点击上传</span>
            <input @input="parseItems" type="file" />
          </div>
        </div>

        <div class="ml-2">
          <el-button size="small" type="primary" native-type="submit"
            >导入</el-button
          >
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
export default {
    props:{
        parseItems:Function,
        doImport:Function
    },
  data() {
    return {
     
    };
  },
  methods:{

  }
};
</script>

<style>
</style>