<template>
  <div class="saleContract">
    <div v-if="this.user.role < 2 || this.user.role > 3" class="ml-4 mb-3">
      <el-button type="success" plain @click="exportSubmit"
        >导出其他业务</el-button
      >
    </div>
    <avue-crud
      :data="data"
      :option="options"
      @row-update="update"
      @row-del="remove"
      :page.sync="page"
      @on-load="changPage"
      @search-change="search"
      @row-save="create"
      :before-open="beforeOpen"
      :before-close="beforeClose"
      :upload-preview="preview"
      v-model="obj"
    >
      <template slot="customer" slot-scope="scope">
        <div>{{ _.get(scope.row, "customer.name") }}</div>
      </template>
      <template slot="teacher" slot-scope="scope">
        <div>{{ _.get(scope.row, "teacher.name") }}</div>
      </template>
      <template slot="user" slot-scope="scope">
        <div>{{ _.get(scope.row, "user.realname") }}</div>
      </template>
    </avue-crud>
  </div>
</template>

<script>
import * as dayjs from "dayjs";
export default {
  data() {
    return {
      query: {
        limit: 10,
        sort: { createdAt: -1 },
      },
      page: {
        total: 0,
      },
      data: [],
      obj: {},
      invoice_amount: 0,
      general_ticket: 0,
      special_ticket: 0,
      isAmount: false,
      brokers: [],
      invoices: [],
      post: null,
    };
  },
  computed: {
    options() {
      return {
        border: true,
        viewBtn: true,
        searchMenuSpan: 5,
        menuWidth: 200,
        index: true,
        indexLabel: "序号",
        translate: false,
        align: "center",
        column: [
          {
            label: "讲师",
            prop: "teacher",
            filterable: true,
            props: {
              label: "name",
              value: "_id",
            },
            span: 12,
            type: "select",
            rules: [
              {
                required: true,
                message: "请选择讲师",
                trigger: "blur",
              },
            ],
            slot: true,
            // dicData: this.teacherOption,
            dicUrl: `teachers/option`,
            dicQuery: {
              query: {
                limit: 0,
              },
            },
          },
          {
            label: "合作机构",
            prop: "customer",
            span: 12,
            slot: true,
            width: 250,
            props: {
              label: "name",
              value: "_id",
            },
            dicUrl: `customers/list`,
            rules: [
              {
                required: true,
                message: "请选择客户名称",
                trigger: "blur",
              },
            ],
            type: "select",
            filterable: true,
          },
          {
            label: "课程名称",
            prop: "post",
            span: 8,
            search: true,
            slot: true,
            width: 300,
            rules: [
              {
                required: true,
                message: "请输入课程名称",
                trigger: "blur",
              },
            ],
          },
          {
            label: "合同金额",
            prop: "order_amount",
            type: "number",
            span: 8,
          },

          {
            label: "经纪人姓名",
            prop: "agent_name",
            type: "select",
            filterable: true,
            span: 8,
            props: {
              label: "realname",
              value: "realname",
            },
            dicData: this.brokers,
            rules: [
              {
                required: true,
                message: "请选择经纪人",
                trigger: "blur",
              },
            ],
          },
          {
            label: "合同细则",
            prop: "contract",
            type: "upload",
            dataType: "array",
            listType: "picture-card",
            limit: 20,
            action: "upload",
            span: 24,
          },
          {
            label: "合同附件",
            prop: "file",
            type: "upload",
            loadText: "附件上传中，请稍等",
            span: 18,
            showColumn: false,
            action: "upload",
            // rules: [
            //   {
            //     required: this.isContractUpload,
            //     message: "请上传合同附件",
            //     trigger: "blur",
            //   },
            // ],
            hide: true,
          },
          {
            label: "合同签订时间",
            prop: "contract_signing_time",
            type: "date",
            format: "yyyy-MM-dd",
            valueFormat: "yyyy-MM-dd",
            rules: [
              {
                required: true,
                message: "请选择合同签订时间",
                trigger: "blur",
              },
            ],
          },
          {
            label: "开票公司",
            prop: "billing_company",
            props: {
              label: "company",
              value: "_id",
            },
            type: "select",
            dicData: this.billingCompanies,
            dicQuery: {
              query: {
                limit: 0,
              },
            },
            span: 12,
            showColumn: false,
            hide: true,
          },
          {
            label: "发票类型",
            prop: "billing_type",
            type: "radio",
            display: this.isBilling_company,
            dicData: [
              {
                label: `普票(税率:${this.general_ticket}%)`,
                value: "普票",
              },
              {
                label: `专票(税率:${this.special_ticket}%)`,
                value: "专票",
              },
            ],
            rules: [
              {
                required: true,
                message: "请选择发票类型",
                trigger: "blur",
              },
            ],
            span: 12,
            showColumn: false,
            hide: true,
          },
          {
            label: "发票金额",
            prop: "invoice_amount",
            type: "number",
            span: 12,
            display: this.isBilling_company,
            rules: [
              {
                required: true,
                message: "请填写发票金额",
                trigger: "blur",
              },
            ],
            showColumn: false,
            hide: true,
          },
          {
            label: "客户开票公司",
            filterable: true,
            prop: "customer_billing_company",
            type: "select",
            span: 12,
            props: {
              label: "company",
              value: "company",
              // res: 'contacts',
              // children: 'contacts'
            },
            dicData: [],
            hide: true,
            showColumn: false,
          },
          {
            label: "开票时间",
            prop: "billing_time",
            display: this.isBilling_company,
            type: "datetime",
            span: 12,
            format: "yyyy-MM-dd hh:mm:ss",
            mock: {
              type: "datetime",
              format: "yyyy-MM-dd hh:mm:ss",
              now: true,
            },
            hide: true,
            showColumn: false,
          },
          {
            label: "备注",
            prop: "info",
            type: "textarea",
            span: 24,
            minRows: 2,
            hide: true,
            showColumn: false,
          },
          {
            label: "回款记录",
            prop: "payBack",
            type: "dynamic",
            span: 24,
            hide: true,
            children: {
              column: [
                {
                  label: "回款日期",
                  prop: "date",
                  type: "datetime",
                  span: 8,
                  rules: [
                    {
                      required: true,
                      message: "请选择回款日期",
                      trigger: "blur",
                    },
                  ],
                  format: "yyyy-MM-dd hh:mm:ss",
                  mock: {
                    type: "datetime",
                    format: "yyyy-MM-dd hh:mm:ss",
                    now: true,
                  },
                },
                {
                  label: "回款金额",
                  prop: "money",
                  rules: [
                    {
                      required: true,
                      message: "请填写回款金额",
                      trigger: "blur",
                    },
                  ],
                },
                {
                  label: "备注",
                  prop: "info",
                  type: "textarea",
                },
              ],
            },
          },
          {
            label: "创建人",
            type: "select",
            props: {
              label: "realname",
              value: "_id",
            },
            prop: "user",
            span: 8,
            slot: true,
            display: false,
          },
        ],
      };
    },

    // 判断是否选了开票公司
    isBilling_company() {
      const bool =
        this.obj.billing_company && this.obj.billing_company.length > 0
          ? true
          : false;
      return bool;
    },
  },
  watch: {
    "obj.customer": {
      async handler(id) {
        if (id) {
          this.fetchInvoice(id);
        }
      },
    },
    "obj.billing_company": {
      async handler(id) {
        if (id) {
          await this.fetchInvoiceType(id);
          this.fetchAmount(this.obj.billing_type);
        } else {
          this.obj.billing_type = "";
          this.obj.invoice_amount = 0;
        }
      },
    },

    "obj.billing_type": {
      async handler(v) {
        if (v && this.isAmount) {
          this.fetchAmount(v);
        }
      },
    },
  },
  methods: {
    preview(file, column, done) {
      const ext = file.url.split(".").pop();
      if (["jpg", "jpeg", "png", "gif"].includes(ext)) {
        done();
      } else {
        window.open(file.url);
      }
    },
    async fetchInvoice(id) {
      const customer = (await this.$http.get(`customers/${id}`)).data;
      const field = this.options.column.find(
        (v) => v.prop === "customer_billing_company"
      );
      const options =
        customer.invoices && customer.invoices.length > 0
          ? customer.invoices
          : [{ name: "" }];
      field.dicData = options;
    },

    async exportSubmit(row) {
      if (this.user.role == 5) {
        row.user = [...this.user.userInfo, this.user._id];
      } else {
        row.user = [this.user._id];
      }
      row.role = this.user.role;
      const res = await this.$http.post(
        "/schedules/otherBusinesseExport/",
        row,
        {
          responseType: "arraybuffer",
        }
      );
      const link = document.createElement("a");
      let blob = new Blob([res.data], { type: "application/x-xls" });
      link.href = URL.createObjectURL(blob);
      const title = `其他业务数据-${dayjs().format("YYYY-MM-DD")}`;
      link.download = `${title}.xlsx`;
      link.click();
      document.body.appendChild(link);
    },
    beforeOpen(done, type) {
      const customer = this.obj.customer || {};
      this.obj.customer = customer._id;
      const teacher = this.obj.teacher || {};
      this.obj.teacher = teacher._id;
      const billing_company = this.obj.billing_company || {};
      this.obj.billing_company = billing_company._id;
      done();
    },
    beforeClose(done, type) {
      this.isAmount = false;
      done();
    },
    // 根据当前选择的开票公司返回它的税率
    async fetchInvoiceType(id) {
      const invoice = (await this.$http.get(`invoices/${id}`)).data;
      if (invoice.general_ticket) {
        this.general_ticket = invoice.general_ticket;
      } else {
        this.general_ticket = 0;
      }
      if (invoice.special_ticket) {
        this.special_ticket = invoice.special_ticket;
      } else {
        this.special_ticket = 0;
      }
    },
    async fetchAmount(v) {
      if (!this.isAmount) {
        this.isAmount = true;
      } else {
        const rate =
          v == "专票" ? this.special_ticket / 100 : this.general_ticket / 100;
        this.obj.invoice_amount = (
          this.obj.order_amount +
          this.obj.order_amount * rate
        ).toFixed(2);
      }
    },
    async changPage({ pageSize, currentPage }) {
      this.query.page = currentPage;
      this.query.limit = pageSize;
      this.fetch();
    },
    async search(where, done) {
      done();
      if (where.post) {
        this.post = { $regex: where.post };
      }
      // this.query.where = where;
      this.fetch();
    },
    async update(row, index, done) {
      await this.$http.put(`schedules/${row._id}`, row);
      this.$message.success("更新成功");
      this.fetch();
      done();
    },

    async create(row, done, index) {
      try {
        row.status = 14;
        await this.$http.post("schedules", row);
        this.$message.success("创建成功");
        this.fetch();
        done();
      } catch {
        // done();
      }
    },

    async remove(row) {
      try {
        await this.$confirm("是否确认删除？");
      } catch (e) {
        return;
      }
      await this.$http.delete(`schedules/${row._id}`);
      this.$message.success("删除成功");
      this.fetch();
    },
    async fetchUser() {
      const res = await this.$http.get(`users`, {
        params: {
          query: {
            limit: 0,
            where: {
              role: { $in: [1, 5] },
            },
          },
        },
      });
      const users = res.data.data;
      const fields = this.options.column.find((v) => v.prop === "agent_name");
      this.brokers = users;
    },
    async fetchBilling() {
      this.billingCompanies = (await this.$http.get("invoices/option")).data;
    },
    async fetch() {
      let where = {};
      if (![0, 8].includes(this.user.role)) {
        (where.user = { $in: [...this.user.userInfo, this.user._id] }),
          (where.status = 14);
      } else {
        where.status = 14;
      }
      if (this.post) {
        where.post = this.post;
      }
      const res = await this.$http.get("schedules", {
        params: {
          query: {
            ...this.query,
            where,
          },
        },
      });
      this.page.total = res.data.total;
      this.data = res.data.data;
    },
  },
  created() {
    this.fetch();
    this.fetchUser();
    this.fetchBilling();
  },
};
</script>

<style></style>
