<template>
  <div class="behavior_list">
    <div class="ml-2 d-flex align-items-center justify-content-end px-3">
      <el-radio-group
        v-model="ranking"
        size="mini "
        class="d-flex align-items-center mt-2"
        @change="getBoard"
      >
        <el-radio-button label="day">本日</el-radio-button>
        <el-radio-button label="week">本周</el-radio-button>
        <el-radio-button label="month">本月</el-radio-button>
        <el-radio-button label="year">全年</el-radio-button>
      </el-radio-group>
      <span class="demonstration mr-2 fs-9 mx-2">当前数据日期</span>

      <el-date-picker
        v-model="dataDate.dataMonth"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        style="width: 400px !important"
      >
      </el-date-picker>
      <el-button type="success" class="mx-2" @click="fetchBehaviorOptions"
        >搜索</el-button
      >
    </div>
    <div class="search my-3">
      <avue-data-panel :option="behaviorOptions"></avue-data-panel>
    </div>

    <el-drawer
      title="数据详情"
      :visible.sync="showDetailModel"
      :before-close="handleDetailClose"
      size="80%"
    >
      <avue-crud
        ref="crud"
        :data="behaviorData"
        :option="behaviorOption"
        :page.sync="page"
        @on-load="changPage"
        :table-loading="loading"
        @search-change="search"
        @row-del="remove"
        @row-save="create"
        @row-update="update"
      >
        <!-- <template slot="time" slot-scope="scope">
        {{ scope.row.time | formatDate }}
      </template> -->
        <template slot="actioner" slot-scope="scope">
          {{ scope.row.actioner.realname }}
        </template>

        <template slot="action" slot-scope="scope">
          <span
            v-if="scope.row.action === 'download'"
            class="d-flex flex-wrap justify-content-center"
          >
            {{ actinInfo(scope.row) }}

            <el-link
              style="color: #409eff"
              type="primary"
              class="fs-10 mx-1"
              @click="previewFile(item)"
              v-for="item in scope.row.files"
              :key="item._id"
              >{{ item.name }}</el-link
            >
          </span>
          <span class="fs-10" v-else
            >{{ scope.row.actioner.realname }}
            {{ scope.row.time | formatDate }} {{ actions[scope.row.action] }}了
          </span>

          <!-- <el-button
          type="text"
          class="mx-3"
          @click="showDetail(scope.row)"
          v-if="scope.row.action === 'download'"
          >详情日志</el-button
        > -->
        </template>
      </avue-crud>
    </el-drawer>

    <el-drawer
      title="下载详情"
      :visible.sync="showDrawer"
      :before-close="handleClose"
      size="50%"
    >
      <div class="px-2 files_record">
        <h6 class="fs-9 d-flex" v-if="detail.data && detail.data.key">
          {{ detail.data.key === "outline" ? "课程大纲" : "公司产品" }}
          <span class="ml-auto fs-10"
            >操作时间：{{ detail.time | formatDate }}</span
          >
          <span class="mx-2 fs-10">操作人：{{ detail.actioner.realname }}</span>
          <span class="mx-2 fs-10">操作ip：{{ detail.ip }}</span>
        </h6>

        <div class="item" v-for="item in detail.files" :key="item._id">
          <div class="icon mx-1">
            <span class="iconfont icon-shizibaozhang"></span>
          </div>
          <div class="link fs-9">
            {{ item.name }}
          </div>
          <div class="action">
            <el-button type="text" @click="previewFile(item)">查看</el-button>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
  
  <script>
import { formatDateY, validMobile, formatDate } from "../../filters";
import { Docxer } from "../../plugins/docx";
import * as dayjs from "dayjs";

// import ExpandText from "../../components/ExpandText.vue";

export default {
  data() {
    return {
      loading: false,
      teacher: {
        communicate_progress: [],
      },

      dialogVisible: false,
      editComm: "",
      record: {
        time: "",
        desc: "",
        way: "",
        user: "",
      },
      types: {
        download: "primary",
        login: "success",
        logout: "danger",
        watch: "warning",
      },
      page: {
        total: 0,
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 50, 100],
      },
      behaviorData: [],
      behaviorOptions: {},
      actionKey: "",
      queyPage: {
        page: 1,
        limit: 10,
      },

      actions: [],

      dataQuery: {
        times: [],
        actioner: "",
      },
      way_options: [],
      select_list: [],
      superintendents: [],
      detail: {},
      showDrawer: false,
      showDetailModel: false,
      ranking: "day",
      dataDate: {
        dataMonth: "",
      },
    };
  },
  computed: {
    behaviorOption() {
      return {
        border: true,
        searchMenuSpan: 24,
        dialogWidth: 900,
        menuWidth: 280,
        viewBtn: true,
        addBtn: false,
        align: "center",
        editBtn: true,
        delBtn: true,
        dialogClickModal: false,
        index: true,
        indexLabel: "序号",
        translate: false,
        menu: false,
        behaviorOption: {},

        column: [
          {
            label: "操作人",
            prop: "actioner",
            border: true,
            search: true,
            slot: true,
            props: {
              label: "realname",
              value: "_id",
            },
            span: 8,
            dicUrl: `users/behavior/option`,
            type: "select",
            width: 150,
          },
          {
            label: "操作日志",
            prop: "action",
            border: true,
            showColumn: false,
            slot: true,
          },
          // {
          //   label: "操作时间",
          //   prop: "time",
          //   border: true,
          //   search: false,
          //   slot: true,
          //   display: false,
          // },
          {
            label: "时间",
            prop: "times",
            searchSpan: 8,
            slot: true,
            // search: true,
            type: "datetime",
            searchRange: true,
            display: false,
            hide: true,
          },
        ],
      };
    },
    fileName() {
      return (row) => {
        const files = row.files ?? [];
        return files.map((v) => v.filename).join(",");
      };
    },
    actinInfo() {
      return (row) => {
        const files = row.files ?? [];
        const filename = files.map((v) => v.name).join(",");
        console.log(filename);
        return `
        ${row.actioner.realname} ${this.formatDate(row.time)}  ${
          this.actions[row.action]
        }了 `;
      };
    },
  },
  components: {
    // ExpandText
  },
  methods: {
    formatDate,
    formatDateY,
    getTimes() {
      let from, to;
      let rank = this.ranking;
      if (rank === "week") {
        from = dayjs().startOf("week").toDate();
        to = dayjs().endOf("week").toDate();
      } else if (rank == "month") {
        from = dayjs().startOf("month").toDate();
        to = dayjs().endOf("month").toDate();
      } else if (rank == "year") {
        const year = dayjs().format("YYYY");
        from = dayjs(year).startOf("year").toDate();
        to = dayjs(year).endOf("year").toDate();
      } else if (rank == "day") {
        from = dayjs().startOf("day").toDate();
        to = dayjs().endOf("day").toDate();
      }

      return { from, to };
    },
    async fetchType() {
      const res = await this.$http.get("dictionaries/type/contact.way");
      this.way_options = res.data.sort();
    },
    async fetchBehaviorOptions() {
      const res = await this.$http.get("behaviors/board", {
        params: {
          ...this.dataDate,
          rank: this.ranking,
        },
      });
      this.behaviorOptions = res.data;
      this.behaviorOptions.data = this.behaviorOptions.data.map((v) => {
        return {
          ...v,
          click: () => {
            this.actionKey = v.key;
            this.showDetailModel = true;
            this.behaviorData = [];
            console.log(this.ranking)
            if (this.ranking) {
              const { from, to } = this.getTimes();
              this.dataQuery.times = [from, to];
            }
            if (this.dataDate.dataMonth && this.dataDate.dataMonth.length) {
              this.dataQuery.times = this.dataDate.dataMonth;
            }
            this.fetchData();
          },
        };
      });
    },
    getBoard() {
      this.dataDate.dataMonth = [];
      this.fetchBehaviorOptions();
    },

    showDetail(row) {
      this.detail = row;
      this.showDrawer = true;
    },
    handleClose() {
      this.detail = {};
      this.showDrawer = false;
    },
    handleDetailClose() {
      this.showDetailModel = false;
      this.behaviorData = [];
      this.page = {
        total: 0,
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 50, 100],
      };
    },

    async remove(row) {
      await this.$confirm("是否确认删除此数据？");
      await this.$http.delete(`develop-teacher/` + row._id);

      this.$message.success("删除成功");
      this.fetchData();
    },
    async changPage({ pageSize, currentPage }) {
      this.queyPage.page = currentPage;
      this.queyPage.limit = pageSize;
      this.fetchData();
    },
    selectionChange(vals) {
      this.select_list = vals;
    },

    async previewFile(file) {
      const url = file.url;
      const ext = url.split(".").pop();

      const isDoc = ["doc", "docx", "ppt", "pptx", "xlsx"].includes(ext);

      const isPdf = ["pdf"].includes(ext);

      const isImage = ["png", "jpeg", "jpg"].includes(ext);

      if (isImage) {
        return this.$ImagePreview([file], 0, {
          closeOnClickModal: true,
          interval: 1000,
        });
      }

      if (isPdf) {
        return window.open(url);
      }

      if (!isDoc && !isImage) {
        return this.$message.error("文件类型暂不支持预览");
      }

      if (isDoc) {
        return Docxer.viewDoc(url);
      }
    },

    showComm(scope) {
      this.editComm = scope.row;
      this.dialogVisible = true;
    },
    dataClose() {
      this.dialogVisible = false;
    },
    async onSubmit() {
      this.record.submitedAt = new Date();
      if (this.record && this.record.user == undefined) {
        this.record.user = this.user._id;
      }
      this.editComm.communicate_progress.push(this.record);
      await this.updateItem(this.editComm);
      this.record = {
        time: "",
        desc: "",
        way: "",
        user: "",
      };
    },
    async fetchUser() {
      const ret = await this.$http.get(`users`, {
        params: {
          query: {
            limit: 0,
            where: {
              role: { $in: [1, 4, 5, 0] },
            },
          },
        },
      });
      const superintendent = ret.data.data;
      this.superintendents = superintendent;

      const res = await this.$http.get(`users/director`);
      const users = res.data;
      users.unshift({
        _id: "空",
        realname: "空",
      });
      this.users = users;

      if (this.option && this.option.column) {
        const field = this.option.column.find((v) => v.prop === "user");
        field.dicData = this.users;
      }
    },

    Addcommunicate(row) {
      let list = row.communicate_progress ?? [];
      list.push({
        user: "",
        action: "",
        time: new Date(),
        way: "",
        desc: "",
        contact_way: "",
        isEdit: true,
      });
    },
    updateComm(val, index, row) {
      this.$set(row.communicate_progress, index, val);
    },
    async create(row, done) {
      await this.$http.post("develop-teacher", row);
      this.$message.success("创建成功");
      this.fetchData();
      done();
    },

    async update(row, index, done) {
      if (!row.communication_times) {
        row.communication_times = [];
      }
      await this.$http.put(`develop-teacher/${this.teacher._id}`, row);
      this.$message.success("更新成功");
      this.fetchData();
      done();
    },

    search(val, done) {
      this.dataQuery.actioner = val.actioner || "";
      this.dataQuery.times = val.times || [];
      done();
      this.fetchData();
    },

    // 获取师资开发列表
    async fetchData(item) {
      const { data } = await this.$http.get("behaviors", {
        params: {
          ...this.queyPage,
          where: {
            ...this.dataQuery,
            action: this.actionKey,
          },
        },
      });
      this.page.total = data.total;
      this.behaviorData = data.data;
      const { data: action_data } = await this.$http.get(
        "behaviors/actions/options"
      );
      this.actions = action_data;
      console.log(this.actions);
    },

    async downloadInfos(row) {
      const techer_files = (row.teacher_files ?? []).map((v, index) => {
        const ext = v.split(".").pop();
        return {
          url: v,
          filename: `讲师资料${index + 1}.${ext}`,
        };
      });

      const interview_course = (row.interview_course ?? []).map((v, index) => {
        const ext = v.split(".").pop();
        return {
          url: v,
          filename: `试讲课件${index + 1}.${ext}`,
        };
      });
      const files = techer_files.concat(interview_course);

      if (!files.length) {
        return this.$message.error("暂无资料");
      }

      await ossClient.batchDownload(
        files,
        () => {
          this.$message.success("下载成功");
        },
        () => {
          this.$message.error("下载失败,请重试");
        },
        row.name + "资料"
      );

      console.log(files);
    },
  },

  created() {
    this.fetchData();
    this.fetchType();
    this.fetchUser();
    this.fetchBehaviorOptions();
  },
};
</script>
  
  <style>
.courseware .text-truncate {
  color: #409eff;
}

.files_record {
  /* display: flex; */
}

.files_record .item {
  display: flex;
  padding: 0.5rem;
  border: 1px solid #eee;
  border-radius: 0.5rem;
  margin: 0.5rem 0;
}

.files_record .item .link {
  color: #409eff;
  flex: 1;
  display: flex;
  justify-content: center;
}

.files_record .item .icon {
  width: 30px;
  height: 30px;
  background: #409eff;
  border-radius: 50%;
  font-size: 18px;
  display: flex;
  color: white;
  align-items: center;
  justify-content: center;
}

.files_record .item .action {
  width: 50px;
}
/* .avue-data-panel .el-col:nth-child(1) .item{
  background: linear-gradient(45deg, #1BDBD0 25%,#2980b9 75%);
  color: white;
} 

.avue-data-panel .el-col:nth-child(3) .item{
  background: linear-gradient(45deg, #E63494 25%,#2980b9 75%);
  color: white;
} 

.avue-data-panel .el-col:nth-child(2) .item{
  background: linear-gradient(45deg, #007BFF 25%,#2980b9 75%);
  color: white;
}  */
</style>
  