<template>
  <div class="customer">
    <avue-crud
      :data="data"
      :option="option"
      @row-save="create"
      @row-update="update"
      @row-del="remove"
      :page.sync="page"
      @on-load="changPage"
      :upload-after="uploadAfter"
      v-model="form"
      @search-change="search"
    >
      <template slot-scope="scope" slot="menu">
        <el-button
          @click="payBack(scope)"
          icon="el-icon-refresh"
          :size="scope.size"
          :type="scope.type"
          >回款</el-button
        >
        <el-button
          @click="schedule(scope)"
          icon="el-icon-date"
          :size="scope.size"
          :type="scope.type"
          >排期记录</el-button
        >
      </template>
    </avue-crud>

    <el-dialog
      title="回款记录"
      :visible.sync="dialogPayBack"
      width="60%"
      :before-close="handleClose"
    >
      <avue-crud
        class="payBack"
        v-if="formRow"
        :data="formRow.payBack"
        :option="payBackOption"
        @row-save="payBackCreate"
        @row-del="removePayBack"
        @row-update="payBackUpdate"
      >
      </avue-crud>
    </el-dialog>

    <el-dialog
      title="排期记录"
      :visible.sync="dialogSchedule"
      width="80%"
      :before-close="handleClose"
    >
      <avue-crud
        :data="schedules"
        :option="scheduleOption"
        :page.sync="page"
        @on-load="scheduleChangPage"
      >
        <template slot="times" slot-scope="scope">
          <div>{{ scope.row.times.join("&") | formatDateT }}</div>
        </template>
      </avue-crud>
    </el-dialog>
    <!-- 
          <el-dialog
      title="回款"
      :visible.sync="dialogVisible"
      width="60%"
      :before-close="handleClose"
    >
      <avue-form
        @submit="payBacksubmit"
        :option="diaLogOption"
        v-model="payBackForm"
      >
      </avue-form>
    </el-dialog> -->
  </div>
</template>

<script>
let baseUrl = "https://cli.avuejs.com/api/area";
import * as dayjs from "dayjs";

export default {
  data() {
    return {
      orderId: "",
      schedules: [],
      payBackdata: [],
      contractForm: {},
      schedulePage: 1,
      scheduleLimit: 2,
      total: 0,
      query: {
        limit: 10,
      },
      page: {
        total: 0,
      },
      totalType: [],
      contacts: [],
      form: {},
      formRow: {},
      record: {
        contact: "",
        contact_way: "",
        time: "",
        content: "",
        submitedAt: new Date(),
      },
      options: [],
      dialogSchedule: false,
      dialogPayBack: false,
      data: [],
      scheduleOption: {
        align: "center",
        menuBtn: false,
        header: false,
        menu: false,
        column: [
          {
            label: "讲师",
            prop: "teacher",
            props: {
              label: "name",
              value: "_id",
            },
            span: 24,
            type: "select",
            dicUrl: `teachers`,
            display: false,
          },
          {
            label: "讲师课酬",
            prop: "price",
            type: "number",
            span: 8,
          },
          {
            label: "订单",
            prop: "order",
            rules: [
              {
                required: true,
                message: "请选择订单",
                trigger: "blur",
              },
            ],
            props: {
              label: "name",
              value: "_id",
            },
            type: "select",
            span: 8,
            dicUrl: `orders`,
            showColumn: false,
          },

          {
            label: "客户名称",
            prop: "customer",
            props: {
              label: "name",
              value: "_id",
            },
            span: 8,
            rules: [
              {
                required: true,
                message: "请选择客户名称",
                trigger: "blur",
              },
            ],
            type: "select",
            dicUrl: `customers`,
          },
          {
            label: "联系人",
            prop: "contact",
            type: "select",
            span: 8,
            props: {
              label: "name",
              value: "name",
            },
            dicData: [],
            // dicUrl: `customers/contacts/`,
            showColumn: false,
          },

          {
            label: "上课时间",
            prop: "times",
            type: "dates",
            format: "yyyy-MM-dd",
            // formatter: (row, value) => dayjs(value) && value.join("-").dayjs(value),
            slot: true,
            rules: [
              {
                required: true,
                message: "请选择上课时间",
                trigger: "blur",
              },
            ],
            span: 24,
          },
          {
            label: "课程名称",
            prop: "post",
            span: 8,
            showColumn: false,
          },
          {
            label: "授课城市",
            prop: "city",
            type: "cascader",
            span: 8,
            props: {
              label: "name",
              value: "name",
            },
            formatter: (row, value) => value && value.join("-"),
            rules: [
              {
                required: true,
                message: "请选择授课城市",
                trigger: "blur",
              },
            ],
            lazy: true,
            lazyLoad(node, resolve) {
              let stop_level = 2;
              let level = node.level;
              let data = node.data || {};
              let code = data.code;
              let list = [];
              let callback = () => {
                resolve(
                  (list || []).map((ele) => {
                    return Object.assign(ele, {
                      leaf: level >= stop_level,
                    });
                  })
                );
              };
              if (level == 0) {
                this.$http.get(`${baseUrl}/getProvince`).then((res) => {
                  list = res.data.data;
                  callback();
                });
              }
              if (level == 1) {
                this.$http.get(`${baseUrl}/getCity/${code}`).then((res) => {
                  list = res.data.data;
                  callback();
                });
              } else if (level == 2) {
                this.$http.get(`${baseUrl}/getArea/${code}`).then((res) => {
                  list = res.data.data;
                  callback();
                });
              }
            },
          },
          {
            label: "授课地址",
            prop: "address",
            placeholder: "详细地址",
            span: 8,
          },
          {
            label: "终端名称",
            prop: "terminal_name",
            span: 8,
          },
          {
            label: "终端行业",
            prop: "terminal_industry",
            type: "select",
             props: {
              label: "label",
              value: "label",
            },
            span: 8,
            dicUrl: `dictionaries/type/industry.type`,
            showColumn: false,
          },
          {
            label: "学员类型",
            prop: "student_type",
            span: 8,
          },
          {
            label: "学员人数",
            prop: "student_amount",
            type: "number",
            span: 8,
          },
          {
            label: "订单总额",
            prop: "order_amount",
            type: "number",
            span: 8,
          },
          {
            label: "备注",
            prop: "info",
            type: "textarea",
            span: 24,
            minRows: 2,
            showColumn: false,
            placeholder: "档期备注",
          },
        ],
      },
      payBackOption: {
        translate: false,
        column: [
          {
            prop: "date",
            label: "回款日期",
            type: "datetime",
            format: "yyyy-MM-dd hh:mm:ss",
            valueFormat: "yyyy-MM-dd hh:mm:ss",
          },
          // {
          //   prop: "orderName",
          //   label: "订单名称",
          // },
          {
            prop: "money",
            label: "回款金额",
            type: "number",
          },
        ],
      },
   
      option: {
        searchMenuSpan: 4,
        menuWidth: 300,
        align: "center",
        translate: false,
        dialogClickModal: false,
        index: true,
        indexLabel: "序号",
        title: "表格的标题",
        column: [
          {
            label: "订单名称",
            prop: "name",
            search: true,
            span: 8,
            rules: [
              {
                required: true,
                message: "请输入订单名称",
                trigger: "blur",
              },
            ],
          },
          {
            label: "客户名称",
            prop: "customer",
            props: {
              label: "name",
              value: "_id",
            },
            span: 8,
            type: "select",
            dicUrl: `customers`,
            rules: [
              {
                required: true,
                message: "请选择客户",
                trigger: "blur",
              },
            ],
          },
          // {
          //   label: "订单类型",
          //   prop: "type",
          //   type: "select",
          //   dicUrl: `dictionaries/type/order.type`,
          // },
          {
            label: "联系人",
            prop: "contact",
            type: "select",
            span: 8,
            props: {
              label: "name",
              value: "name",
            },
            dicData: [],
            // dicUrl: `customers/contacts/`,
            showColumn: false,
          },
          {
            label: "讲师",
            prop: "teacher",
            props: {
              label: "name",
              value: "id",
            },
            span: 8,
            type: "select",
            dicUrl: `teachers`,
            rules: [
              {
                required: true,
                message: "请选择讲师",
                trigger: "blur",
              },
            ],
          },
          {
            label: "实施日期",
            prop: "effective_date",
            type: "date",
            format: "yyyy-MM-dd",
            valueFormat: "yyyy-MM-dd",
            mock: {
              type: "datetime",
              format: "yyyy-MM-dd",
            },
            rules: [
              {
                required: true,
                message: "请选择实施日期",
                trigger: "blur",
              },
            ],
            span: 8,
          },
          {
            label: "所属地区",
            prop: "region",
            type: "cascader",
            span: 8,
            props: {
              label: "name",
              value: "name",
            },
            formatter: (row, value) => value && value.join("-"),
            lazy: true,
            lazyLoad(node, resolve) {
              let stop_level = 2;
              let level = node.level;
              let data = node.data || {};
              let code = data.code;
              let list = [];
              let callback = () => {
                resolve(
                  (list || []).map((ele) => {
                    return Object.assign(ele, {
                      leaf: level >= stop_level,
                    });
                  })
                );
              };
              if (level == 0) {
                this.$http.get(`${baseUrl}/getProvince`).then((res) => {
                  list = res.data.data;
                  callback();
                });
              }
              if (level == 1) {
                this.$http.get(`${baseUrl}/getCity/${code}`).then((res) => {
                  list = res.data.data;
                  callback();
                });
              } else if (level == 2) {
                this.$http.get(`${baseUrl}/getArea/${code}`).then((res) => {
                  list = res.data.data;
                  callback();
                });
              }
            },
          },

          {
            label: "终端名称",
            prop: "terminal_name",
            span: 8,
            showColumn: false,
          },

          {
            label: "终端行业",
            prop: "terminal_industry",
            type: "select",
            span: 8,
            dicUrl: `industry`,
          },
          {
            label: "订单状态",
            prop: "state",
            type: "radio",
            span: 8,
            dicUrl: `dictionaries/type/order.state`,
            rules: [
              {
                required: true,
                message: "请选择订单状态",
                trigger: "blur",
              },
            ],
          },
          {
            label: "订单金额",
            prop: "price",
            type: "number",
            span: 8,
            rules: [
              {
                required: true,
                message: "请输入订单金额",
                trigger: "blur",
              },
            ],
          },

          {
            label: "实收金额",
            prop: "true_price",
            display: false,
          },
          {
            label: "应收金额",
            prop: "no_price",
            display: false,
          },
          // {
          //   label: "订单确认",
          //   prop: "days_not_contacted",
          //   display: false,
          // },
          {
            label: "备注",
            prop: "info",
            type: "textarea",
            span: 24,
            minRows: 2,
            showColumn: false,
          },

          {
            prop: "number",
            label: "合同编号",
            type: "input",
            span: 8,
            showColumn: false,
          },
          {
            label: "合同文件",
            prop: "certificate",
            type: "upload",
            dataType: "array",
            listType: "picture-card",
            action: "upload",
            span: 24,
            showColumn: false,
          },
          {
            label: "创建日期",
            prop: "updatedAt",
            type: "date",
            format: "yyyy-MM-dd hh:mm:ss",
            valueFormat: "yyyy-MM-dd hh:mm:ss",
            display: false,
          },
        ],
      },
    };
  },

  watch: {
    "form.customer": {
      handler(id) {
        if (id && !this.form.contact) {
          this.fetchContact(id);
        }
      },
      immediate: true,
    },
  },

  computed: {
    isImplement() {
      const isFile = this.data.map((v) => {
        // console.log(v.contract.file)
        return v.contract.file ? true : false;
      });
      return isFile;
    },
  },

  methods: {
    uploadAfter(res, done, loading, column) {
      done();
      this.$message.success("上传后的方法");
    },

    // async diaLogsubmit() {
    //   this.$clear(this.formRow);
    //   this.formRow["contract"] = this.contractForm;
    //   // console.log(this.formRow)
    //   let res = await this.$http.put(
    //     `orders/${this.formRow._id}`,
    //     this.formRow
    //   );
    // },
    async changPage({ pageSize, currentPage }) {
      this.query.page = currentPage;
      this.query.limit = pageSize;
      this.fetch();
    },
    async scheduleChangPage({ pageSize, currentPage }) {
      this.schedulePage = currentPage;
      this.scheduleLimit = pageSize;
      this.fetchSchedule(this.orderId);
    },
    async fetchContact(id) {
      const customer = (await this.$http.get(`customers/${id}`)).data;
      const field = this.option.column.find((v) => v.prop === "contact");
      const options =
        customer.contacts.length > 0 ? customer.contacts : [{ name: "" }];
      field.dicData = options;
      this.fetch();
    },
    async detach(index) {
      try {
        await this.$confirm("是否确认删除？");
      } catch (e) {
        return;
      }
      this.$clear(this.formRow);
      this.formRow.history.reverse().splice(index, 1);
      await this.$http.put(`orders/${this.formRow._id}`, this.formRow);
      this.$message.success("删除成功");
      this.fetch();
    },
    async onSubmit() {
      this.$clear(this.formRow);
      this.record.submitedAt = new Date();
      this.formRow.history.push(this.record);
      await this.$http.put(`orders/${this.formRow._id}`, this.formRow);
      this.$message.success("提交成功");
      this.record = {};
      this.fetch();
    },
    followUp(scope) {
      this.formRow = scope.row;
      this.dialogVisible = true;
    },
    payBack(scope) {
      this.formRow = scope.row;
      this.dialogPayBack = true;
    },
    schedule(scope) {
      this.orderId = scope.row._id;
      this.fetchSchedule(this.orderId);
      this.dialogSchedule = true;
    },
    handleClose(done) {
      this.fetch();
      done();
    },
    async search(where, done) {
      done();
      if (where.name) {
        where.name = { $regex: where.name };
      }
      this.query.where = where;
      this.fetch();
    },
    async fetch() {
      const res = await this.$http.get("orders", {
        params: {
          query: this.query,
        },
      });
      this.page.total = res.data.total;
      this.data = res.data.data;
    },

    async fetchSchedule(id) {
      const res = await this.$http.get("schedules", {
        params: {
          query: {
            page: this.schedulePage,
            limit: this.scheduleLimit,
            where: { order: id },
          },
        },
      });
      this.schedules = res.data.data;
      this.page.total = res.data.total;
    },

    async fetchType() {
      const res = await this.$http.get("dictionaries/type/contact.way");
      this.options = res.data;
    },
    async removePayBack(row) {
      this.$clear(this.formRow);
      try {
        await this.$confirm("是否确认删除？");
      } catch (e) {
        return;
      }
      this.formRow.payBack.splice(row.$index, 1);
      await this.$http.put(`orders/${this.formRow._id}`, this.formRow);
      this.$message.success("删除成功");
    },
    async update(row, index, done) {
      await this.$http.put(`orders/${row._id}`, row);
      this.$message.success("更新成功");
      this.fetch();
      done();
    },
    async create(row, done) {
      await this.$http.post("orders", row);
      this.$message.success("创建成功");
      this.fetch();
      done();
    },
    async remove(row) {
      try {
        await this.$confirm("是否确认删除？");
      } catch (e) {
        return;
      }
      await this.$http.delete(`orders/${row._id}`);
      this.$message.success("删除成功");
      this.fetch();
    },
    async payBackUpdate(row, index, done) {
      this.$clear(this.formRow);
      this.formRow.payBack[index] = row;
      let res = await this.$http.put(
        `orders/${this.formRow._id}`,
        this.formRow
      );
      this.formRow = res.data;
      this.$message.success("更新成功");
      this.fetch();
      done();
    },
    async payBackCreate(row, done) {
      this.$clear(this.formRow);
      this.formRow.payBack.push(row);
      await this.$http.put(`orders/${this.formRow._id}`, this.formRow);
      this.$message.success("提交成功");
      this.fetch();
      done();
    },
  },
  created() {
    this.fetch();
    this.fetchType();
  },
};
</script>

<style>

.createdAt {
  color: #555;
}

.payBack .el-table__body {
  max-height: 50vh;
  overflow: scroll;
}

.payBack .el-table::-webkit-scrollbar {
  width: 0 !important;
}
</style>