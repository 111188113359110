<template>
  <div class="w-100 d-flex teacher-board">
    <!-- <div class="item" v-for="item in list" :key="item.key">
      <div class="content d-flex align-items-center">
        <h3 class="count">{{ item.count }}</h3>
        <span class="unit-text">{{ item.unit }}</span>
      </div>
      <div class="name">{{ item.name }}</div>
    </div> -->
    今年完课: {{ info.done_course }}天 
    待实施：{{ info.wait_course }}天
     预约： {{ info.app_course }} 天
  </div>
</template>

<script>
export default {
  data() {
    return {
      info: {},
      list: [
        {
          key: "app_course",
          name: "预讲课程",
          count: 2,
          unit: "天",
        },
        {
          key: "wait_course",
          name: "待讲课程",
          count: 2,
          unit: "天",
        },
        // {
        //   key: "wait_confirm",
        //   name: "待提课件",
        //   count: 2,
        //   unit: "个",
        // },
        {
          key: "done_course",
          name: "今年完课",
          count: 2,
          unit: "天",
        },
        // {
        //   key: "wait_settle",
        //   name: "今年待结",
        //   count: 2,
        //   unit: "万元",
        // },
        // {
        //   key: "done_settle",
        //   name: "今年已结",
        //   count: 2,
        //   unit: "万元",
        // },
      ],
    };
  },
  props: {
    user_id: String,
  },

  watch: {
    user_id(){
      this.fetchData()
    }
  },

  methods: {
    async fetchData() {
      console.log(this.user_id,"userid")
      if(!this.user_id.trim()){
        return false;
      }
      const { data } = await this.$http.get(
        "teachers/board_data/" + this.user_id
      );

      this.info = data;

      Object.keys(data).forEach((key) => {
        const index = this.list.findIndex((v) => v.key == key);
        if (index > -1) {
          this.$set(this.list, index, {
            ...this.list[index],
            count: data[key],
          });
        }
      });
      console.log(data);
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<style>
@media only screen and (max-width: 768px) {
  .teacher-board .item .content .count {
    font-size: 15px;
  }

  .teacher-board .item .content .unit-text {
    font-size: 12px;
  }

  .teacher-board .name {
    font-size: 12px;
    /* font-weight: bold; */
  }
}
.teacher-board {
  /* width: 100%;
  justify-content: space-around;
  background-color: #fff;
  box-shadow: 0 -1px 6px 0 rgba(0, 0, 0, 0.04),
    0 10px 20px 0 rgba(0, 0, 0, 0.04);
  border-radius: 0.6rem;
  margin: 0.6rem 0rem; */
  font-size:14px;
}

.teacher-board .item {
  width: 18%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0rem;
}

.teacher-board .item .content {
  display: flex;
  align-items: flex-end;
}

.teacher-board .item .content .unit-text {
  font-size: 14px;
}

.teacher-board .name {
  margin-top: 0.8rem;
  font-size: 14px;
  /* font-weight: bold; */
}
.teacher-board .item .content .count {
  margin: 0 0.2rem;
  color: #409eff;
  font-size: 26px;
}
</style>
