import Vue from "vue";
import Vuex from "vuex";
import http from "../http";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: JSON.parse(localStorage.getItem("user") || "{}"),
    token: "",
    cities: [],
  },
  getters: {
    // 是否为工作人员(非老师)
    isStaff(state) {
      return [0, 1, 4, 5].includes(state.user?.role);
    },
    // 是否为兼职(非老师)
    isPartTime(state,getters){
      return [7].includes(state.user?.role);
    },
    // 是否为老师
    isTeacher(state, getters) {
      return [2, 3].includes(state.user?.role);
    },

    isCustomer(state,getters){
      return [9].includes(state.user?.role);
    },
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload;
    },
    setToken(state, payload) {
      state.token = payload;
    },
  },
  actions: {
    async fetchUser({ commit, state }) {
      let token = localStorage.getItem("token");
      let user = JSON.parse(localStorage.getItem("user") || "{}");
      state.token = token;
      state.user = user;
      const { data } = await Vue.prototype.$axios.get("auth/profile");
      state.user = data;
    },
    async fetchCity({ state }) {
      state.cities = (await http.get("getCity")).data;
    },
  },
  modules: {},
});
