<template>
  <div>
    <div class="add mb-3 ml-1">
      <el-button @click="scheduleClick" type="primary" size="small"
        ><i class="el-icon-plus pr-2"></i>新增档期报备</el-button
      >
      <el-radio-group v-model="is_sole" class="mt-2 mx-2 sole_group" @change="changeSole">
      <el-radio-button v-for="item in soleData" :key="item.value" :value="item.value" :label="item.value">
       {{ item.label }}
      </el-radio-button>
    </el-radio-group>
    </div>
    <avue-crud
      :data="data"
      :option="option"
      @row-del="remove"
      :page.sync="page"
      @on-load="changPage"
      @search-change="search"
    >
      <template slot-scope="scope" slot="teacher">
        <div>{{ _.get(scope.row, "teacher.name") }}</div>
      </template>

      <template slot-scope="scope" slot="is_sole">
        <div>{{ scope.row.is_sole===true ? "是" : "否" }}</div>
      </template>
      <template slot-scope="scope" slot="sole_time">
        <div>{{ scope.row.sole_time | formatDateY }}</div>
      </template>
      <template slot="customer" slot-scope="scope">
        <el-tooltip
          effect="dark"
          :content="_.get(scope.row, 'customer.name')"
          placement="top-start"
          >z
          <div class="text-truncate cursor-pointer">
            {{ _.get(scope.row, "customer.name") }}
          </div>
        </el-tooltip>
      </template>

      <template slot="topic" slot-scope="scope">
        <el-tooltip
          effect="dark"
          :content="_.get(scope.row, 'topic')"
          placement="top-start"
        >
          <div class="text-truncate cursor-pointer">
            {{ _.get(scope.row, "topic") }}
          </div>
        </el-tooltip>
      </template>

      <template slot-scope="scope" slot="valid_period">
        <span v-for="(item, index) in scope.row.valid_period" :key="item._id">
          <!-- dayjs(item).format("YYYY-MM-DD") -->
          {{ validPreiod(item, index) }}
        </span>
      </template>
      <template slot-scope="scope" slot="user">
        <div>{{ _.get(scope.row, "user.realname") }}</div>
      </template>
      <template slot-scope="scope" slot="reportingStatu">
        <el-tag v-if="statu(scope.row) == '正常'" type="success">{{
          statu(scope.row)
        }}</el-tag>
        <el-tag v-else type="info">{{ statu(scope.row) }}</el-tag>
      </template>
      <template slot-scope="scope" slot="menu">
        <el-button
          @click="update(scope.row)"
          icon="el-icon-edit"
          :size="scope.size"
          :type="scope.type"
          >编辑</el-button
        >
      </template>
    </avue-crud>

    <el-dialog
      class="dialog"
      :close-on-click-modal="false"
      :title="optionTitle"
      :visible.sync="dialogVisible"
      :before-close="beforeClose"
      ref="dialog"
      width="76%"
    >
      <avue-form
        v-if="dialogVisible"
        ref="form"
        :option="option"
        v-model="obj"
        @submit="submit"
      >
      </avue-form>
    </el-dialog>
  </div>
</template>

<script>
import * as dayjs from "dayjs";
import { formatDate } from "../../filters";
export default {
  data() {
    return {
      optionTitle: "",
      isAdd: false,
      dialogVisible: false,
      filter_where: {
        teacher: "",
      },
      data: [],
      obj: {
        status: 11,
        way: 1,
      },
      query: {
        sort: { createdAt: -1 },
        populate: ["customer", "user", "teacher"],
        limit: 10,
        where: {
          status: 11,
        },
      },
      page: {
        total: 0,
      },
      status: [],
      is_sole:null,
      soleData:[
        {
          label: "日常报备",
          value: 1,
        },
        {
          label: "独家报备",
          value: 2,
        },
      ],
    };
  },
  computed: {
    option() {
      const state = this.obj.status;
      const way = this.obj.way;
      return {
        times: null,
        searchName: null,
        size: "mini",
        border: true,
        index: true,
        indexLabel: "序号",
        labelWidth: 100,
        menuWidth: 160,
        align: "center",
        searchMenuSpan: 4,
        emptyBtn: false,
        dialogClickModal: false,
        addBtn: false,
        header: false,
        editBtn: false,
        translate: false,
        column: [
          {
            label: "档期状态",
            prop: "status",
            type: "radio",
            border: true,
            rules: [
              {
                required: true,
                message: "请选择档期状态",
                trigger: "blur",
              },
            ],
            dicData: this.status,
            span: 24,
            hide: true,
            showColumn: false,
          },
          {
            label: "报备讲师",
            prop: "teacher",
            filterable: true,
            search: true,
            props: {
              label: "name",
              value: "_id",
            },
            span: 8,
            type: "select",
            slot: true,
            rules: [
              {
                required: true,
                message: "请选择讲师",
                trigger: "blur",
              },
            ],
            dicUrl: `teachers/option`,
            searchFilterable: true,
            dicQuery: {
              query: {
                limit: 0,
              },
            },
          },

          {
            label: "客户名称",
            filterable: true,
            prop: "customer",
            slot: true,
            props: {
              label: "name",
              value: "_id",
            },
            span: 8,
            rules: [
              {
                required: true,
                message: "请选择客户名称",
                trigger: "blur",
              },
            ],
            type: "select",
            dicUrl: `customers/option`,
            dicQuery: {
              query: {
                limit: 0,
              },
            },
          },
          {
            label: "联系人",
            filterable: true,
            prop: "contact",
            type: "select",
            span: 8,
            props: {
              label: "name",
              value: "name",
              // res: 'contacts',
              // children: 'contacts'
            },
            dicData: [],
            showColumn: false,
            display: state < 10,
            hide: true,
            showColumn: false,
          },
          {
            label: "授课方式",
            prop: "way",
            type: "radio",
            span: 8,
            rules: [
              {
                required: true,
                message: "请选择授课方式",
                trigger: "blur",
              },
            ],
            display: state < 10,
            dicData: [
              {
                label: "线上",
                value: 0,
              },
              {
                label: "线下",
                value: 1,
              },
            ],
            hide: true,
            showColumn: false,
          },
          {
            label: "上课日期",
            prop: "times",
            type: "dates",
            format: "yyyy-MM-dd",
            formatter: (row, value) => value && value.join("-"),
            rules: [
              {
                required: true,
                message: "请选择上课时间",
                trigger: "blur",
              },
            ],
            display: state < 10,
            span: 16,
            hide: true,
            showColumn: false,
          },
          {
            label: "选择晚上有课的日期",
            prop: "nights",
            type: "select",
            multiple: true,
            props: {
              label: "label",
              value: "value",
            },
            dicData: [],
            format: "yyyy-MM-dd",
            // valueFormat: "yyyy-MM-dd",
            display: state < 10,
            // slot: true,
            // width: "106px",
            span: 8,
            hide: true,
            showColumn: false,
          },

          {
            label: "时长(小时)",
            prop: "hour",
            type: "number",
            span: 8,
            display: way == 0 && state < 10,
            hide: true,
            showColumn: false,
          },
          {
            label: "时长(天)",
            prop: "fate",
            type: "number",
            span: 8,
            display: way == 1 && state < 10,
            hide: true,
            showColumn: false,
          },
          {
            label: "课程名称",
            prop: "post",
            span: 8,
            showColumn: false,
            display: state < 10,
            hide: true,
            showColumn: false,
          },

          {
            label: "授课类别",
            display: state < 10,
            prop: "category",
            props: {
              label: "label",
              value: "label",
            },
            type: "select",
            dicUrl: `dictionaries/type/schedule.category`,
            span: 8,
            hide: true,
            showColumn: false,
          },
          {
            label: "授课城市",
            slot: true,
            prop: "city",
            filterable: true,
            type: "cascader",
            props: {
              label: "value",
              value: "value",
            },
            span: 8,
            rules: [
              {
                required: true,
                message: "请选择授课城市",
                trigger: "blur",
              },
            ],
            // dicData: this.cities,
            dicUrl: "getCity",
            checkStrictly: true,
            display: state < 10,
            hide: true,
            showColumn: false,
            // lazy: true,
            // async lazyLoad(node, resolve) {
            //   const items = (
            //     await this.$http.get("getCity", {
            //       params: {
            //         parent: node.path.join("-"),
            //       },
            //     })
            //   ).data;
            //   if (node.level >= 2) {
            //     items.map((v) => {
            //       v.leaf = true;
            //     });
            //   }
            //   resolve(items);
            // },
          },
          {
            label: "授课地址",
            display: state < 10,
            prop: "address",
            placeholder: "详细地址",
            span: 8,
            hide: true,
            showColumn: false,
          },

          {
            label: "银行类别",
            prop: "bank_type",
            props: {
              label: "label",
              value: "label",
            },
            type: "select",
            dicUrl: `dictionaries/type/schedule.bank_type`,
            span: 8,
            display: state < 10,
            hide: true,
            showColumn: false,
          },
          {
            label: "终端名称",
            prop: "terminal_name",
            span: 8,
            display: state < 10,
            hide: true,
            showColumn: false,
          },

          {
            label: "终端客户",
            prop: "terminal_customer",
            span: 8,
            display: state == 11,
            search: true,
            rules: [
              {
                required: true,
                message: "请输入终端客户",
                trigger: "blur",
              },
            ],
          },

          {
            label: "需求课题",
            prop: "topic",
            search: true,
            display: state == 11,
            slot: true,
            rules: [
              {
                required: true,
                message: "请输入终端行业",
                trigger: "blur",
              },
            ],
          },
          {
            label: "报备人",
            prop: "user",
            slot: true,
            display: false,
          },
          {
            label: "有效期限",
            prop: "valid_period",
            slot: true,
            type: "daterange",
            startPlaceholder: "选择开始时间",
            endPlaceholder: "选择结束时间",
            // format: "yyyy-MM-dd HH:mm:ss",
            valueFormat: "yyyy-MM-dd HH:mm:ss",
            rules: [
              {
                required: true,
                message: "请选择有效期限",
                trigger: "blur",
              },
            ],
            display: state === 11,
          },
          {
            label: "状态",
            slot: true,
            prop: "reportingStatu",
            span: 8,
            display: false,
          },
          {
            label: "学员岗位",
            prop: "student_job",
            display: state < 10,
            span: 8,
            hide: true,
            showColumn: false,
          },
          {
            label: "讲师课酬",
            prop: "price",
            type: "number",
            span: 8,
            showColumn: false,
            hide: true,
            display: state >= 1 && state < 10,
          },
          {
            label: "备注",
            prop: "info",
            type: "textarea",
            span: 24,
            minRows: 2,
            display: state < 10,
            hide: true,
            showColumn: false,
          },
          {
            label: state < 12 ? "注意事项" : "事项内容",
            prop: "matter",
            type: "textarea",
            span: 24,
            minRows: 2,
            showColumn: false,
            hide: true,
            display: state !== 11 && state < 14,
            // rules: [
            //   {
            //     required: true,
            //     message: "请输入事项内容",
            //     trigger: "blur",
            //   },
            // ],
          },

          // {
          //   label: "备注",
          //   prop: "info",
          //   type: "textarea",
          //   span: 24,
          //   minRows: 2,
          //   showColumn: false,
          //   placeholder: "档期备注",
          // },

          {
            label: "是否独家",
            prop: "is_sole",
            type: "radio",
            span: 8,
            rules: [
              {
                required: true,
                message: "请选择是否独家",
                trigger: "blur",
              },
            ],
            display: state == 11,
            dicData: [
              {
                label: "是",
                value: true,
              },
              {
                label: "否",
                value: false,
              },
            ],
            slot: true,
            showColumn: false,
          },

          {
            label: "独家期限",
            prop: "sole_times",
            type: "daterange",
            multiple: true,
            props: {
              label: "label",
              value: "value",
            },
            dicData: [],
            format: "yyyy-MM-dd",
            // valueFormat: "yyyy-MM-dd",
            display: state == 11,
            // slot: true,
            // width: "106px",
            span: 8,
            showColumn: false,
          },
        ],
      };
    },
  },

  watch: {
    "obj.customer": {
      handler(id) {
        if (id) {
          this.fetchContact(id);
        }
      },
      immediate: true,
    },
    "obj.times": {
      immediate: false,
      handler(value) {
        if (value && value.length > 0) {
          this.fetchFate(value);
        }
      },
    },
    "obj.teacher": {
      handler(id) {
        if (id) {
          this.fetchPay(id);
        } else {
          this.obj.price = "";
        }
      },
      immediate: true,
    },
  },
  methods: {
    changeSole(val){
      if(this.is_sole){
        this.filter_where.is_sole = this.is_sole;
      }
      this.fetch();
    },
    // 返回讲师课酬
    async fetchPay(id) {
      const teacher = (await this.$http.get(`teachers/${id}`)).data;
      this.obj.price = teacher.price;
    },
    fetchFate(v) {
      this.times = [];
      for (const item of v) {
        var selectTime = dayjs(item).format("YYYY-MM-DD");
        this.times.push({
          label: selectTime,
          value: dayjs(item).format("YYYY-MM-DD"),
        });
      }

      const field = this.option.column.find((v) => v.prop === "nights");
      field.dicData = this.times;
      if (v.length) {
        this.obj.fate = v.length;
      } else {
        this.obj.fate = 0;
      }
    },
    statu(date) {
      const newDate = dayjs().subtract(1, "day").toDate();
      const valid_period = date.valid_period && date.valid_period[1];

      if (!valid_period) {
        return "已过期";
      }
      let endTime = dayjs(date.valid_period[1]).toDate();
      var statu = null;
      if (newDate > endTime) {
        statu = "已过期";
      } else {
        statu = "正常";
      }
      return statu;
    },
    async fetchContact(id) {
      const customer = (await this.$http.get(`customers/${id}`)).data;
      const field = this.option.column.find((v) => v.prop === "contact");
      const options =
        customer.contacts && customer.contacts.length > 0
          ? customer.contacts
          : [{ name: "" }];
      field.dicData = options;
      // this.contacts = options;
      // console.log(this.contacts)
      // this.fetch();
    },
    validPreiod(item, index) {
      let time = dayjs(item).format("YYYY-MM-DD");
      if (index == 0) {
        time += "至";
      }
      return time;
    },
    async search(where, done) {
      done();
      if (where.topic) {
        this.searchName = { $regex: where.topic };
      }

      this.filter_where.teacher = where.teacher || "";
    
      this.filter_where.terminal_customer = where.terminal_customer
        ? { $regex: where.terminal_customer }
        : "";
      this.fetch();
    },
    beforeClose() {
      this.dialogVisible = false;
      this.obj = {};
    },
    async submit(obj, done) {
      this.$clear(obj, true);
      if (!this.isAdd) {
        await this.$http.put(`schedules/${obj._id}`, obj);
        this.$message.success("更新成功");
      } else {
        await this.$http.post("schedules", obj);
        this.$message.success("创建成功");
      }
      this.obj = {};
      this.fetch();
      done();
      this.dialogVisible = false;
    },
    scheduleClick() {
      this.isAdd = true;
      this.optionTitle = "新增档期报备";
      this.dialogVisible = true;
      this.obj = {
        status: 11,
      };
      this.status = [
        {
          label: "报备",
          value: 11,
        },
      ];
    },
    async update(item) {
      this.isAdd = false;
      const res = await this.$http.get(`schedules/${item._id}`);
      this.obj = res.data;
      this.optionTitle = "编辑档期报备";
      this.obj.way = 1;
      this.dialogVisible = true;
      this.status = [
        {
          label: "报备",
          value: 11,
        },
        {
          label: "预定",
          value: 1,
        },
      ];
      this.fetch();
    },
    async remove(row) {
      try {
        await this.$confirm("是否确认删除？");
      } catch (e) {
        return;
      }
      await this.$http.delete(`schedules/${row._id}`);
      this.$message.success("删除成功");
      this.fetch();
    },
    async changPage({ pageSize, currentPage }) {
      this.query.page = currentPage;
      this.query.limit = pageSize;
      this.fetch();
    },
    async fetch() {
      let where = {};

      console.log(
        {
          where,
        },
        "fetch"
      );
      // if (this.user.role !== 0) {
      //   where.user = this.user._id;
      // }
      if (this.searchName) {
        where.topic = this.searchName;
      }
      if (this.filter_where.teacher) {
        where.search_teacher = this.filter_where.teacher;
      }
      if (this.filter_where.terminal_customer) {
        where.terminal_customer = this.filter_where.terminal_customer;
      }
      if(this.filter_where.is_sole){
        where.is_sole = this.filter_where.is_sole;
      }
      where.status = 11;

      const res = await this.$http.get("schedules/list2", {
        params: {
          query: {
            ...this.query,
            where,
          },
        },
      });
      this.page.total = res.data.total;
      this.data = res.data.data;
    },
  },
  created() {
    this.fetch();
  },
};
</script>

<style>
.sole_group label .el-radio-button__inner {
  margin-bottom: 0;
  height: 33px;
  padding: 0 15px;
  line-height: 33px;
}
</style>