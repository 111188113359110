<template>
  <div class="blacklist">
    <avue-crud
      v-model="form"
      :data="data"
      :option="option"
      :page.sync="page"
      @row-update="update"
      @row-del="remove"
      @on-load="changPage"
      @search-change="search"
      @row-save="create"
    >
      <template slot="user" slot-scope="scope">
        <div v-if="!Array.isArray(scope.row.user)">
          {{ scope.row.user && scope.row.user.realname }}
        </div>
        <span v-else>
          {{ (scope.row.user || []).map((v) => v.realname).join(",") }}
        </span>
      </template>

      <template slot="name" slot-scope="scope">
        <el-tooltip
          effect="dark"
          :content="scope.row.name"
          placement="top-start"
        >
          <!-- <div class="text-primary">{{ scope.row.name }}</div> -->
          <div
            class="text-truncate text-primary cursor-pointer"
            style="max-width: 14vw"
          >
            {{ scope.row.name }}
          </div>
        </el-tooltip>
      </template>

      <template slot="contacts" slot-scope="scope">
        <el-tooltip
          effect="dark"
          :content="(scope.row.contacts || []).map((v) => v.name).join('，')"
          placement="top-start"
        >
          <div class="cursor-pointer text-truncate" style="max-width: 12vw">
            <span
              class="pr-1 contact"
              v-for="(row, index) in scope.row.contacts"
              :key="index"
            >
              {{ row.name }}
            </span>
          </div>
        </el-tooltip>
      </template>

      <template slot="mobile" slot-scope="scope">
        <el-tooltip
          effect="dark"
          :content="(scope.row.contacts || []).map((v) => v.mobile).join('，')"
          placement="top-start"
        >
          <div
            style="line-height: 22px"
            v-for="(row, index) in scope.row.contacts"
            :key="index"
          >
            {{ row.mobile }}
          </div>
          <div class="cursor-pointer text-truncate" style="max-width: 12vw">
            <span
              class="pr-1 contact"
              v-for="(row, index) in scope.row.contacts"
              :key="index"
            >
              {{ row.mobile }}
            </span>
          </div>
        </el-tooltip>
      </template>

      <template slot-scope="scope" slot="menu">
        <el-button
          @click="followUp(scope)"
          icon="el-icon-check"
          :size="scope.size"
          :type="scope.type"
          >跟进记录</el-button
        >
      </template>
    </avue-crud>
    <el-dialog
      title="跟进记录"
      :visible.sync="dialogVisible"
      width="70%"
      :before-close="handleClose"
    >
      <el-card class="mb-4"  v-if="role!==5&&role!==1">
        <el-form :inline="true">
          <el-form-item label="跟进日期">
            <el-date-picker
              v-model="record.time"
              type="datetime"
              placeholder="选择日期时间"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="沟通方式">
            <el-select v-model="record.contact_way" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.label"
                :value="item.label"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="联系人">
            <el-select filterable v-model="record.contact" placeholder="请选择">
              <el-option
                v-for="item in formRow.contacts"
                :key="item.id"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="负责人">
            <el-select filterable v-model="record.user" placeholder="请选择">
              <el-option
                v-for="item in superintendents"
                :key="item.id"
                :label="item.realname"
                :value="item._id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit()">提交</el-button>
          </el-form-item>
          <el-input
            v-model="record.content"
            type="textarea"
            placeholder="请输入沟通内容"
          ></el-input>
        </el-form>
        <!-- <avue-form v-model="record" :option="records"> </avue-form> -->
      </el-card>
      <div class="block">
        <el-timeline
          v-if="Array.isArray(formRow.history) && formRow.history.length > 0"
        >
          <!-- <div v-for="v in data" :key="v._id"> -->
          <el-timeline-item
            v-for="(item, index) in history"
            :key="item._id"
            :timestamp="item.submitedAt | formatDate"
            placement="top"
          >
            <el-card>
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <div class="history-info">
                    <div class="pr-2" v-if="item.user">
                      负责人：
                      <span> {{ _.get(item.user, "realname") }}</span>
                    </div>

                    <div class="pr-2" v-else>
                      负责人：
                      <span> {{ _.get(formRow, "user[0].realname") }}</span>
                    </div>
                    跟进日期：<span class="createdAt">{{
                      item.time | formatDateY
                    }}</span>
                    <div class="pdl-3">
                      <span>沟通方式：</span>
                      <span class="text-danger font-weight-bold">{{
                        item.contact_way
                      }}</span>
                    </div>
                    <div class="pdl-3">
                      <span>联系人：</span> <span>{{ item.contact }}</span>
                    </div>
                  </div>
                  <div class="pt-3">{{ item.content }}</div>
                </div>
                <div class="pr-4"  v-if="role!==5&&role!==1">
                  <el-button
                    @click="detach(index)"
                    type="danger"
                    size="mini"
                    icon="el-icon-delete"
                    circle
                  ></el-button>
                </div>
              </div>
            </el-card>
          </el-timeline-item>
        </el-timeline>
        <div v-else class="text-center pb-2">暂无记录</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
var mobile = (rule, value, callback) => {
  const reg = /^1[2|3|4|5|6|7|8|9][0-9]\d{8}$/;
  if (reg.test(value)) {
    callback();
  } else {
    callback(new Error("请输入正确的手机号"));
  }
};
export default {
  data() {
    return {
      superintendents: [],
      customerId: undefined,
      query: {
        limit: 10,
        sort: { grade: 1, name: 1 },
      },
      searchName: null,
      page: {
        total: 0,
      },
      form: {
        isBlacklist: 1,
      },
      formRow: {},
      record: {
        contact: "",
        contact_way: "",
        time: "",
        content: "",
        submitedAt: new Date(),
      },
      options: [],
      dialogVisible: false,
      data: [],

      // records: {
      //   translate: false,
      //   column: [
      //     {
      //       label: "跟进时间",
      //       prop: "time",
      //       type: "datetime",
      //       span: 8,
      //       format: "yyyy-MM-dd hh:mm:ss",
      //       mock: {
      //         type: "datetime",
      //         format: "yyyy-MM-dd hh:mm:ss",
      //         now: true,
      //       },
      //     },
      //     {
      //       label: "沟通方式",
      //       prop: "type",
      //       props: {
      //         label: "label",
      //         value: "label",
      //       },
      //       type: "select",
      //       dicUrl: `dictionaries/type/contact.way`,
      //       showColumn: false,
      //     },

      //     {
      //       label: "联系人",
      //       prop: "contact",
      //       type: "select",
      //       props: {
      //         label: "name",
      //         value: "name",
      //       },
      //       // dicData: this.contacts,
      //       showColumn: false,
      //     },
      //   ],
      // },
      // importModel: {
      //   file: null,
      // },
    };
  },

  computed: {
    history() {
      const rows = this.formRow.history;
      rows.sort((a, b) => {
        return Date.parse(b.time) - Date.parse(a.time);
      });
      return rows;
    },
    role(){
      const user = this.$store?.state?.user || {};
      return user.role || 0
    },
    option() {
      const user = this.$store?.state?.user || {};
      const isHide = [1, 5].includes(user.role);
      return {
        border: true,
        tree: true,
        expandLevel: 2,
        headerAlign: "center",
        dialogWidth: "90%",
        menuWidth: 220,
        searchMenuSpan: 5,
        align: "center",
        index: true,
        indexLabel: "序号",
        dialogClickModal: false,
        translate: false,
        addBtn: isHide ? false : true,
        editBtn: isHide ? false : true,
        delBtn: isHide ? false : true,
        column: [
          {
            label: "客户名称",
            prop: "name",
            search: true,
            slot: true,
            rules: [
              {
                required: true,
                message: "请输入客户名称",
                trigger: "blur",
              },
            ],
          },

          {
            label: "联系人",
            children: [
              {
                label: "姓名",
                display: false,
                prop: "contacts",
                slot: true,
                formatter: (row) => {
                  return this._.map(row.contacts, "name").join("<br>");
                  return this._.get(row, "contacts", [])
                    .map((v) => v.name)
                    .join("<br> ");
                  return row.contacts[0] && row.contacts[0].name;
                },
              },
              {
                label: "手机号",
                display: false,
                slot: true,
                prop: "mobile",
                formatter: (row) => {
                  return this._.get(row, "contacts", [])
                    .map((v) => v.mobile)
                    .join("<br/>");
                },
              },
            ],
          },

          {
            label: "负责人",
            prop: "user",
            span: 8,
            slot: true,
            display: false,
          },

          // {
          //   label: "客户类型",
          //   prop: "type",
          //   type: "radio",
          //   props: {
          //     label: "label",
          //     value: "label",
          //   },
          //   dicUrl: `dictionaries/type/customer.type`,
          //   hide: true,
          //   showColumn: false,
          // },
          {
            label: "客户状态",
            prop: "state",
            type: "select",
            props: {
              label: "label",
              value: "label",
            },
            dicUrl: `dictionaries/type/customer.state`,
            hide: true,
            showColumn: false,
          },

          {
            label: "客户来源",
            props: {
              label: "label",
              value: "label",
            },
            prop: "source",
            type: "select",
            dicUrl: `dictionaries/type/customer.source`,
            hide: true,
            showColumn: false,
          },
          {
            label: "客户等级",
            prop: "grade",
            props: {
              label: "label",
              value: "label",
            },
            dicUrl: `dictionaries/type/customer.grade`,
            type: "radio",
            searchFilterable: true,
            // showColumn: false,
          },
          // {
          //   label: "客户阶段",
          //   prop: "stage",
          //   type: "radio",
          //   props: {
          //     label: "label",
          //     value: "label",
          //   },
          //   dicUrl: `dictionaries/type/customer.stage`,
          //   hide: true,
          //   showColumn: false,
          // },
          // {
          //   label: "业务评价",
          //   prop: "comment",
          //   display: false,
          // },
          {
            label: "距上次联系天数",
            prop: "days_not_contacted",
            display: false,
          },
          {
            label: "距上次下单天数",
            prop: "days_without_order",
            display: false,
          },
          {
            label: "累计合作(金额)",
            prop: "total",
            display: false,
          },
          {
            label: "所属城市",
            prop: "region",
            filterable: true,
            type: "cascader",
            props: {
              label: "value",
              value: "value",
            },
            formatter: (row, value) => value && value.join("-"),
            dicUrl: "getCity",
            checkStrictly: true,
            hide: true,
            showColumn: false,
          },
          {
            label: "详细地址",
            prop: "address",
            hide: true,
            showColumn: false,
          },
          {
            label: "年度合同",
            multiple: true,
            prop: "contractImg",
            type: "upload",
            dataType: "array",
            listType: "picture-card",
            action: "upload",
            span: 24,
            hide: true,
            showColumn: false,
          },
          {
            label: "年度合同附件",
            prop: "file",
            type: "upload",
            loadText: "附件上传中，请稍等",
            span: 18,
            hide: true,
            showColumn: false,
            action: "upload",
          },
          {
            label: "备注",
            prop: "info",
            type: "textarea",
            span: 24,
            hide: true,
            showColumn: false,
          },
          // {
          //   label: "导入联系人",
          //   type: "upload",
          //   loadText: "附件上传中，请稍等",
          //   span: 24,
          //   propsHttp: {
          //     res: "res",
          //   },
          //   // tip: "只能上传ex",
          //   action: "customers/import",
          // },
          // {
          //   label: "导入联系人",
          //   type: "import-button",
          // },
          {
            label: "添加联系人",
            prop: "contacts",
            hide: true,
            type: "dynamic",
            span: 24,
            rules: [
              {
                required: true,
                message: "请添加联系人",
                trigger: "blur",
              },
            ],
            children: {
              column: [
                {
                  label: "姓名",
                  prop: "name",
                  rules: [
                    {
                      required: true,
                      message: "请填写姓名",
                      trigger: "blur",
                    },
                  ],
                },
                {
                  label: "手机号",
                  prop: "mobile",
                  rules: [
                    {
                      required: true,
                      message: "请填写手机号",
                      trigger: "blur",
                    },
                  ],
                  rules: [
                    { validator: mobile, trigger: "blur", required: true },
                  ],
                },
                {
                  label: "邮箱",
                  prop: "email",
                },
                {
                  label: "职务",
                  prop: "job",
                },
                {
                  label: "备注",
                  prop: "info",
                },
              ],
            },
          },
          {
            label: "开票管理",
            prop: "invoices",
            hide: true,
            type: "dynamic",
            span: 24,
            children: {
              column: [
                {
                  label: "单位",
                  prop: "company",
                },
                {
                  label: "税号",
                  prop: "duty_paragraph",
                },
                {
                  label: "城市",
                  prop: "city",
                  type: "cascader",
                  props: {
                    label: "value",
                    value: "value",
                  },
                  formatter: (row, value) => value && value.join("-"),
                  dicUrl: "getCity",
                },
                {
                  label: "详细地址",
                  prop: "address",
                },
                {
                  label: "手机号",
                  prop: "mobile",
                },
                {
                  label: "开户行",
                  prop: "bank",
                },
                {
                  label: "开户行账号",
                  prop: "account",
                },
              ],
            },
          },
          {
            label: "加入黑名单",
            prop: "isBlacklist",
            span: 24,
            type: "switch",
            props: {
              label: "label",
              value: "value",
            },
            dicData: [
              {
                label: "否",
                value: 0,
              },
              {
                label: "是",
                value: 1,
              },
            ],
            // display: false,
            hide: true,
            showColumn: false,
          },
          {
            label: "创建时间",
            prop: "createdAt",
            type: "date",
            display: true,
            disabled: true,
            format: "yyyy-MM-dd",
            valueFormat: "yyyy-MM-dd",
          },
        ],
      };
    },
  },
  methods: {
    async create(row, done) {
      row.isBlacklist = 1;
      await this.$http.post("customers", row);
      this.$message.success("创建成功");
      this.fetch();
      done();
    },
    async remove(row) {
      try {
        await this.$confirm("是否确认删除？");
      } catch (e) {
        return;
      }
      await this.$http.delete(`customers/${row._id}`);
      this.$message.success("删除成功");
      this.fetch();
    },
    async update(row, index, done) {
      await this.$http.put(`customers/${row._id}`, row);
      this.$message.success("更新成功");
      this.fetch();
      done();
    },
    // uploadAfter(res, done, loading) {
    //   this.customerId = res._id;
    //   let contacts = this.findObject(this.option.column, "contacts");

    //   // let column= contacts.children.column
    //   // column.forEach(v=>{
    //   //   column.push(v)
    //   // })
    //   this.form.contacts = res.contacts;
    //   done();
    //   // console.log(contacts);
    // },
    async search(where, done) {
      done();
      if (where.name) {
        this.searchName = { $regex: where.name };
      }
      this.fetch();
    },
    async changPage({ pageSize, currentPage }) {
      this.query.page = currentPage;
      this.query.limit = pageSize;
      this.fetch();
    },
    async detach(index) {
      try {
        await this.$confirm("是否确认删除？");
      } catch (e) {
        return;
      }
      this.$clear(this.formRow);
      this.formRow.history.reverse().splice(index, 1);
      this.formRow.history.reverse();
      await this.$http.put(`customers/${this.formRow._id}`, this.formRow);
      this.$message.success("删除成功");
      this.fetch();
    },
    async onSubmit() {
      this.$clear(this.formRow, true);
      this.record.submitedAt = new Date();
      if (!this.record.user) {
        this.record.user = this.user._id;
      }
      this.formRow.history.push(this.record);
      await this.$http.put(`customers/${this.formRow._id}`, this.formRow);
      const row = await this.$http.get(`customers/${this.formRow._id}`);
      this.formRow = row.data;
      this.$message.success("提交成功");
      this.record = {};
      this.fetch();
    },
    followUp(scope) {
      this.formRow = scope.row;
      this.dialogVisible = true;
    },
    handleClose(done) {
      done();
    },
    async fetchUser() {
      const ret = await this.$http.get(`users`, {
        params: {
          query: {
            limit: 0,
            where: {
              role: { $in: [1, 4, 5, 0] },
            },
          },
        },
      });
      const superintendent = ret.data.data;
      this.superintendents = superintendent;
    },
    async fetch() {
      let where = {};
      where.isBlacklist = 1;
      if (this.user.role !== 0) {
        where.user = { $in: [this.user._id, null] };
      }
      // 取消限制
      // if (this.user.role == 5) {
      //   where.user = { $in: [...this.user.userInfo, this.user._id, null] };
      // }

      if (this.user.role == 5 || this.user.role == 1) {
        delete where.user;
      }
      if (this.searchName) {
        where.name = this.searchName;
      }
      const res = await this.$http.get("customers", {
        params: {
          query: {
            ...this.query,
            where,
          },
        },
      });
      this.page.total = res.data.total;
      this.data = res.data.data;
    },
    async fetchType() {
      const res = await this.$http.get("dictionaries/type/contact.way");
      this.options = res.data;
    },
  },
  created() {
    this.fetch();
    this.fetchUser();
    this.fetchType();
    // this.$store.dispatch('fetchUser')
  },
};
</script>

<style>
.createdAt {
  color: #555;
}

.block {
  height: 50vh;
  overflow: scroll;
}
.block::-webkit-scrollbar {
  width: 0 !important;
}
.blacklist .el-input--suffix .el-input__inner {
  padding-right: 24px !important;
}

.blacklist .el-dialog__body .el-form-item__label {
  width: auto !important;
}

.blacklist .el-dialog__body .el-form-item__content {
  margin-left: auto !important;
}
</style>
