"use strict";
import Vue from "vue";
import router from "../router/index";
import axios from "axios";
import { Message } from "element-ui";
import ShowMessage from "./singleMessage"

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
  baseURL: process.env.VUE_APP_API_URL,
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

const singleMessage=new ShowMessage();

export const _axios = axios.create(config);

_axios.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    let url = config.url.search(/cli.avuejs.com/i);
    if (url != -1) {
      return config;
    }
    const token = localStorage.token || "";
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    } else {
      router.replace("/login");
    }
    return config;
  },
  (err) => {
    // Do something with request error
    return Promise.reject(err.request);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    // Do something with response data

    return response;
  },
  function(err) {
    const { data, status } = err.response;
    if (status === 401) {
      singleMessage.error('请先登录');
      router.replace('/login')
    }
    if (status === 422 || status=== 400) {
      Message.error(data.message);
    }


    

    // Do something with response error
    return Promise.reject(err);
  }
);

export const  http=_axios

Plugin.install = function(Vue) {
  Vue.axios = _axios;
  window.axios = _axios;
  Vue.prototype.axios = _axios;
  Vue.prototype.$axios = _axios;
  Vue.prototype.$http = _axios;
  // Vue.prototype.$httpajax = _axios;
  // Object.defineProperties(Vue.prototype, {
  //   axios: {
  //     get() {
  //       return _axios;
  //     }
  //   },
  //   $axios: {
  //     get() {
  //       return _axios;
  //     }
  //   },
  // });
};

Vue.use(Plugin);

export default Plugin;
