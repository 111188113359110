var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"invoiceManage"},[_c('el-tabs',{staticClass:"px-2",attrs:{"type":"card"},on:{"tab-click":_vm.handleClick},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_c('el-tab-pane',{attrs:{"label":"待开票","name":"first"}},[_c('div',{staticClass:"mb-3 ml-2"},[_c('el-button',{attrs:{"disabled":!_vm.select_list.length,"type":"primary","size":"small"},on:{"click":_vm.addInvoice}},[_c('i',{staticClass:"el-icon-plus pr-2"}),_vm._v("新增开票")])],1),_c('avue-crud',{ref:"invoice_crud",attrs:{"data":_vm.data,"option":_vm.option,"page":_vm.page,"before-open":_vm.beforeOpen},on:{"row-update":_vm.update,"update:page":function($event){_vm.page=$event},"on-load":_vm.changPage,"search-change":_vm.search,"selection-change":_vm.selectionChange},scopedSlots:_vm._u([{key:"customer_billing_company",fn:function(ref){
var row = ref.row;
return [(_vm.findkp(row))?_c('el-tooltip',{attrs:{"effect":"dark","placement":"top-start"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('div',[_c('span',[_vm._v("客户开票公司：")]),_c('span',[_vm._v(_vm._s(_vm.findkp(row).company))])]),_c('div',{staticClass:"py-2"},[_c('span',[_vm._v("手机号：")]),_c('span',[_vm._v(_vm._s(_vm.findkp(row).mobile))])]),_c('div',[_c('span',[_vm._v("税号：")]),_c('span',[_vm._v(_vm._s(_vm.findkp(row).duty_paragraph))])]),_c('div',{staticClass:"py-2"},[_c('span',[_vm._v("开户行：")]),_c('span',[_vm._v(_vm._s(_vm.findkp(row).bank))])]),_c('div',[_c('span',[_vm._v("开户行账号：")]),_c('span',[_vm._v(_vm._s(_vm.findkp(row).account))])])]),_c('div',{staticClass:"text-truncate cursor-pointer text-primary"},[_c('span',[_vm._v(_vm._s(row.billing_company && row.billing_company.company))])])]):_vm._e()]}},{key:"isBill",fn:function(scope){return [(scope.row.isBill)?_c('el-tag',{attrs:{"type":"success"}},[_vm._v("已开票")]):_c('el-tag',{attrs:{"type":"info"}},[_vm._v("待开票")])]}},{key:"customer",fn:function(scope){return [_c('el-tooltip',{attrs:{"effect":"dark","content":_vm._.get(scope.row, 'customer.name'),"placement":"top-start"}},[_c('div',[_c('div',{staticClass:"text-truncate cursor-pointer"},[_vm._v(" "+_vm._s(_vm._.get(scope.row, "customer.name"))+" ")])])])]}},{key:"billing_company",fn:function(ref){
var row = ref.row;
return [(row.billing_company)?_c('el-tooltip',{attrs:{"effect":"dark","placement":"top-start"}},[_c('div',{staticStyle:{"line-height":"26px"},attrs:{"slot":"content"},slot:"content"},[_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"ws-n"},[_vm._v("开票公司：")]),_vm._v(" "+_vm._s(row.billing_company.company)+" ")]),_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"ws-n"},[_vm._v("城市：")]),_vm._v(" "+_vm._s(row.billing_company.city.join(","))+" ")]),_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"ws-n"},[_vm._v("详细地址：")]),_vm._v(" "+_vm._s(row.billing_company.address)+" ")]),_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"ws-n"},[_vm._v("联系人：")]),_vm._v(" "+_vm._s(row.billing_company.contact)+" ")]),_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"ws-n"},[_vm._v("手机号：")]),_vm._v(" "+_vm._s(row.billing_company.mobile)+" ")]),_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"ws-n"},[_vm._v("税号：")]),_vm._v(" "+_vm._s(row.billing_company.duty_paragraph)+" ")]),_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"ws-n"},[_vm._v("开户行：")]),_vm._v(" "+_vm._s(row.billing_company.bank)+" ")]),_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"ws-n"},[_vm._v("开户行账号：")]),_vm._v(" "+_vm._s(row.billing_company.account)+" ")])]),_c('div',{staticClass:"text-truncate cursor-pointer text-primary"},[_c('span',[_vm._v(_vm._s(row.billing_company.company))])])]):_vm._e()]}}]),model:{value:(_vm.obj),callback:function ($$v) {_vm.obj=$$v},expression:"obj"}})],1),_c('el-tab-pane',{attrs:{"label":"已开票","name":"second"}},[_c('avue-crud',{ref:"invoice_crud",attrs:{"data":_vm.invoice_data,"option":_vm.invoiceOption,"page":_vm.invoice_page,"before-open":_vm.beforeOpen},on:{"update:page":function($event){_vm.invoice_page=$event},"on-load":_vm.changInvoicePage},scopedSlots:_vm._u([{key:"billing_company",fn:function(ref){
var row = ref.row;
return [_c('el-tooltip',{attrs:{"effect":"dark","placement":"top-start"}},[_c('div',{staticStyle:{"line-height":"26px"},attrs:{"slot":"content"},slot:"content"},[_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"ws-n"},[_vm._v("开票公司：")]),_vm._v(" "+_vm._s(_vm.firstSchedule(row).billing_company.company)+" ")]),_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"ws-n"},[_vm._v("城市：")]),_vm._v(" "+_vm._s(_vm.firstSchedule(row).billing_company.city.join(","))+" ")]),_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"ws-n"},[_vm._v("详细地址：")]),_vm._v(" "+_vm._s(_vm.firstSchedule(row).billing_company.address)+" ")]),_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"ws-n"},[_vm._v("联系人：")]),_vm._v(" "+_vm._s(_vm.firstSchedule(row).billing_company.contact)+" ")]),_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"ws-n"},[_vm._v("手机号：")]),_vm._v(" "+_vm._s(_vm.firstSchedule(row).billing_company.mobile)+" ")]),_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"ws-n"},[_vm._v("税号：")]),_vm._v(" "+_vm._s(_vm.firstSchedule(row).billing_company.duty_paragraph)+" ")]),_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"ws-n"},[_vm._v("开户行：")]),_vm._v(" "+_vm._s(_vm.firstSchedule(row).billing_company.bank)+" ")]),_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"ws-n"},[_vm._v("开户行账号：")]),_vm._v(" "+_vm._s(_vm.firstSchedule(row).billing_company.account)+" ")])]),_c('div',{staticClass:"text-truncate text-primary cursor-pointer"},[_vm._v(" "+_vm._s(_vm.firstSchedule(row).billing_company.company)+" ")])])]}},{key:"customer",fn:function(ref){
var row = ref.row;
return [_c('el-tooltip',{attrs:{"effect":"dark","content":_vm._.get(_vm.firstSchedule(row), 'customer.name'),"placement":"top-start"}},[_c('div',[_c('div',{staticClass:"text-truncate cursor-pointer"},[_vm._v(" "+_vm._s(_vm._.get(_vm.firstSchedule(row), "customer.name"))+" ")])])])]}},{key:"customer_billing_company",fn:function(ref){
var row = ref.row;
return [_c('el-tooltip',{attrs:{"effect":"dark","placement":"top-start"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('div',[_c('span',[_vm._v("客户开票公司：")]),_c('span',[_vm._v(_vm._s(_vm.invoice_customer(row).company))])]),_c('div',{staticClass:"py-2"},[_c('span',[_vm._v("手机号：")]),_c('span',[_vm._v(_vm._s(_vm.invoice_customer(row).mobile))])]),_c('div',[_c('span',[_vm._v("税号：")]),_c('span',[_vm._v(_vm._s(_vm.invoice_customer(row).duty_paragraph))])]),_c('div',{staticClass:"py-2"},[_c('span',[_vm._v("开户行：")]),_c('span',[_vm._v(_vm._s(_vm.invoice_customer(row).bank))])]),_c('div',[_c('span',[_vm._v("开户行账号：")]),_c('span',[_vm._v(_vm._s(_vm.invoice_customer(row).account))])])]),_c('div',{staticClass:"text-truncate text-primary cursor-pointer"},[_vm._v(" "+_vm._s(_vm.invoice_customer(row).customer_billing_company)+" ")])])]}},{key:"user",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.user.realname))])]}},{key:"menu",fn:function(ref){
var type = ref.type;
var size = ref.size;
var row = ref.row;
return [_c('el-button',{attrs:{"icon":"el-icon-view","size":size,"type":type},on:{"click":function($event){return _vm.viewItem(row)}}},[_vm._v("查看")]),_c('el-button',{attrs:{"icon":"el-icon-delete","size":size,"type":type},on:{"click":function($event){return _vm.deleteInvoice(row)}}},[_vm._v("删除")])]}}])})],1)],1),_c('el-dialog',{attrs:{"title":_vm.invoiceModel._id ? '查看详情' : '添加开票',"visible":_vm.showInvoice,"width":"80%"},on:{"update:visible":function($event){_vm.showInvoice=$event}}},[_c('avue-form',{attrs:{"option":_vm.invoice_form_options},on:{"submit":_vm.submitInvoice},scopedSlots:_vm._u([{key:"customer_billing_company",fn:function(ref){
var row = ref.row;
return [(_vm.findkp(row))?_c('el-tooltip',{attrs:{"effect":"dark","placement":"top-start"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('div',[_c('span',[_vm._v("客户开票公司：")]),_c('span',[_vm._v(_vm._s(_vm.findkp(row).company))])]),_c('div',{staticClass:"py-2"},[_c('span',[_vm._v("手机号：")]),_c('span',[_vm._v(_vm._s(_vm.findkp(row).mobile))])]),_c('div',[_c('span',[_vm._v("税号：")]),_c('span',[_vm._v(_vm._s(_vm.findkp(row).duty_paragraph))])]),_c('div',{staticClass:"py-2"},[_c('span',[_vm._v("开户行：")]),_c('span',[_vm._v(_vm._s(_vm.findkp(row).bank))])]),_c('div',[_c('span',[_vm._v("开户行账号：")]),_c('span',[_vm._v(_vm._s(_vm.findkp(row).account))])])]),_c('div',{staticClass:"text-truncate cursor-pointer text-primary"},[_c('span',[_vm._v(_vm._s(row.billing_company && row.billing_company.company))])])]):_vm._e()]}},{key:"isBill",fn:function(scope){return [(scope.row.isBill)?_c('el-tag',{attrs:{"type":"info"}},[_vm._v("已开票")]):_c('el-tag',{attrs:{"type":"success"}},[_vm._v("待开票")])]}},{key:"customer",fn:function(scope){return [_c('el-tooltip',{attrs:{"effect":"dark","content":_vm._.get(scope.row, 'customer.name'),"placement":"top-start"}},[_c('div',[_c('div',{staticClass:"text-truncate cursor-pointer"},[_vm._v(" "+_vm._s(_vm._.get(scope.row, "customer.name"))+" ")])])])]}},{key:"billing_company",fn:function(ref){
var row = ref.row;
return [(row.billing_company)?_c('el-tooltip',{attrs:{"effect":"dark","placement":"top-start"}},[_c('div',{staticStyle:{"line-height":"26px"},attrs:{"slot":"content"},slot:"content"},[_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"ws-n"},[_vm._v("开票公司：")]),_vm._v(" "+_vm._s(row.billing_company.company)+" ")]),_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"ws-n"},[_vm._v("城市：")]),_vm._v(" "+_vm._s(row.billing_company.city.join(","))+" ")]),_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"ws-n"},[_vm._v("详细地址：")]),_vm._v(" "+_vm._s(row.billing_company.address)+" ")]),_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"ws-n"},[_vm._v("联系人：")]),_vm._v(" "+_vm._s(row.billing_company.contact)+" ")]),_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"ws-n"},[_vm._v("手机号：")]),_vm._v(" "+_vm._s(row.billing_company.mobile)+" ")]),_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"ws-n"},[_vm._v("税号：")]),_vm._v(" "+_vm._s(row.billing_company.duty_paragraph)+" ")]),_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"ws-n"},[_vm._v("开户行：")]),_vm._v(" "+_vm._s(row.billing_company.bank)+" ")]),_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"ws-n"},[_vm._v("开户行账号：")]),_vm._v(" "+_vm._s(row.billing_company.account)+" ")])]),_c('div',{staticClass:"text-truncate cursor-pointer text-primary"},[_c('span',[_vm._v(_vm._s(row.billing_company.company))])])]):_vm._e()]}},{key:"billing_type",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.billing_type))])]}},{key:"order_amount",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.order_amount))])]}},{key:"invoice_amount1",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row && row.invoice_amount))])]}},{key:"mailing_contact",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.mailing_contact))])]}},{key:"mailing_mobile",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.mailing_mobile))])]}},{key:"mailing_address",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.mailing_address))])]}}]),model:{value:(_vm.invoiceModel),callback:function ($$v) {_vm.invoiceModel=$$v},expression:"invoiceModel"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }