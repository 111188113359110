<template>
  <div>
    <div class="ml-3 mb-3 mt-3">
      <el-button size="small" type="success" plain @click="exportFollowUp"
        >导出跟进记录</el-button
      >
    </div>
    <avue-crud
      :data="data"
      :option="option"
      @row-save="create"
      @row-update="update"
      @row-del="remove"
      :page.sync="page"
      @on-load="changPage"
      @search-change="search"
      v-model="obj"
    >
      <template slot="contact_way" slot-scope="scope">
        <div>{{ scope.row.history.contact_way }}</div>
      </template>

      <template slot="teachers" slot-scope="scope" class="d-flex">
        <el-tooltip
          effect="dark"
          :content="scope.row.teachers.map(v=>v.name).join(' | ')"
          placement="top-start"
        >
        <div class=" w-100 justify-content mx-auto text-align text-truncate1">
          <span class="  mx-2" v-for="t in  scope.row.teachers" :key="t._id">
            {{ t.name }}
          </span>
        </div>
        </el-tooltip>
       
      </template>

      <template slot="name" slot-scope="scope">
        <el-tooltip
          effect="dark"
          :content="scope.row.name"
          placement="top-start"
        >
          <div
            @click="goCustomer(scope.row)"
            class="text-truncate text-primary cursor-pointer"
          >
            {{ scope.row.name }}
          </div>
        </el-tooltip>
      </template>

      <template slot="time" slot-scope="scope">
        <div v-if="scope.row.history.time">
          {{ dayjs(scope.row.history.time).format("YYYY-MM-DD HH:mm") }}
        </div>
        <div v-else>
          {{ dayjs(scope.row.history.submitedAt).format("YYYY-MM-DD HH:mm") }}
        </div>
      </template>
      <template slot="followUp_type" slot-scope="scope">
        <span> {{ followUpType(scope.row.history.followUp_type) }}</span>
      </template>
      <template slot="content" slot-scope="scope">
        <el-tooltip
          effect="dark"
          :content="scope.row.history.content"
          placement="top-start"
        >
          <div class="text-truncate1 cursor-pointer">
            {{ scope.row.history.content }}
          </div>
        </el-tooltip>
      </template>
      <template slot="contact" slot-scope="scope">
        <div>{{ scope.row.history.contact }}</div>
      </template>
      <template slot="user" slot-scope="scope">
        <div v-if="!Array.isArray(scope.row.user)">
          {{ scope.row.user && scope.row.user.realname }}
        </div>
        <span v-else>
          {{ (scope.row.user || []).map((v) => v.realname).join(",") }}
        </span>
      </template>
    </avue-crud>
  </div>
</template>

<script>
import dayjs from "dayjs";
export default {
  data() {
    return {
      isAdd: false,
      query: {
        page: 1,
        where: {},
        limit: 10,
      },
      page: {
        total: 0,
      },
      obj: {
        followUp_type: "daily",
      },
      contacts: [],
      data: [],
    };
  },
  computed: {
    option() {
      return {
        border: true,
        searchMenuSpan: 4,
        menuWidth: 160,
        index: true,
        align: "center",
        editBtn: false,
        removeBtn: false,
        menu: false,
        indexLabel: "序号",
        translate: false,
        column: [
          {
            label: "跟进客户",
            prop: "id",
            // search: true,
            filterable: true,
            showColumn: false,
            hide: true,
            props: {
              label: "name",
              value: "_id",
            },
            span: 12,
            type: "select",
            dicUrl: "customers/option",
            rules: [
              {
                message: "请选择跟进客户",
                required: true,
                trigger: "blur",
              },
            ],
          },

          {
            label: "跟进客户",
            prop: "name",
            props: {
              label: "name",
              value: "_id",
            },
            display: false,
            dicUrl: "customers/option",
            search: true,
            filterable: true,
            searchSpan:4,
            span: 12,
            slot: true,
          },
          {
            label: "联系人",
            prop: "contact",
            type: "select",
           
            props: {
              label: "name",
              value: "name",
            },
            dicData: [],
            span: 12,
            search: true,
            searchSpan:4,
            slot: true,
            width: "100px",
          },
          {
            label: "跟进内容",
            prop: "content",
            type: "textarea",
            span: 24,
            minRows: 2,
            slot: true,
            rules: [
              {
                message: "请输入跟进内容",
                required: true,
                trigger: "blur",
              },
            ],
            width: 600,
          },
          {
            label: "推荐教师",
            prop: "teachers",
            // search: true,
            filterable: true,
            showColumn: false,
            // hide: true,
            width:150,
            props: {
              label: "name",
              value: "_id",
            },
            span: 12,
            type: "select",
            multiple:true,
            dicUrl: "teachers/option",
            rules: [
              {
                message: "请选择推荐教师",
                required: true,
                trigger: "blur",
              },
            ],
          },
          {
            label: "距上次联系天数",
            prop: "days_not_contacted",
            display: false,
            width: "110px",
            formatter: (row) => {
              if (!row.last) {
                return "/";
              }
              return dayjs().diff(row.last, "day");
            },
          },
          // {
          //   label: "词典类型",
          //   prop: "type",
          // },

          {
            label: "沟通方式",
            prop: "contact_way",
            span: 12,
            props: {
              label: "label",
              value: "label",
            },
            type: "select",
            slot: true,
            width: "100px",
            dicUrl: "dictionaries/type/contact.way",
          },
          {
            label: "跟进日期",
            prop: "time",
            type: "datetime",
            format: "yyyy-MM-dd hh:mm:ss",
            span: 12,
            slot: true,
            width: "160px",
            rules: [
              {
                message: "请选择跟进日期",
                required: true,
                trigger: "blur",
              },
            ],
          },

       

          {
            label: "跟进类型",
            prop: "followUp_type",
            span: 12,
            type: "radio",
            slot: true,
            searchSpan:4,
            width: "100px",
            search: true,
            dicData: [
              {
                label: "日常业务对接",
                value: "daily",
              },
              {
                label: "重点业务机会",
                value: "keyPoint",
              },
            ],
          },

          {
            label: "跟进人",
            type: "select",
            filterable: true,
            props: {
              label: "realname",
              value: "_id",
            },
            prop: "users",
            dicData: [],
            // dicQuery: {
            //   query: {
            //     limit: 0,
            //     where: {
            //       role: { $in: [1, 4] },
            //     },
            //   },
            // },
            span: 8,
            slot: true,
            searchFilterable: true,
            hide: true,
            display: this.user.role == 0 || this.user.role == 5,
          },
          {
            label: "负责人",
            search: true,
            type: "select",
            filterable: true,
            props: {
              label: "realname",
              value: "_id",
            },
            prop: "user",
            searchSpan:4,
            dicData: [],
            // dicQuery: {
            //   query: {
            //     limit: 0,
            //     where: {
            //       role: { $in: [1, 4] },
            //     },
            //   },
            // },
            span: 8,
            slot: true,
            searchFilterable: true,
            display: false,
            width:100,
          },
        ],
      };
    },
  },
  watch: {
    "obj.id": {
      async handler(id) {
        if (id) {
          // this.fetchCustomer(id)
          this.fetchContact(id);
        }
      },
      immediate: true,
    },
  },
  methods: {
    async exportFollowUp() {
      const loading = this.$loading({
        lock: true,
        text: "导出中，请稍后。请勿关闭刷新页面",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const res = await this.$http.post(
        "/customers/followUpExport/",
        {},
        {
          responseType: "arraybuffer",
        }
      );
      const link = document.createElement("a");
      let blob = new Blob([res.data], { type: "application/x-xls" });
      link.href = URL.createObjectURL(blob);
      const title = `跟进记录-${dayjs().format("YYYY-MM-DD")}`;
      link.download = `${title}.xlsx`;
      link.click();
      document.body.appendChild(link);
      loading.close();
      this.$message.success("导出成功");
    },

    // beforeClose(done, type) {
    //   this.isAdd = false;
    //   done()
    // },
    followUpType(val) {
      let type = null;
      if (val == "keyPoint") {
        type = "重点业务机会";
      } else {
        type = "日常业务对接";
      }
      return type;
    },
    beforeOpen(done, type) {
      if (["view", "edit"].includes(type)) {
        // 查看和编辑逻辑
      } else {
        //新增逻辑
        setTimeout(() => {
          this.isAdd = true;
        }, 0);
        console.log(this.isAdd);
      }
      done();
    },
    async fetchUser() {
      const res = await this.$http.get(`users`, {
        params: {
          query: {
            limit: 0,
            where: {
              role: { $in: [1, 4, 5] },
            },
          },
        },
      });
      const users = res.data.data;
      let usersId = [];
      const form = [...this.user.userInfo, this.user._id];
      if (this.user.role == 5) {
        users.forEach((v) => {
          if (form.includes(v._id)) {
            usersId.push(v);
          }
        });
      } else {
        const user = users.find((v) => v._id == this.user._id);
        usersId.push(user);
      }
      if (this.user.role == 0) {
        this.users = users;
      } else {
        this.users = usersId;
      }
      const field = this.option.column.find((v) => v.prop === "user");
      const fields = this.option.column.find((v) => v.prop === "users");
      field.dicData = this.users;
      fields.dicData = this.users;
    },

    goCustomer(row) {
      this.$router.push(`/customers/list?customerId=${row._id}`);
    },
    async fetchContact(id) {
      const customer = (await this.$http.get(`customers/${id}`)).data;
      const field = this.option.column.find((v) => v.prop === "contact");
      const options =
        customer.contacts && customer.contacts.length > 0
          ? customer.contacts
          : [{ name: "" }];
      field.dicData = options;
      // this.contacts = options;
      // this.fetch();
    },

    async search(where, done) {
      done();
      if (where.name) {
        where.name = { $regex: where.name };
      }
      if (where.followUp_type) {
        where.followUp_type = { $regex: where.followUp_type };
      }
      if (where.user) {
        where.user = where.user;
      }
      this.query.where = where;
      this.fetch();
    },
    async changPage({ pageSize, currentPage }) {
      this.query.page = currentPage;
      this.query.limit = pageSize;
      this.fetch();
    },
    async fetch() {
      const res = await this.$http.post("customers/follow_up/list", {
        params: {
          query: JSON.stringify(this.query),
        },
      });
      this.page.total = res.data.total;
      this.data = res.data.data;
    },
    async remove(row) {
      try {
        await this.$confirm("是否确认删除？");
      } catch (e) {
        return;
      }
      await this.$http.delete(`invoices/${row._id}`);
      this.$message.success("删除成功");
      this.fetch();
    },
    async update(row, index, done) {
      const data = JSON.parse(JSON.stringify(row));
      delete data.$index;
      delete data.$grade;
      await this.$http.put(`invoices/${row._id}`, data);
      this.$message.success("更新成功");
      this.fetch();
      done();
    },
    async create(row, done) {
      row.submitedAt = new Date();
      if (this.obj.users) {
        row.user = this.obj.users;
      } else {
        row.user = this.user._id;
      }

      const res = await this.$http.get(`customers/${row.id}`);
      const customer = res.data;
      customer.history.push(row);
      await this.$http.put(`customers/${customer._id}`, customer);
      this.$message.success("提交成功");
      this.fetch();
      done();
    },
  },
  created() {
    this.fetch();
    this.fetchUser();
  },
};
</script>

<style></style>
