<template>
  <div class="invoiceManage">
    <avue-crud
      :data="data"
      :option="option"
      v-model="obj"
      @row-update="update"
      :page.sync="page"
      @on-load="changPage"
      @search-change="search"
      :before-open="beforeOpen"
    >
      <template slot="isBill" slot-scope="scope">
        <el-tag type="info" v-if="scope.row.isBill">已开票</el-tag>
        <el-tag type="success" v-else>待开票</el-tag>
      </template>
      <template slot="customer" slot-scope="scope">
        <el-tooltip
          effect="dark"
          :content="_.get(scope.row, 'customer.name')"
          placement="top-start"
        >
          <div>
            <div class="text-truncate cursor-pointer">
              {{ _.get(scope.row, "customer.name") }}
            </div>
          </div>
        </el-tooltip>
      </template>
       <template #billing_company="{ row }">
          <el-tooltip
          effect="dark"
          :content="row.billing_company.company"
          placement="top-start"
          >     
          <div class="text-truncate cursor-pointer">
             <span>{{ row.billing_company.company }}</span
            >        
          </div>
        </el-tooltip>
           </template
      >
    </avue-crud>
  </div>
</template>

<script>
export default {
  data() {
    return {
      query: {
        sort: { createdAt: -1 },
        limit: 10,
        sort: { createdAt: -1 },
        where: {
          invoice_amount: { $gt: 0 },
        },
        populate: [
          // {
          //   path: "customer",
          //   select: "name",
          // },
          // "user",
          // {
          //   path: "teacher",
          //   select: "name",
          // },
          "customer",
          "billing_company",
          //  {
          //   path: "billing_company",
          //   select: "company",
          // },
        ],
      },
      page: {
        total: 0,
      },
      obj: {},
      data: [],
      option: {
        border: true,
        addBtn: false,
        // editBtn: false,
        delBtn: false,
        viewBtn: true,
        header: false,
        border: true,
        searchMenuSpan: 5,
        menuWidth: 160,
        index: true,
        indexLabel: "序号",
        translate: false,
        column: [
          {
            label: "客户名称",
            prop: "customer",
            props: {
              label: "name",
              value: "_id",
            },
            dicUrl: `customers/list`,
            slot: true,
            span: 12,
            rules: [
              {
                required: true,
                message: "请选择客户名称",
                trigger: "blur",
              },
            ],
            type: "select",
          },
          {
            label: "客户开票公司",
            filterable: true,
            prop: "customer_billing_company",
            type: "select",
            span: 12,
            props: {
              label: "company",
              value: "company",
              // res: 'contacts',
              // children: 'contacts'
            },
          },
          {
            label: "开票公司",
            prop: "billing_company",
            type: "select",
            filterable: true,
            searchFilterable: true,
            slot: true,
            props: {
              label: "company",
              value: "_id",
            },
            // slot:true,
            dicUrl: `invoices/option`,
            search: true,
            span: 12,
            rules: [
              {
                message: "请输入开票公司",
                required: true,
                trigger: "blur",
              },
            ],
          },
          {
            label: "发票类型",
            prop: "billing_type",
            type: "radio",
            dicData: [
              {
                label: `普票`,
                value: "普票",
              },
              {
                label: `专票`,
                value: "专票",
              },
            ],
            rules: [
              {
                required: true,
                message: "请选择发票类型",
                trigger: "blur",
              },
            ],
            span: 12,
          },
          {
            label: "发票金额(税后)",
            prop: "invoice_amount",
            type: "number",
            span: 12,
            rules: [
              {
                required: true,
                message: "请填写发票金额",
                trigger: "blur",
              },
            ],
          },
          {
            label: "开票时间",
            prop: "billing_time",
            type: "datetime",
            span: 12,
            format: "yyyy-MM-dd hh:mm:ss",
            mock: {
              type: "datetime",
              format: "yyyy-MM-dd hh:mm:ss",
              now: true,
            },
          },
          {
            label: "订单总额",
            prop: "order_amount",
            type: "number",
            span: 12,
          },
          {
            label: "是否开票",
            prop: "isBill",
            slot: true,
            type: "radio",
            dicData: [
              {
                label: "是",
                value: true,
              },
              {
                label: "否",
                value: false,
              },
            ],
            span: 12,
          },
          // {
          //   label: "词典类型",
          //   prop: "type",
          // },
          // {
          //   label: "联系人",
          //   prop: "contact",
          //   span: 12,
          // },
        ],
      },
    };
  },
  methods: {
    beforeOpen(done, type) {
      const customer = this.obj.customer || {};
      const billing_company = this.obj.billing_company || {};
      this.obj.customer = customer._id;
      this.obj.billing_company = billing_company._id;
      done();
    },
    async search(where, done) {
      done();
      if (!where.billing_company) {
        this.query.where.billing_company = undefined;
      } else {
        this.query.where.billing_company = where.billing_company;
      }
      this.fetch();
    },
    async changPage({ pageSize, currentPage }) {
      this.query.page = currentPage;
      this.query.limit = pageSize;
      this.fetch();
    },
    async fetch() {
      const res = await this.$http.get("schedules", {
        params: {
          query: this.query,
        },
      });
      this.page.total = res.data.total;
      this.data = res.data.data;
    },
    // async remove(row) {
    //   try {
    //     await this.$confirm("是否确认删除？");
    //   } catch (e) {
    //     return;
    //   }
    //   await this.$http.delete(`invoices/${row._id}`);
    //   this.$message.success("删除成功");
    //   this.fetch();
    // },
    async update(row, index, done) {
      const data = JSON.parse(JSON.stringify(row));
      delete data.$index;
      delete data.$grade;
      await this.$http.put(`schedules/${row._id}`, data);
      this.$message.success("更新成功");
      this.fetch();
      done();
    },
  },
  created() {
    this.fetch();
  },
};
</script>

<style>
</style>