<template>
  <div class="invoiceManage">
    <el-tabs
      class="px-2"
      v-model="activeName"
      type="card"
      @tab-click="handleClick"
    >
      <el-tab-pane label="待开票" name="first">
        <div class="mb-3 ml-2">
          <el-button
            @click="addInvoice"
            :disabled="!select_list.length"
            type="primary"
            size="small"
            ><i class="el-icon-plus pr-2"></i>新增开票</el-button
          >
        </div>
        <avue-crud
          :data="data"
          :option="option"
          ref="invoice_crud"
          v-model="obj"
          @row-update="update"
          :page.sync="page"
          @on-load="changPage"
          @search-change="search"
          :before-open="beforeOpen"
          @selection-change="selectionChange"
        >
          <template slot="customer_billing_company" slot-scope="{ row }">
            <el-tooltip
              effect="dark"
              :content="row.customer_billing_company"
              placement="top-start"
            >
              <!-- <div class="text-primary">{{ row.name }}</div> -->
              <div class="text-truncate text-primary cursor-pointer">
                {{ row.customer_billing_company }}
              </div>
            </el-tooltip>
          </template>
          <template slot="isBill" slot-scope="scope">
            <el-tag type="success" v-if="scope.row.isBill">已开票</el-tag>
            <el-tag type="info" v-else>待开票</el-tag>
          </template>
          <template slot="customer" slot-scope="scope">
            <el-tooltip
              effect="dark"
              :content="_.get(scope.row, 'customer.name')"
              placement="top-start"
            >
              <div>
                <div class="text-truncate cursor-pointer">
                  {{ _.get(scope.row, "customer.name") }}
                </div>
              </div>
            </el-tooltip>
          </template>
          <template #billing_company="{ row }">
            <el-tooltip v-if="row.billing_company" effect="dark" placement="top-start">
              <div slot="content"  style="line-height: 26px">
                <div class="d-flex">
                  <span class="ws-n">开票公司：</span>
                  {{ row.billing_company.company }}
                </div>
                <div class="d-flex">
                  <span class="ws-n">城市：</span>
                  {{ row.billing_company.city.join(",") }}
                </div>
                <div class="d-flex">
                  <span class="ws-n">详细地址：</span>
                  {{ row.billing_company.address }}
                </div>
                <div class="d-flex">
                  <span class="ws-n">联系人：</span>
                  {{ row.billing_company.contact }}
                </div>
                <div class="d-flex">
                  <span class="ws-n">手机号：</span>
                  {{ row.billing_company.mobile }}
                </div>
                <div class="d-flex">
                  <span class="ws-n">税号：</span>
                  {{ row.billing_company.duty_paragraph }}
                </div>
                <div class="d-flex">
                  <span class="ws-n">开户行：</span>
                  {{ row.billing_company.bank }}
                </div>
                <div class="d-flex">
                  <span class="ws-n">开户行账号：</span>
                  {{ row.billing_company.account }}
                </div>
              </div>
              <div class="text-truncate cursor-pointer text-primary">
                <span>{{ row.billing_company.company }}</span>
              </div>
            </el-tooltip>
          </template>

          <template #customer_billing_company="{ row }">
            <el-tooltip v-if="findkp(row)" effect="dark" placement="top-start">
              <div slot="content">
                <div>
                  <span>客户开票公司：</span
                  ><span>{{ findkp(row).company }}</span>
                </div>
                <div class="py-2">
                  <span>手机号：</span><span>{{ findkp(row).mobile }}</span>
                </div>
                <div>
                  <span>税号：</span
                  ><span>{{ findkp(row).duty_paragraph }}</span>
                </div>
                <div class="py-2">
                  <span>开户行：</span><span>{{ findkp(row).bank }}</span>
                </div>
                <div>
                  <span>开户行账号：</span
                  ><span>{{ findkp(row).account }}</span>
                </div>
                <!-- <div><span>税号：</span><span>row.billing_company.company</span></div> -->
              </div>
              <div class="text-truncate cursor-pointer text-primary">
                <span>{{ row.billing_company && row.billing_company.company }}</span>
              </div>
            </el-tooltip>
          </template>
        </avue-crud>
      </el-tab-pane>
      <el-tab-pane label="已开票" name="second">
        <avue-crud
          :data="invoice_data"
          :option="invoiceOption"
          ref="invoice_crud"
          :page.sync="invoice_page"
          @on-load="changInvoicePage"
          :before-open="beforeOpen"
        >
          <template slot="billing_company" slot-scope="{ row }">
            <el-tooltip effect="dark" placement="top-start">
              <div slot="content" style="line-height: 26px">
                <div class="d-flex">
                  <span class="ws-n">开票公司：</span>
                  {{ firstSchedule(row).billing_company.company }}
                </div>
                <div class="d-flex">
                  <span class="ws-n">城市：</span>
                  {{ firstSchedule(row).billing_company.city.join(",") }}
                </div>
                <div class="d-flex">
                  <span class="ws-n">详细地址：</span>
                  {{ firstSchedule(row).billing_company.address }}
                </div>
                <div class="d-flex">
                  <span class="ws-n">联系人：</span>
                  {{ firstSchedule(row).billing_company.contact }}
                </div>
                <div class="d-flex">
                  <span class="ws-n">手机号：</span>
                  {{ firstSchedule(row).billing_company.mobile }}
                </div>
                <div class="d-flex">
                  <span class="ws-n">税号：</span>
                  {{ firstSchedule(row).billing_company.duty_paragraph }}
                </div>
                <div class="d-flex">
                  <span class="ws-n">开户行：</span>
                  {{ firstSchedule(row).billing_company.bank }}
                </div>
                <div class="d-flex">
                  <span class="ws-n">开户行账号：</span>
                  {{ firstSchedule(row).billing_company.account }}
                </div>
              </div>
              <div class="text-truncate text-primary cursor-pointer">
                {{ firstSchedule(row).billing_company.company }}
              </div>
            </el-tooltip>
          </template>
          <template slot="customer" slot-scope="{ row }">
            <el-tooltip
              effect="dark"
              :content="_.get(firstSchedule(row), 'customer.name')"
              placement="top-start"
            >
              <div>
                <div class="text-truncate cursor-pointer">
                  {{ _.get(firstSchedule(row), "customer.name") }}
                </div>
              </div>
            </el-tooltip>
          </template>
          <template slot="customer_billing_company" slot-scope="{ row }">
            <el-tooltip effect="dark" placement="top-start">
              <div slot="content">
                <div>
                  <span>客户开票公司：</span
                  ><span>{{ invoice_customer(row).company }}</span>
                </div>
                <div class="py-2">
                  <span>手机号：</span
                  ><span>{{ invoice_customer(row).mobile }}</span>
                </div>
                <div>
                  <span>税号：</span
                  ><span>{{ invoice_customer(row).duty_paragraph }}</span>
                </div>
                <div class="py-2">
                  <span>开户行：</span
                  ><span>{{ invoice_customer(row).bank }}</span>
                </div>
                <div>
                  <span>开户行账号：</span
                  ><span>{{ invoice_customer(row).account }}</span>
                </div>
                <!-- <div><span>税号：</span><span>row.billing_company.company</span></div> -->
              </div>

              <div class="text-truncate text-primary cursor-pointer">
                {{ invoice_customer(row).customer_billing_company }}
              </div>
            </el-tooltip>
          </template>

          <template slot="user" slot-scope="{ row }">
            <span>{{ row.user.realname }}</span>
          </template>
          <template slot-scope="{ type, size, row }" slot="menu">
            <el-button
              icon="el-icon-view"
              :size="size"
              :type="type"
              @click="viewItem(row)"
              >查看</el-button
            >
            <el-button
              icon="el-icon-delete"
              :size="size"
              :type="type"
              @click="deleteInvoice(row)"
              >删除</el-button
            >
          </template>
        </avue-crud>
      </el-tab-pane>
    </el-tabs>
    <el-dialog
      :title="invoiceModel._id ? '查看详情' : '添加开票'"
      :visible.sync="showInvoice"
      width="80%"
    >
      <avue-form
        v-model="invoiceModel"
        :option="invoice_form_options"
        @submit="submitInvoice"
      >
        <template slot="customer_billing_company" slot-scope="{ row }">
          <el-tooltip
            effect="dark"
            :content="row.customer_billing_company"
            placement="top-start"
          >
            <!-- <div class="text-primary">{{ row.name }}</div> -->
            <div class="text-truncate text-primary cursor-pointer">
              {{ row.customer_billing_company }}
            </div>
          </el-tooltip>
        </template>
        <template slot="isBill" slot-scope="scope">
          <el-tag type="info" v-if="scope.row.isBill">已开票</el-tag>
          <el-tag type="success" v-else>待开票</el-tag>
        </template>
        <template slot="customer" slot-scope="scope">
          <el-tooltip
            effect="dark"
            :content="_.get(scope.row, 'customer.name')"
            placement="top-start"
          >
            <div>
              <div class="text-truncate cursor-pointer">
                {{ _.get(scope.row, "customer.name") }}
              </div>
            </div>
          </el-tooltip>
        </template>
        <template #billing_company="{ row }">
          <el-tooltip v-if="row.billing_company" effect="dark" placement="top-start">
            <div slot="content" style="line-height: 26px">
              <div class="d-flex">
                <span class="ws-n">开票公司：</span>
                {{ row.billing_company.company }}
              </div>
              <div class="d-flex">
                <span class="ws-n">城市：</span>
                {{ row.billing_company.city.join(",") }}
              </div>
              <div class="d-flex">
                <span class="ws-n">详细地址：</span>
                {{ row.billing_company.address }}
              </div>
              <div class="d-flex">
                <span class="ws-n">联系人：</span>
                {{ row.billing_company.contact }}
              </div>
              <div class="d-flex">
                <span class="ws-n">手机号：</span>
                {{ row.billing_company.mobile }}
              </div>
              <div class="d-flex">
                <span class="ws-n">税号：</span>
                {{ row.billing_company.duty_paragraph }}
              </div>
              <div class="d-flex">
                <span class="ws-n">开户行：</span>
                {{ row.billing_company.bank }}
              </div>
              <div class="d-flex">
                <span class="ws-n">开户行账号：</span>
                {{ row.billing_company.account }}
              </div>
            </div>
            <div class="text-truncate cursor-pointer text-primary">
              <span>{{ row.billing_company.company }}</span>
            </div>
          </el-tooltip>
        </template>

        <template #customer_billing_company="{ row }">
          <el-tooltip v-if="findkp(row)" effect="dark" placement="top-start">
            <div slot="content">
              <div>
                <span>客户开票公司：</span
                ><span>{{ findkp(row).company }}</span>
              </div>
              <div class="py-2">
                <span>手机号：</span><span>{{ findkp(row).mobile }}</span>
              </div>
              <div>
                <span>税号：</span><span>{{ findkp(row).duty_paragraph }}</span>
              </div>
              <div class="py-2">
                <span>开户行：</span><span>{{ findkp(row).bank }}</span>
              </div>
              <div>
                <span>开户行账号：</span><span>{{ findkp(row).account }}</span>
              </div>
              <!-- <div><span>税号：</span><span>row.billing_company.company</span></div> -->
            </div>
            <div class="text-truncate cursor-pointer text-primary">
              <span>{{ row.billing_company && row.billing_company.company }}</span>
            </div>
          </el-tooltip>
        </template>
        <template #billing_type="{ row }">
          <span>{{ row.billing_type }}</span>
        </template>
        <template #order_amount="{ row }">
          <span>{{ row.order_amount }}</span>
        </template>
        <template #invoice_amount1="{ row }">
          <span>{{ row && row.invoice_amount }}</span>
        </template>
        <template #mailing_contact="{ row }">
          <span>{{ row.mailing_contact }}</span>
        </template>
        <template #mailing_mobile="{ row }">
          <span>{{ row.mailing_mobile }}</span>
        </template>
        <template #mailing_address="{ row }">
          <span>{{ row.mailing_address }}</span>
        </template>
      </avue-form>
    </el-dialog>
  </div>
</template>

<script>
const cloneDeep = (obj) => JSON.parse(JSON.stringify(obj));

const schedule_Columns = [
  {
    label: "客户名称",
    prop: "customer",
    props: {
      label: "name",
      value: "_id",
    },
    dicUrl: `customers/list`,
    slot: true,
    span: 12,
    rules: [
      {
        required: true,
        message: "请选择客户名称",
        trigger: "blur",
      },
    ],
    type: "select",
  },
  {
    label: "客户开票公司",
    filterable: true,
    prop: "customer_billing_company",
    type: "select",
    span: 12,
    slot: true,
    props: {
      label: "company",
      value: "company",
      // res: 'contacts',
      // children: 'contacts'
    },
  },

  {
    label: "发票类型",
    prop: "billing_type",
    type: "radio",
    dicData: [
      {
        label: `普票`,
        value: "普票",
      },
      {
        label: `专票`,
        value: "专票",
      },
      {
        label: `电子发票`,
        value: "电子发票",
      },
      {
        label: `纸质发票`,
        value: "纸质发票",
      },
    ],
    rules: [
      {
        required: true,
        message: "请选择发票类型",
        trigger: "blur",
      },
    ],
    span: 12,
  },
  {
    label: "发票金额(税后)",
    prop: "invoice_amount",
    type: "number",
    span: 12,
    rules: [
      {
        required: true,
        message: "请填写发票金额",
        trigger: "blur",
      },
    ],
  },
  {
    label: "订单总额",
    prop: "order_amount",
    type: "number",
    span: 12,
  },
  {
    label: "邮寄联系人",
    prop: "mailing_contact",
    span: 12,
  },
  {
    label: "邮寄联系电话",
    prop: "mailing_mobile",
    span: 12,
  },
  {
    label: "邮寄地址",
    prop: "mailing_address",
    type: "textarea",
    span: 12,
    minRows: 2,
  },

  // {
  //   label: "词典类型",
  //   prop: "type",
  // },
  // {
  //   label: "联系人",
  //   prop: "contact",
  //   span: 12,
  // },
];

export default {
  data() {
    return {
      activeName: "first",
      invoice_crud: "",
      showInvoice: false,
      select_list: [],
      query: {
        sort: { createdAt: -1 },
        limit: 10,
        sort: { createdAt: -1 },
        where: {
          invoice_amount: { $gt: 0 },
          isApplyBill: true,
          isBill: false,
        },
        populate: [
          // {
          //   path: "customer",
          //   select: "name",
          // },
          // "user",
          // {
          //   path: "teacher",
          //   select: "name",
          // },
          "customer",
          "billing_company",
          //  {
          //   path: "billing_company",
          //   select: "company",
          // },
        ],
      },
      invoiceModel: {
        invoice_amount: "",
        schedules: [],
      },
      page: {
        total: 0,
      },
      invoice_page: {
        total: 0,
      },
      obj: {},
      data: [],

      doing: false,
      invoiceOption: {
        border: true,
        // addBtn: true,
        // editBtn: false,
        delBtn: false,
        // viewBtn: true,
        header: false,
        border: true,
        searchMenuSpan: 5,
        menuWidth: 160,
        index: true,
        indexLabel: "序号",
        translate: false,
        editBtn: false,
        column: [
          {
            label: "开票公司",
            filterable: true,
            prop: "billing_company",
            type: "text",
            span: 12,
            slot: true,
          },
          {
            label: "客户名称",
            filterable: true,
            prop: "customer",
            type: "text",
            span: 12,
            slot: true,
          },
          {
            label: "客户开票公司",
            filterable: true,
            prop: "customer_billing_company",
            type: "text",
            span: 12,
            slot: true,
          },
          {
            label: "开票档期数量",
            filterable: true,
            prop: "schedule_count",
            type: "text",
            span: 12,
            slot: true,
          },
          {
            label: "发票总金额(税后)",
            prop: "invoice_amount",
            type: "number",
            span: 12,
            rules: [
              {
                required: true,
                message: "请填写发票金额",
                trigger: "blur",
              },
            ],
          },

          {
            label: "开票人",
            filterable: true,
            prop: "user",
            type: "text",
            span: 12,
            slot: true,
          },

          {
            label: "创建时间",
            filterable: true,
            prop: "createdAt",
            type: "datetime",
            format: "yyyy-MM-dd hh:mm:ss",
            span: 12,
            slot: true,
          },
        ],
      },
      invoice_query: {
        sort: { createdAt: -1 },
        limit: 10,
        sort: { createdAt: -1 },
        where: {},
      },
      invoice_data: [],
      invoice_form_options: {
        submitBtn: true,
        emptyBtn: false,
        submitText: "确认开票",
        column: [
          // {
          //   label: "公司名称",
          //   prop: "billing_company",
          //   type: "select",
          //   props: {
          //     label: "company",
          //     value: "_id",
          //   },
          //   dicUrl: `invoices/option`,
          // },
          {
            label: "开票档期",
            prop: "schedules",
            type: "dynamic",
            span: 24,
            labelWidth: 120,
            children: {
              addBtn: false,
              delBtn: false,
              column: [
                {
                  label: "客户名称",
                  filterable: true,
                  prop: "customer",
                  type: "text",
                  span: 12,
                  slot: true,
                },
                {
                  label: "开票公司",
                  filterable: true,
                  prop: "billing_company",
                  type: "text",
                  span: 12,
                  slot: true,
                },
                {
                  label: "客户开票公司",
                  filterable: true,
                  prop: "customer_billing_company",
                  type: "text",
                  span: 12,
                  slot: true,
                },
                {
                  label: "发票类型",
                  prop: "billing_type",
                  span: 12,
                  slot: true,
                  type: "",
                },
                {
                  label: "发票金额(税后)",
                  prop: "invoice_amount1",
                  span: 12,
                  slot: true,
                  type: "",
                },
                {
                  label: "订单总额",
                  prop: "order_amount",
                  span: 12,
                  slot: true,
                  type: "",
                },
                {
                  label: "邮寄联系人",
                  prop: "mailing_contact",
                  span: 12,
                  slot: true,
                  type: "",
                },
                {
                  label: "邮寄联系电话",
                  prop: "mailing_mobile",
                  span: 12,
                  slot: true,
                  type: "",
                },
                {
                  label: "邮寄地址",
                  prop: "mailing_address",
                  span: 12,
                  slot: true,
                  type: "",
                },
              ],
            },
          },
          {
            label: "发票总金额(税后)",
            prop: "invoice_amount",
            type: "number",
            span: 24,
            labelWidth: 120,
          },
        ],
      },
    };
  },
  computed: {
    firstSchedule() {
      return (row) => {
        const schedule = row?.schedules[0] || {};
        return schedule;
      };
    },
    option(){

      const type=this.obj.billing_type;
    return  {
        selection: true,
        border: true,
        // addBtn: true,
        // editBtn: false,
        delBtn: false,
        // viewBtn: true,
        header: false,
        border: true,
        searchMenuSpan: 5,
        menuWidth: 160,
        index: true,
        indexLabel: "序号",
        translate: false,
        column: [
          {
            label: "开票公司",
            prop: "billing_company",
            type: "select",
            filterable: true,
            searchFilterable: true,
            slot: true,
            props: {
              label: "company",
              value: "_id",
            },
            // slot:true,
            dicUrl: `invoices/option`,
            search: true,
            span: 12,
            rules: [
              {
                message: "请输入开票公司",
                required: true,
                trigger: "blur",
              },
            ],
          },
          ...cloneDeep(schedule_Columns),
          {
            label: "电子发票图片",
            prop: "invoice_img",
            type: "upload",
            dataType: "array",
            listType: "picture-card",
            action: "upload",
            multiple: true,
            span: 24,
            display:type==='电子发票',
          },
          {
            label: "开票状态",
            prop: "isBill",
            slot: true,
            type: "radio",
            dicData: [
              {
                label: "是",
                value: true,
              },
              {
                label: "否",
                value: false,
              },
            ],
            span: 12,
            // display:false
          },
        ],
      }
    },

    invoice_customer() {
      return (row) => {
        return {
          ...this.firstSchedule(row),
          ...this.findkp(this.firstSchedule(row)),
        };
      };
    },
  },
  methods: {
    // 新增开票方法
    async addInvoice() {
      this.invoiceModel.schedules = this.select_list;
      this.invoiceModel.invoice_amount = this.getTotal(this.select_list);
      await this.checkSchedules();
      this.showInvoice = true;
      this.invoice_form_options.submitBtn = true;
    },
    checkSchedules() {
      return new Promise((resolve, reject) => {
        const company_names = new Set(
          this.invoiceModel.schedules.map((v) => {
            return v?.billing_company?.company;
          })
        );
        if (company_names.size > 1) {
          this.$message.error("开票公司不一致");
          return reject(false);
        }

        const customer_names = new Set(
          this.invoiceModel.schedules.map((v) => {
            return v.customer.name;
          })
        );
        if (customer_names.size > 1) {
          this.$message.error("客户名称不一致");
          return reject(false);
        }

        const customer_company_names = new Set(
          this.invoiceModel.schedules.map((v) => {
            return this.findkp(v)?.company;
          })
        );
        if (customer_company_names.size > 1) {
          this.$message.error("客户开票公司不一致");
          return reject(false);
        }

        resolve(true);
      });
    },
    // 表格勾选方法
    selectionChange(selection) {
      // this.$set("invoiceModel.schedules", selection);
      this.select_list = cloneDeep(selection);

      // this.data = this.data.map((v, index) => {
      //   return {
      //     ...v,
      //     disabled: index == 1 ? true : false,
      //   };
      // });
    },
    getTotal(arr) {
      return arr.reduce((total, cur) => {
        return (total += cur.invoice_amount);
      }, 0);
    },
    findkp(row) {
      // let obj
      const obj = row.customer.invoices.find(
        (v) => v.company == row.customer_billing_company
      );
      return obj;
    },
    async submitInvoice(form, done) {
      const params = {
        ...this.invoiceModel,
        schedules: this.invoiceModel.schedules.map((v) => v._id),
      };
      
      if (params["$billing_company"]) {
        delete params["$billing_company"];
      }

      await this.$http.post("invoice-manage", params);
      this.$message.success("开票成功～请至已开票查看");
      this.fetch().then(() => {
        this.showInvoice = false;
        done();

      });
    },
    async createInvoice(done) {},
    handleClick(tab, event) {
      this.invoiceModel = {
        schedules: [],
        invoice_amount: "",
      };
      // console.log(this.activeName);
    },
    beforeOpen(done, type) {
      const customer = this.obj.customer || {};
      const billing_company = this.obj.billing_company || {};
      this.obj.customer = customer._id;
      this.obj.billing_company = billing_company._id;
      done();
    },
    async search(where, done) {
      done();
      if (!where.billing_company) {
        this.query.where.billing_company = undefined;
      } else {
        this.query.where.billing_company = where.billing_company;
      }
      this.fetch();
    },
    async changPage({ pageSize, currentPage }) {
      this.query.page = currentPage;
      this.query.limit = pageSize;
      this.fetch();
    },
    async changInvoicePage({ pageSize, currentPage }) {
      this.invoice_query.page = currentPage;
      this.invoice_query.limit = pageSize;
      this.fetch();
    },
    async fetch() {
      const [schedule_res, invoice_res] = await Promise.all([
        this.$http.get("schedules", {
          params: {
            query: this.query,
          },
        }),
        this.$http.get("invoice-manage", {
          params: {
            query: this.invoice_query,
          },
        }),
      ]);

      this.page.total = schedule_res.data.total;
      this.data = schedule_res.data.data;
      this.invoice_data = invoice_res.data.data;
      this.invoice_page.total = invoice_res.data.total;
    },

    // async remove(row) {
    //   try {
    //     await this.$confirm("是否确认删除？");
    //   } catch (e) {
    //     return;
    //   }
    //   await this.$http.delete(`invoices/${row._id}`);
    //   this.$message.success("删除成功");
    //   this.fetch();
    // },
    async update(row, index, done) {
      const data = JSON.parse(JSON.stringify(row));
      delete data.$index;
      delete data.$grade;
      await this.$http.put(`schedules/${row._id}`, data);
      this.$message.success("更新成功");
      this.fetch();
      done();
    },

    deleteInvoice(row) {
      this.$confirm("您确认是否删除此开票记录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        await this.$http.delete("invoice-manage/" + row._id);
        this.$message({
          type: "success",
          message: "删除成功!",
        });
        this.fetch();
      });
    },

    viewItem(row) {
      const params = {
        schedules: row.schedules,
        invoice_amount: row.invoice_amount,
      };

      this.invoice_form_options.emptyBtn = false;
      this.invoice_form_options.submitBtn = false;
      this.$nextTick(() => {
        this.showInvoice = true;
        this.invoiceModel = params;
        this.invoiceModel._id = row._id;
      });
    },
  },
  created() {
    this.fetch();
  },
};
</script>

<style></style>
