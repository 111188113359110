<template>
  <div class="courseware">
    <div class="search my-3"></div>
    <avue-crud
      ref="crud"
      :data="dunnData"
      :option="dunnOption"
      :page.sync="page"
      @on-load="changPage"
      v-model="dataQuery"
      :table-loading="loading"
      @search-change="search"
      @selection-change="selectionChange"
    >
      <!-- <template #search="{size}">

        <div class="d-flex align-items-center">
          <span>分组:</span> {{ size }}

          <el-radio-group v-model="dataQuery.group">
            <el-radio
              :label="item.value"
              :key="item.value"
              v-for="item in groupData"
              >{{ item.label }}</el-radio
            >
          </el-radio-group>
        </div>
      </template> -->
      <template slot="groupSearch">
        <el-radio-group
          @change="changeGroup"
          v-model="dataQuery.group"
          class="group-radio"
        >
          <el-radio
            :label="item.value"
            :key="item.value"
            v-for="item in groupData"
            >{{ item.label }}</el-radio
          >
        </el-radio-group>
      </template>

      <template slot="menuLeft">
        <el-button
          type="primary"
          size="small"
          icon="el-icon-download"
          @click="batchDownload"
          >批量生成
        </el-button>
      </template>

      <template #times="{ row }">
        <div class="text-align">
          <el-tooltip
            effect="dark"
            :content="
              _.get(row, 'times', [])
                .map((v) => $filters.formatDateT(v))
                .join('，')
            "
            placement="top-start"
          >
            <!-- <div v-for="(item, i) in _.get(row, 'times', [])" :key="i">
                  {{ $filters.formatDateT(item) }}
                </div>-->

            <div
              class="cursor-pointer"
              :class="IsPC ? 'text-truncate1' : 'text-truncate'"
              style="max-width: 20vw"
            >
              <!-- {{ scope.row.times && scope.row.times.join("<br>") | formatDateT
                  }}-->

              <div
                class="times"
                v-for="(item, i) in _.get(row, 'times', []).slice(0, 4)"
                :key="i"
              >
                <span :key="i">{{ $filters.formatDateT(item) }}</span>
                <!-- <br :key="`br-` + i" /> -->
              </div>
            </div>
          </el-tooltip>
        </div>
      </template>
      <template slot="teacher" slot-scope="scope">
        <div>
          <el-tooltip
            effect="dark"
            :content="expandArr(scope.row.teacher, 'name')"
            placement="top-start"
          >
            <span
              v-for="teacher of scope.row.teacher.slice(0, 1)"
              :key="teacher._id"
            >
              {{ teacher.name }}
            </span>
          </el-tooltip>
        </div>
      </template>
      <template slot="terminal_name" slot-scope="scope">
        <div v-if="dataQuery.group === 'customer-terminal_name'">
          {{ scope.row.terminal_name }}
        </div>

        <div v-if="dataQuery.group === 'customer-user'">
          <el-tooltip
            effect="dark"
            :content="scope.row.terminal_names.join(',')"
            placement="top-start"
          >
            <span>{{ scope.row.terminal_names[0] }}</span>
          </el-tooltip>
        </div>
      </template>
      <template slot="user" slot-scope="scope">
        <div>
          <span v-for="user of scope.row.user" :key="user._id">
            {{ user.realname }}
          </span>
        </div>
      </template>

      <template slot="contract_signing_time" slot-scope="scope">
        <div>
          <el-tooltip
            effect="dark"
            placement="top-start"
            :content="sign_info(scope.row)"
          >
            <span>{{
              $filters.formatDateT(scope.row.contract_signing_time[0].time)
            }}</span>
          </el-tooltip>
        </div>
      </template>

      <template slot="status" slot-scope="scope">
        <div>
          <el-tag :type="scope.row.isDocx ? 'success' : 'primary'">{{
            scope.row.isDocx ? "已生成" : "未生成"
          }}</el-tag>
        </div>
      </template>

      <template slot-scope="scope" slot="menu">
        <el-button
          icon="iconfont icon-genjinjilu"
          @click="downloadWord(scope.row)"
          :size="scope.size"
          :type="scope.type"
          >生成催款函</el-button
        >
      </template>
    </avue-crud>
  </div>
</template>

<script>
import { formatDate, formatDateT } from "../../filters";
import { ossClient } from "../../plugins/upload";
import { Docxer } from "../../plugins/docx";
import * as _ from "lodash";
export default {
  data() {
    return {
      loading: false,
      page: {
        total: 0,
        // currentPage:1,
        // pageSize: 1,
        // pageSizes:[1,5,10]
      },
      dunnData: [],

      dataQuery: {
        customer: "",
        teacher: "",
        limit: 10,
        page: 1,
        group: "customer-terminal_name",
      },
      groupData: [
        {
          label: "终端客户+公司",
          value: "customer-terminal_name",
        },
        {
          label: "负责人+公司",
          value: "customer-user",
        },
      ],
      select_list: [],
      dunnOption: {
        size: "mini",
        align: "center",
        border: true,
        selection: true,
        index: true,
        indexLabel: "序号",
        searchMenuSpan: 4,
        header: true,
        dialogClickModal: false,
        emptyBtn: false,
        border: true,
        menuWidth: 160,
        translate: false,
        editBtn: false,
        addBtn: false,
        refreshBtn: false,
        searchShowBtn: false,
        columnBtn: false,
        copyBtn: false,
        delBtn: false,
        column: [
          // {
          //   label: "讲师名称",
          //   prop: "teacher",
          //   border: true,
          //   type: "select",
          //   span: 24,
          //   props: {
          //     label: "name",
          //     value: "_id",
          //   },
          //   span: 24,
          //   slot: true,
          //   dicUrl: "teachers/option",
          //   filterable: true,
          //   searchFilterable: true,
          //   search: true,
          // },

          {
            label: "客户名称",
            prop: "customer",
            border: true,
            type: "select",
            props: {
              label: "name",
              value: "_id",
            },
            span: 24,
            width: 300,
            slot: true,
            dicUrl: "customers/option",
            filterable: true,
            searchFilterable: true,
            search: true,
          },
          {
            label: "终端客户",
            prop: "terminal_name",
            border: true,
            span: 24,
            slot: true,
            width: 150,
          },

          // {
          //   label: "终端客户",
          //   prop: "category",
          //   border: true,
          //   span: 24,
          //   slot: true,
          //   display:false
          // },

          {
            label: "负责人",
            prop: "user",
            border: true,
            type: "select",
            props: {
              label: "realname",
              value: "_id",
            },
            span: 24,
            slot: true,
            dicUrl: "users/leader/option",
            searchFilterable: true,
            search: true,
          },

          // {
          //   label: "上课日期",
          //   prop: "times",
          //   border: true,
          //   span: 24,
          //   slot: true,
          //   width: 100,
          // },
          {
            label: "合同签订时间",
            prop: "contract_signing_time",
            border: true,
            span: 24,
            slot: true,
            width: 100,
            // search: true,
          },
          {
            label: "总金额",
            prop: "order_amount",
            border: true,
            span: 24,
          },
          {
            label: "已收款",
            prop: "received",
            border: true,
            span: 24,
          },
          {
            label: "未收款",
            prop: "remain",
            border: true,
            span: 24,
          },
          {
            label: "状态",
            prop: "status",
            type: "select",
            border: true,
            span: 24,
            slot: true,
            search: true,
            dicData: [
              {
                label: "已生成",
                value: 1,
              },
              {
                label: "未生成",
                value: 2,
              },
            ],
          },
          {
            label: "分组",
            prop: "group",
            search: true,
            searchslot: true,
            showColumn: false,
            hide: true,
            display: false,
          },
        ],
      },
    };
  },
  computed: {
    expandArr() {
      return (arr, key) => {
        const str = arr
          .map((v) => v[key])
          .filter((v) => v)
          .join(",");
        console.log(str);
        return str;
      };
    },
  },
  methods: {
    formatDate,

    async changPage({ pageSize, currentPage }) {
      this.dataQuery.page = currentPage;
      this.dataQuery.limit = pageSize;
      this.fetchData();
    },
    selectionChange(vals) {
      this.select_list = vals;
    },
    sign_info(row) {
      return row.contract_signing_time.reduce((str, cur) => {
        return (str += `${cur.post}-${formatDateT(cur.time)} \n`);
      }, "");
    },

    downloadWord(row) {
      let name = row.terminal_name;
      if (this.dataQuery.group === "customer-user") {
        name = row.terminal_names.join(",");
      }

      Docxer.downloadSignle(
        {
          company_name: row.customer.trim(),
          remain: row.remain,
          banks: name,
        },
        `${row.customer}.docx`
      ).then(() => {
        this.batchUpdate(row.schedule_ids);
        this.$message.success("下载成功");
      });
    },

    async batchDownload() {
      if (!this.select_list.length) {
        return this.$message.error("请选择档期");
      }

      const list = _.groupBy(this.select_list, "customer");

      let result = [];

      for (let key in list) {
        let banks = list[key]
          .reduce((arr, cur) => {
            let name = cur.terminal_name;
            if (this.dataQuery.group === "customer-user") {
              name = cur.terminal_names.join(",");
            }
            let has = arr.find((v) => v === name);
            return (arr = arr.concat(has ? null : name));
          }, [])
          .filter((v) => v)
          .join("、");
        let item = {
          filename: key.trim() + ".docx",
          data: {
            remain: list[key].reduce((total, cur) => {
              return (total += cur.remain);
            }, 0),
            company_name: key.trim(),
            banks,
          },
        };
        result.push(item);
      }

      const loading = this.$loading({
        lock: true,
        text: "文件下载中... 0%",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      await Docxer.batchDownload(
        result,
        ({ val }) => {
          loading.text = `文件下载中... ${val}%`;
        },
        () => {
          loading.text = "压缩中";
        }
      );

      console.timeEnd("download");

      loading.close();

      this.select_list = [];

      this.$refs.crud.selectClear();

      this.$message.success("下载成功");

      const ids = Object.keys(list).reduce((arr, cur) => {
        const l = list[cur].map((v) => v.schedule_ids);
        return (arr = arr.concat(...l));
      }, []);

      this.batchUpdate(ids);
    },

    async batchUpdate(ids) {
      const { data } = await this.$http.post("schedules/batch/updateDocx", {
        ids,
      });

      this.fetchData();
    },

    search(val, done) {
      this.dataQuery.customer = val.customer || "";
      this.dataQuery.teacher = val.teacher || "";
      this.dataQuery.user = val.user || "";
      this.dataQuery.status = val.status;
      this.dataQuery.page = 1;
      done();
      this.fetchData();
    },
    changeGroup(val) {
      this.dataQuery.group = val;
      this.fetchData();
    },

    // 获取课件管理
    async fetchData() {
      this.loading = true;
      const { data } = await this.$http.get("schedules/dunning/list", {
        params: {
          ...this.dataQuery,
        },
      });
      this.page.total = data.total;
      this.dunnData = data.data;
      this.loading = false;
    },
  },

  created() {
    // this.fetchData();
  },
};
</script>

<style>
.courseware .text-truncate {
  color: #409eff;
}

.group-radio .el-radio {
  margin-bottom: 0;
}
</style>
