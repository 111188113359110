import OSS from "ali-oss";
import { http } from "./axios";
import * as zip from "jszip";
import { saveAs } from "file-saver";
import { saver } from "./saver";

export class UploadOss {
  constructor() {
    this.client = null;
    this.size = 2;
  }
  getCredential = async () => {
    const { data } = await http.get("credential", {
      headers: {
        showLoading: false,
      },
    });
    const { region, accessKeyId, accessKeySecret, bucket, stsToken } = data;
    this.client = new OSS({
      region: region,
      accessKeyId: accessKeyId,
      accessKeySecret: accessKeySecret,
      stsToken: stsToken,
      bucket: bucket,
      timeout: 300000,
      secure: true,
    });
  };
  async getBuffer(url, callback) {
    await this.getCredential();
    const key = url.replace(
      "http://worthdaily-app.oss-cn-hangzhou.aliyuncs.com/",
      ""
    );

    const result = await this.client.get(key);
    callback && callback(result.content);
    return result.content;
  }

    // async batchDownload(urls, progress, done) {
    //   const total_byte = urls.length * 100;

    //   const fetchUrls=this.splitArrayBySize(urls,this.size)
    //   let result = 0;
    //   const jsZip = new zip();
    //   for (let url of urls) {
    //     console.log(url);
    //     const buffer = await this.getBuffer(url.url);
    //     jsZip.file(url.filename, buffer);
    //     result += 1;
    //     progress &&
    //       progress({
    //         total: urls.length,
    //         now: result,
    //         val: Math.floor((result / urls.length) * 100),
    //       });
    //   }

    //   done && done();

    //   const blob = await jsZip.generateAsync({
    //     type: "blob",
    //     compression: "DEFLATE",
    //     compressionOptions: {
    //       level: 1,
    //     },
    //   });
    //   saveAs(blob, `${Date.now()}.zip`);
    // }

  async batchDownload(urls, done,error,dirname) {
    const fetchUrls = this.splitArrayBySize(urls, this.size);
    let result = 0;
    return  saver.downloadCourses(urls,done,error,dirname)

    const jsZip = new zip();
    for (let item_urls of fetchUrls) {
      await Promise.all(
        item_urls.map((v) => {
          return this.getBuffer(v.url, (buffer) => {
            jsZip.file(v.filename, buffer);
            result += 1;
            progress &&
              progress({
                total: urls.length,
                now: result,
                val: Math.floor((result / urls.length) * 100),
              });
          });
        })
      );
    }

    done && done();

    const blob = await jsZip.generateAsync({
      type: "blob",
      compression: "DEFLATE",
      compressionOptions: {
        level: 1,
      },
    });
    saveAs(blob, `${Date.now()}.zip`);
  }

  splitArrayBySize(array, size) {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }
    return result;
  }

  async donwloadSingleFile(url, filename) {
    const buffer = await this.getBuffer(url);
    let blob = new Blob([buffer]);

    saveAs(blob, `${filename}`);
  }

  

}

export const ossClient = new UploadOss();
