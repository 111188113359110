<template>
    <div class="notice-list w-100 h-100 card-2">
        <avue-crud class="h-100 notice-table" ref="flie_crud" @sort-change="sortChange" @row-del="remove"
            @row-save="create" @row-update="update" :data="noticeData" :option="noticeOption" :page.sync="page"
            @on-load="changPage">

            <template slot="name" slot-scope="{ row }">
                <el-tooltip effect="dark" :content="row.name" placement="top-start">
                    <!-- <div class="text-primary">{{ row.name }}</div> -->
                    <div class=" text-primary cursor-pointer w-100">
                        {{ row.name }}
                        <el-button type="text" class="mx-2" @click="openImage(row)" icon="el-icon-view"></el-button>
                    </div>
                </el-tooltip>
            </template>
        </avue-crud>
    </div>
</template>

<script>

export default {
    data() {
        return {
            dataQuery: {
                name: "",
            },
            sortWhere: {},
            noticeData: [],
            noticeOption: {
                translate: false,
                refreshBtn: false,
                searchShowBtn: false,
                columnBtn: false,
                column: [
                    {
                        label: "通知类型",
                        prop: "type",
                        border: true,
                        type: "radio",
                        dicData: [
                            {
                                label: "一般",
                                value: 'usually',
                            },
                            {
                                label: "重要",
                                value: 'important',
                            },
                        ],
                        span: 24,
                        slot: true,
                        width: 100,
                        rules: [
                            {
                                required: true,
                                message: "请选择通知类型",
                                trigger: "blur",
                            },
                        ],
                    },
                    {
                        label: "通知名称",
                        prop: "name",
                        span: 24,
                        slot: true,
                        width: 300,
                        sortable: true,
                        rules: [
                            {
                                required: true,
                                message: "请填写通知名称",
                                trigger: "blur",
                            },
                        ],
                    },
                    {
                        label: "通知内容",
                        prop: "content",
                        type: "textarea",
                        span: 24,
                        minRows: 2,
                        slot: true,
                        width: 200,
                        showColumn: false,
                        sortable: true,
                        hide: true,
                        rules: [
                            {
                                required: true,
                                message: "请填写通知内容",
                                trigger: "blur",
                            },
                        ],
                    },
                    {
                        label: "通知图片",
                        prop: "urls",
                        multiple: true,
                        type: "upload",
                        listType: "picture-card",
                        action: "upload",
                        dataType: "array",
                        span: 24,
                        hide: true,
                        showColumn: false,
                        accept: "image/*"
                    },
                ],
            },
            page: {
                total: 0,
                currentPage: 1,
                pageSize: 10,
                pageSizes: [10, 20, 30, 40, 50],
            },
        }
    },
    methods: {
        changPage() {

        },
        openImage(row) {
            let imgs=row.urls.map(v=>{
                return {
                    url:v,
                }
            })
            // this.$ImagePreview(imgs, 0, {
            //     closeOnClickModal: true,
            //     interval: 1000,
        // });

            window.open(imgs[0].url)
        },
        async fetchData() {
            const { data } = await this.$http.get("notice", {
                params: {
                    ...this.page,
                    where: this.dataQuery,
                    sort: this.sortWhere
                },
            });
            this.page.total = data.total;
            this.noticeData = data.data;
        },
        sortChange(e) {
            const { order, prop } = e;
            this.sortWhere = {
                _id: order == "ascending" ? 1 : -1,
            };
            this.fetchData()
        },
        async create(row, done) {
            await this.$http.post("notice", row);
            this.$message.success("创建成功");
            this.fetchData();
            done();
        },
        async remove(row) {
            await this.$confirm("是否确认删除此数据？");
            await this.$http.delete(`notice/` + row._id);
            this.$message.success("删除成功");
            this.fetchData();
        },
        async update(row, index, done) {
            console.log(row)
            if (!row.urls) {
                row.urls = [];
            }
            await this.$http.put(`notice/${row._id}`, row);
            this.$message.success("更新成功");
            this.fetchData();
            done();
        },
    },
    created() {
        this.fetchData()
    }
}

</script>

<style>
.notice-table .el-card__body {
    display: flex;
    flex-direction: column;

}

.notice-table .el-form {
    flex: auto
}

.el-carousel__item.is-active.is-animating {
    overflow: scroll;
}

.notice-list .avue-crud__pagination {
    padding: 0 !important;
}
</style>