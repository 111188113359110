<template>
  <avue-crud
    :data="data"
    :option="option"
    @row-save="create"
    @row-update="update"
    @row-del="remove"
    :page.sync="page"
    @on-load="changPage"
    @search-change="search"
  >
    <template #company="{ row }">
      <el-tooltip effect="dark" :content="row.company" placement="top-start">
        <div class="text-truncate cursor-pointer">
          <span>{{ row.company }}</span>
        </div>
      </el-tooltip>
    </template>
    <template #address="{ row }">
      <el-tooltip effect="dark" :content="row.address" placement="top-start">
        <div class="text-truncate cursor-pointer">
          <span>{{ row.address }}</span>
        </div>
      </el-tooltip>
    </template>
  </avue-crud>
</template>

<script>
let baseUrl = "https://cli.avuejs.com/api/area";
export default {
  data() {
    return {
      query: {
        limit: 10,
        // sort: { createdAt: -1 },
      },
      page: {
        total: 0,
      },
      data: [],
      option: {
        border: true,
        searchMenuSpan: 5,
        menuWidth: 160,
        index: true,
        indexLabel: "序号",
        translate: false,
        align: "center",
        column: [
          {
            label: "开票公司",
            prop: "company",
            search: true,
            span: 12,
            slot: true,
            rules: [
              {
                message: "请输入开票公司",
                required: true,
                trigger: "blur",
              },
            ],
          },

          // {
          //   label: "词典类型",
          //   prop: "type",
          // },

          {
            label: "税号",
            prop: "duty_paragraph",
            span: 12,
            width:200,
          },
          {
            label: "城市",
            prop: "city",
            type: "cascader",
            filterable: true,
            span: 12,
            width:150,

            props: {
              label: "value",
              value: "value",
            },
            formatter: (row, value) => value && value.join("-"),
            dicUrl: "getCity",
            checkStrictly: true,
          },
          {
            label: "详细地址",
            prop: "address",
            slot: true,
            span: 12,
          },
          {
            label: "联系人",
            prop: "contact",
            span: 12,
          },
          {
            label: "手机号",
            prop: "mobile",
            span: 12,
            width:100,


          },
          {
            label: "开户行",
            prop: "bank",
            span: 12,
            width:200,
          },
          {
            label: "开户行账号",
            prop: "account",
            span: 12,
            width:200,

          },
          {
            label: "专票税率",
            type: "number",
            prop: "special_ticket",
            rules: [
              {
                required: true,
                message: "请输入专票税率",
                trigger: "blur",
              },
            ],
            span: 12,
          },
          {
            label: "普票税率",
            type: "number",
            prop: "general_ticket",
            span: 12,
          },
          {
            label: "电子普票税率",
            type: "number",
            prop: "electronic_ticket",
            span: 12,
          },
          {
            label: "电子专票税率",
            type: "number",
            prop: "electronic_special_ticket",
            span: 12,
          },
        ],
      },
    };
  },
  methods: {
    async search(where, done) {
      done();
      if (where.company) {
        where.company = { $regex: where.company };
      }
      this.query.where = where;
      this.fetch();
    },
    async changPage({ pageSize, currentPage }) {
      this.query.page = currentPage;
      this.query.limit = pageSize;
      this.fetch();
    },
    async fetch() {
      const res = await this.$http.get("invoices", {
        params: {
          query: this.query,
        },
      });
      this.page.total = res.data.total;
      this.data = res.data.data;
    },
    async remove(row) {
      try {
        await this.$confirm("是否确认删除？");
      } catch (e) {
        return;
      }
      await this.$http.delete(`invoices/${row._id}`);
      this.$message.success("删除成功");
      this.fetch();
    },
    async update(row, index, done) {
      const data = JSON.parse(JSON.stringify(row));
      delete data.$index;
      delete data.$grade;
      await this.$http.put(`invoices/${row._id}`, data);
      this.$message.success("更新成功");
      this.fetch();
      done();
    },
    async create(row, done) {
      await this.$http.post("invoices", row);
      this.$message.success("创建成功");
      this.fetch();
      done();
    },
  },
  created() {
    this.fetch();
  },
};
</script>

<style>
</style>