import axios from "axios";

const http = axios.create({
  baseURL:process.env.VUE_APP_API_URL,
});

http.interceptors.response.use(res => {
  return res
}, err => {
  console.log(err.response)
})

export default http;