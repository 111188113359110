<template>
  <div class="expand_text">
    <ellipsis
      v-if="ellipsis"
      :content="info"
      :rows="1"
      @click-btn="hideMore(false)"
      class="w-100 text-center"
    />
    <div class="ellipsis-content fs-9" v-else>
      {{ info }}
      <span class="hide_btn" @click.stop="hideMore(true)">折叠</span>
    </div>
  </div>
</template>
  <script>
import ellipsis from "vue-overflow-ellipsis";
import "vue-overflow-ellipsis/dist/index.css";
export default {
  name: "DpTextEllipsis",
  components: {
    ellipsis,
  },
  props: {
    info: {
      type: String,
      default: "",
    },
    lineClamp: {
      type: Number,
      default: 3,
    },
    hiddenBtn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showall: false,
      ellipsis: true,
    };
  },

  methods: {
    hideMore(ellipsis) {
      this.ellipsis = ellipsis;
    },
  },
};
</script>
 <style>
.ellipsis-content {
}
.expand_text .real-box{
  text-align: left;
}
.ellipsis-content .hide_btn {
  color: rgba(64, 158, 255, 1);
  text-decoration: underline;
  cursor: pointer;
}
</style>
  