<template>
  <div class="change-pw">
    <div class="head">
      <div class="d-flex justify-content-between px-5" style="margin: auto">
        <div>
          <img
            class="pr-3"
            src="../assets/images/logo.png"
            alt=""
            width="120px"
          />
          <span class="border-left pl-3 fs-8">修改密码</span>
        </div>

        <div class="d-flex">
          <span class="pr-3 fs-7">{{ user.mobile }}</span>
          <span class="text-primary fs-8 cursor-pointer" @click="pre">返回</span>
        </div>
      </div>
    </div>

    <div class="setting-card mt-3" style="width: 46%; margin: auto">
      <div class="font-weight-bold text-center pb-4">修改密码</div>

      <el-form class="input-box"  :model="user" :rules="rules"  ref="user">
        <el-form-item label="用户名:" class="d-flex">
          <div class="fs-7">
            {{ user.username }}
          </div>
        </el-form-item>

        <el-form-item label="新密码:" prop="password" class="d-flex">
          <el-input
            v-model="user.password"
            placeholder="设置密码：6-16位字符"
            maxLength="16"
            type="password"
          ></el-input>
        </el-form-item>

        <el-form-item label="确认密码:" prop="password2" class="d-flex">
          <el-input
            v-model="user.password2"
            placeholder="请输入密码"
            maxLength="16"
            type="password"
          ></el-input>
        </el-form-item>

        <div>
          <el-button type="primary mt-3" @click="confirm('user')" class="w-100"
            >保存</el-button
          >
        </div>
        <div>
          <el-button class="w-100 mt-3" @click="$router.go(-1)">取消</el-button>
        </div>
      </el-form>
    </div>
<!-- 
    <div class="fs-8 pb-4 foot">
      <el-divider content-position="center">沃盟经纪</el-divider>
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      isGettingCode: false,
      countDown: 60,
      formName: "",
      dialogName: false,
      rules: {
        password2: [{ validator: this.checkPass2 }],
        password: [{ validator: this.checkPass }],
      },
    };
  },

  methods: {
    checkPass2(rule, value, callback) {
      if (this.user.password !== this.user.password2) {
        callback(new Error("两次输入的密码不一致"));
      } else {
        callback();
      }
    },
    checkPass(rule, value, callback) {
      if (!value) {
        callback(new Error("请设置正确格式的密码"));
      } else if (value.length < 6) {
        callback(new Error("密码长度最小6位"));
      } else {
        callback();
      }
    },

    async confirm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const res = await this.$http.post("auth/mine/reset/", {
            userId: this.user._id,
            password: this.user.password,
          });
          const { token, user } = res.data;
          localStorage.token = token;
          localStorage.userId = user._id;
          this.$router.push("/login");
          this.$message.success("修改成功,请重新登录");
        } else {
          return false;
        }
      });
    },

    pre() {
      this.$router.go(-1)
    },
  },
  created() {
    this.$store.dispatch("fetchUser");
  },
};
</script>

<style>
@media only screen and (min-width: 768px) {
 .setting-card{
    width: 50% !important;
    padding: 2rem 4rem !important;
  }
}
@media (max-width: 667px) {
  .setting-card{
    width: 92% !important;
    padding: 2rem 2rem !important;
  }
}
.change-pw .el-form-item__label {
  /* margin-right: 0rem; */
  width: 100px;
}
.change-pw .el-form-item__content {
  width: 100%;
}
.head {
  height: 4rem;
  box-shadow: 0 -1px 6px 0 rgba(0, 0, 0, 0.04),
    0 10px 20px 0 rgba(0, 0, 0, 0.04);
  line-height: 4rem;
}

.setting-card {
  box-shadow: 0 -1px 6px 0 rgba(0, 0, 0, 0.04),
    0 10px 20px 0 rgba(0, 0, 0, 0.04);
  padding: 2rem 6rem;
  border-radius: 0.6rem;
}

.foot {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
</style>
