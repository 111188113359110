<template>
  <div class="sort rank_sort ml-2">
    <span  @click="asc" class="sort_content">
    <el-icon
      class="el-icon-caret-top sort_select"
      :class="{ active: value === 1 }"
     
    ></el-icon></span>

    <span   @click="desc" class="sort_content">
        <el-icon
      class="el-icon-caret-bottom  sort_select"
      :class="{ active: value === -1 }"
    
    ></el-icon>
    </span>
  
   
  </div>
</template>
<script>
export default {
  data() {
    return {
      sort: 1,
    };
  },
  props: {
    value: {
      type: Number,
      default: 0,
    },
  },
  model: {
    // 建议显示把这个写上
    event: "update:value",
    prop: "value",
  },
  methods: {
    asc() {
        console.log("asc")
      this.$emit("update:value", 1);
    },
    desc() {
        console.log("desc")
      this.$emit("update:value", -1);
    },
  },
};
</script>

<style scoped>
.rank_sort {
  display: inline-flex;
  flex-direction: column;
  font-size: 12px;
  cursor: pointer;
}

.sort_content{
    width: 12px;
    height: 12px;
    display: flex;
    align-items: center;
}

/deep/.sort_select.active {
  color: #409eff;
}
</style>
