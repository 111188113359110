<template>
  <div class="login">
    <div class="login-wrapper">
      <div class="text-center pt-4 pb-5">
        <img width="120px" src="../assets/images/login-logo.png" alt="" />
        <div class="pt-3">师资管理系统 | 用户登录</div>
      </div>
      <avue-login :option="option" @submit="submit"> </avue-login>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      option: {
        width: 600,
        column: {
          username: {
            // hide: true,
            label: "用户名",
            prop: "username",
            // placeholder:'',
            autocomplete: "off",
            rules: { required: true, message: "请输入用户名", trigger: "blur" },
          },
          password: {
            label: "密码",
            prop: "password",
            // placeholder:'',
            autocomplete: "off",
            rules: { required: true, message: "请输入密码", trigger: "blur" },
          },
          code: {
            hide: true,
          },
        },
      },
    };
  },
  methods: {
    async submit(form) {
      let res = await this.$http.post("auth/login", form);
      const { token, user } = res.data;
      // this.updateAuth(token);
      this.$store.commit("setUser", user);
      this.$store.commit("setToken", token);
      localStorage.setItem("token", token);
      localStorage.setItem("user", JSON.stringify(user));
      const params={
        user:user._id,
        action:"login",
      }
      this.addBehavior(params)
      if ([2, 3, 7].includes(this.user.role)) {
        this.$router.push("/schedules/list");
      } else if (this.user.role == 6) {
        this.$router.push("/teachers/list");
      } else {
        this.$router.push("/");
      }
      this.$message.success("登录成功");
    },
  },
};
</script>

<style>
@media (max-width: 667px) {
  .avue-login {
    width: auto !important;
  }
  .login {
    min-height: 0 !important;
    padding-bottom: 0 !important;
  }
  .login-wrapper {
    width: 300px !important;
  }
}
.el-message {
  z-index: 9999 !important;
}

.avue-login {
  display: flex;
  align-items: center;
  justify-content: center;
}

.login {
  position: absolute;
  min-height: 730px;
  width: 100%;
  height: 100%;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f7f7;
  padding-bottom: 3rem;

  /* background: url("../assets/images/bg.png") no-repeat top center; */
  /* background-size: auto auto; */
}

.login-wrapper {
  width: 680px;
  height: 420px;
  background-color: #fff;
  box-shadow: 0 -1px 6px 0 rgba(0, 0, 0, 0.04),
    0 10px 20px 0 rgba(0, 0, 0, 0.04);
  border-radius: 0.6rem;
  overflow: hidden;
  padding: 1rem;
}
</style>
