<template>
  <el-select
    size="mini"
    multiple
    filterable
    :disabled="disabled"
    :value="value"
    placeholder="请选择"
    @input="changeSelect"
  >
    <el-checkbox v-model="checked" @change="selectAll">全选</el-checkbox>
    <el-option
      v-for="item in selectOptions"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    ></el-option>
  </el-select>
</template>

<script>
export default {
  data() {
    return {
      checked: false,
      selectedArr: [],
    };
  },
  model: {
    prop: "value", // 对应 props msg
    event: "update",
  },
  props: {
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    // 选项
    selectOptions: {
      type: Array,
      default() {
        return [];
      },
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false,
    },
    filterable: {
      type: Boolean,
      default: false,
    },
    // 已选中选项
    mulSelecteds: {
      type: Array,
      default() {
        return [];
      },
    },
    mulSelectLoading: {
      type: Boolean,
      default: false,
    },
    collapseTags: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    clear(){
      this.selectedArr=[];
      this.$emit("update", []);
      this.checked=false;
    },
    selectAll() {
      let selectedArr = [];
      if (this.checked) {
        this.selectOptions.forEach((item) => {
          selectedArr.push(item.value);
        });
      } else {
        selectedArr = [];
      }
      this.$emit("update", selectedArr);
    },
    changeSelect(val) {
      if (val.length === this.selectOptions.length) {
        this.checked = true;
      } else {
        this.checked = false;
      }
      this.$emit("update", [...val]);
    },
  },
  watch: {
    value: {
      deep: true,
      handler(val) {
        if (val.length === this.selectOptions.length) {
          this.checked = true;
        }
      },
      immediate:true,
    },
  },
};
</script>

<style scoped>
.el-checkbox {
  text-align: right;
  width: 90%;
}
</style>
