<template>
  <div>
    <import :doImport="doImport" :parseItems="parseItems"></import>
    <avue-crud
      :data="data"
      :option="option"
      v-model="form"
      @row-save="create"
      @row-update="update"
      @row-del="remove"
      :page.sync="page"
      @on-load="changPage"
      @search-change="search"
    >
      <template #menu="{ type, size, row }">
        <el-button
          :type="type"
          :size="size"
          icon="el-icon-user"
          @click="login(row._id)"
          >一键登录</el-button
        >
      </template>
    </avue-crud>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        state: true,
      },
      query: {
        limit: 10,
      },
      page: {
        total: 0,
      },
      data: [],
      importData: [],
    };
  },
  computed: {
    option() {
      return {
        border: true,
        index: true,
        searchMenuSpan: 5,
        menuWidth: 160,
        align: "center",
        indexLabel: "序号",
        translate: false,
        column: [
          {
            label: "用户头像",
            prop: "avatar",
            dataType: "string",
            type: "upload",
            listType: "picture-img",
            action: "upload",
            span: 24,
          },
          {
            label: "用户名",
            prop: "username",
            placeholder: "请输入用于登录时的用户名",
            span: 12,
            rules: [
              {
                message: "请输入用户名",
                required: true,
                trigger: "blur",
              },
            ],
          },
          {
            label: "密码",
            prop: "password",
            type: "password",
            span: 12,
            hide: true,
            showColumn: false,
          },
          {
            label: "真实姓名",
            prop: "realname",
            search: true,
            rules: [
              {
                message: "请输入真实姓名",
                required: true,
                trigger: "blur",
              },
            ],
            span: 12,
          },
          {
            label: "角色",
            prop: "role",
            search: true,
            searchFilterable: true,
            props: {
              label: "label",
              value: "value",
            },
            dicUrl: `dictionaries/type/user.role`,
            type: "select",
            span: 12,
            rules: [
              {
                message: "请选择角色",
                required: true,
                trigger: "blur",
              },
            ],
          },
          {
            label: "关联讲师",
            prop: "teacher",
            props: {
              label: "name",
              value: "_id",
            },
            dicUrl: `teachers/option`,
            type: "select",
            span: 12,
            dicQuery: {
              query: {
                limit: 0,
              },
            },
            filterable: true,
            hide: true,
            rules: [
              {
                message: "请选择关联老师",
                required: true,
                trigger: "blur",
              },
            ],
            display: this.form.role == 2 || this.form.role == 3,
          },
          {
            label: "关联客户",
            prop: "customer",
            props: {
              label: "name",
              value: "_id",
            },
            dicUrl: `customers/option`,
            type: "select",
            span: 12,
            dicQuery: {
              query: {
                limit: 0,
              },
            },
            filterable: true,
            hide: true,
            rules: [
              {
                message: "请选择关联客户",
                required: true,
                trigger: "blur",
              },
            ],
            display: this.form.role == 9,
          },
     {
            label: "选择管理的讲师",
            prop: "teachers",
            props: {
              label: "name",
              value: "_id",
            },
            type: "tree",
            multiple: true,
            rules: [
              {
                required: true,
                message: "请选择讲师",
                trigger: "blur",
              },
            ],
            dicUrl: "teachers/option", 
            dicQuery: {
              query: {
                limit: 0,
                // where: {
                //   role: { $in: [1, 4] },
                // },
              },
            },
            hide:true,
            display: this.form.role == 7,
            // dicData: importField,
          },

          {
            label: "组员",
            prop: "userInfo",
            props: {
              label: "realname",
              value: "_id",
            },
            type: "tree",
            multiple: true,
            rules: [
              {
                required: true,
                message: "请选择组员",
                trigger: "blur",
              },
            ],
            dicUrl: "users",
            dicQuery: {
              query: {
                limit: 0,
                where: {
                  role: { $in: [1, 4] },
                },
              },
            },
            hide: true,
            display: this.form.role == 5,
            // dicData: importField,
          },
          {
            label: "小组名称",
            prop: "group_name",
            rules: [
              {
                message: "请输入小组名称",
                required: true,
                trigger: "blur",
              },
            ],
            hide: true,
            display: this.form.role == 5,
            span: 12,
          },
          {
            label: "状态",
            prop: "status",
            type: "radio",
            span: 8,
            rules: [
              {
                required: true,
                message: "请选择状态",
                trigger: "blur",
              },
            ],
            display: this.form.role !== 9,
            dicData: [
              {
                label: "在职",
                value: 1,
              },
              {
                label: "离职",
                value: 2,
              },
            ],
          },
        ],
      };
    },
  },
  methods: {
    async doImport() {
      if (this.importData.length > 0) {
        const loading = this.$loading({
          lock: true,
          text: "导入中，请稍后。请勿关闭刷新页面",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        await this.$http.post("users/import", this.importData);
        loading.close();
        this.fetch();
        this.$message.success("导入成功");
      } else {
        this.$message.error("上传文件为空");
      }
    },
    async parseItems(e) {
      const file = e.target.files[0];
      const { results } = await this.$Export.xlsx(file);
      this.$message.success("上传成功,请导入");
      const items = results.map((v) => {
        return {
          realname: v["真实姓名"],
          username: v["用户名"],
          role: Number(v["角色"]),
          password: v["密码"] + "",
          teacher: v["关联讲师"],
          userInfo: v["组员"],
          group_name: v["小组名称"],
        };
      });
      this.importData = items;
    },

    async search(where, done) {
      done();
      if (where.realname) {
        where.realname = { $regex: where.realname };
      }
      if (where.role) {
        where.role = where.role;
      }
      this.query.where = where;
      this.fetch();
    },
    async changPage({ pageSize, currentPage }) {
      this.query.page = currentPage;
      this.query.limit = pageSize;
      this.fetch();
    },
    async fetch() {
      const res = await this.$http.get("users", {
        params: {
          query: {
            ...this.query,
            where:{
              ...this.query.where,
              // is_delete:0
            }
          },
        },
      });
      this.page.total = res.data.total;
      this.data = res.data.data;
    },
    async remove(row) {
      try {
        await this.$confirm("是否确认删除？");
      } catch (e) {
        return;
      }
      await this.$http.delete(`users/${row._id}`);
      this.$message.success("删除成功");
      this.fetch();
    },
    async update(row, index, done) {
      this.$clear(row,true);
      await this.$http.put(`users/${row._id}`, row);
      this.$message.success("更新成功");
      this.fetch();
      done();
    },
    async create(row, done) {
      this.$clear(row,true);
      try {
      
        await this.$http.post("users/isExist", row);
        await this.$http.post("users", row);
        this.$message.success("创建成功");
        this.fetch();
        done();
      } catch {
        done();
      }
    },
    async login(id) {
      const res = await this.$http.post(`auth/super-login/${id}`);
      const { token, user } = res.data;
      // this.updateAuth(token);
      this.$store.commit("setUser", user);
      this.$store.commit("setToken", token);
      localStorage.setItem("token", token);
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("teacherId", '');
      if (this.user.role == 2 || this.user.role == 3) {
        this.$router.push("/schedules/list");
      } else if (this.user.role == 6) {
        this.$router.push("/teachers/list");
      } else {
        this.$router.push("/");
      }
    },
  },
  created() {
    this.fetch();
  },
};
</script>

<style>
</style>