import Vue from "vue";
import VueRouter from "vue-router";
import Main from "../views/Main.vue";
import Home from "../views/Home.vue";
import CustomerCrud from "../views/customers/CustomerCrud.vue";
import CoursewareCrud from "../views/coursewares/CoursewareCrud.vue";
import BehaviorCrud from "../views/behavior/BehaviorCrud.vue";
import DevelopTeachersCrud from "../views/developTeacher/developTeacherCrud.vue";
import DunningCrud from "../views/dunning/dunningCrud.vue";
import Blacklist from "../views/customers/Blacklist.vue";
import CommonPool from "../views/customers/CommonPool.vue";
import FollowUpList from "../views/customers/FollowUpList.vue";
import DictionaryCrud from "../views/dictionaries/DictionaryCrud.vue";
import TeacherCrud from "../views/teachers/TeacherCrud.vue";
import OrderCrud from "../views/orders/OrderCrud.vue";
import ScheduleCrud from "../views/schedules/ScheduleCrud.vue";
import Report from "../views/schedules/Report.vue";
import Contract from "../views/schedules/Contract.vue";
import SaleContract from "../views/schedules/SaleContract.vue";
import InvoiceCrud from "../views/invoices/InvoiceCrud.vue";
import InvoiceManageCrud2 from "../views/invoices/InvoiceManageCrud2.vue";
import InvoiceManageCrud from "../views/invoices/InvoiceManageCrud.vue";
import AdminUser from "../views/admin-user/AdminUser.vue";
import FinanceList from "../views/finance/FinanceList.vue";
import Login from "../views/Login.vue";
import ChangePw from "../views/ChangePw.vue";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Main,
    children: [
      {
        path: "/",
        component: Home,
      },
      {
        name: "customer-crud",
        path: "/customers/list",
        component: CustomerCrud,
        props: true,
        // meta: {keepAlive: true }
      },
      {
        name: "followUpList",
        path: "/followUp/list",
        component: FollowUpList,
        props: true,
      },
      {
        name: "blacklist",
        path: "/customers/blacklist",
        component: Blacklist,
        props: true,
      },
      {
        name: "commonPool",
        path: "/commonPool/list",
        component: CommonPool,
        props: true,
      },
      {
        name: "order-crud",
        path: "/orders/list",
        component: OrderCrud,
        props: true,
      },
      {
        name: "dictionary-crud",
        path: "/dictionaries/list",
        component: DictionaryCrud,
        props: true,
      },
      {
        name: "teacher-crud",
        path: "/teachers/list",
        component: TeacherCrud,
        props: true,
        meta: { isPartTime: true },
      },
      {
        name: "schedule-crud",
        path: "/schedules/list",
        component: ScheduleCrud,
        props: true,
        meta: { keepAlive: true, isTeacher: true, isPartTime: true },
      },
      {
        name: "report",
        path: "/schedules/report",
        component: Report,
        props: true,
      },

      {
        name: "courseware",
        path: "/courseware/list",
        component: CoursewareCrud,
        meta: { keepAlive: true, isCustomer: true },
        props: true,
      },

      {
        name: "behavior",
        path: "/behavior/list",
        component:BehaviorCrud,
        meta: { keepAlive: true,roles:[0] },
        props: true,
      },


      {
        name: "developTeachers",
        path: "/develop_teacher/list",
        component: DevelopTeachersCrud,
        props: true,
      },
      {
        name: "dunning",
        path: "/dunning/list",
        component: DunningCrud,
        props: true,
      },
      {
        name: "contract",
        path: "/contract/list",
        component: Contract,
        props: true,
      },
      {
        name: "SaleContract",
        path: "/sale_contract/list",
        component: SaleContract,
        props: true,
      },
      {
        name: "admin-user",
        path: "/admin_user/list",
        component: AdminUser,
        props: true,
      },
      {
        name: "invoice-crud",
        path: "/invoice/list",
        component: InvoiceCrud,
        props: true,
      },
      {
        name: "invoice_manage",
        path: "/invoice_manage/list",
        component: InvoiceManageCrud,
        props: true,
      },
      {
        name: "invoice_manage",
        path: "/invoice_manage2/list",
        component: InvoiceManageCrud2,
        props: true,
      },
      {
        name: "finance_list",
        path: "/finance/list",
        component: FinanceList,
        props: true,
      },
      // {
      //   name: 'courses-list',
      //   path: '/courses/list',
      //   component: CourseList
      // },
      // {
      //   name: 'courses-edit',
      //   path: '/courses/edit/:id',
      //   component: CourseEdit,
      //   props: true
      // },
      // {
      //   name: 'courses-create',
      //   path: '/courses/create',
      //   component: CourseEdit
      // }
    ],
  },

  {
    path: "/login",
    name: "login",
    component: Login,
    meta: { isPublic: true },
  },
  {
    path: "/change_pw",
    name: "change_pw",
    component: ChangePw,
    meta: { isTeacher: true, isPartTime: true },
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.isPublic) {
    return next(true);
  }


  if (!to.meta.isTeacher && store.getters.isTeacher) {
    return next("/schedules/list");
  }
  console.log(to)
  if (!to.meta.isPartTime && store.getters.isPartTime) {
    if(to.path==="/courseware/list"){
      return next(true)
    }
    if(to.path=="/customers/blacklist"){
      return next(true)
    }
    return next("/schedules/list");
  }

  if (to.meta.isCustomer && store.getters.isCustomer) {
    return next("/courseware/list");
  }

  const user=store.state.user;

  if(to.meta.roles){
    if(to.meta.roles.includes(user.role)){
      next(true)
    }else{
      next("not_found")
    }
  }
  next(true);
});

export default router;
