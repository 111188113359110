<template>
  <div>
    <div class="pr-3 pb-2 ml-3 contract">
      <el-radio v-model="permission" label="all" border>全部</el-radio>
      <el-radio v-model="permission" label="me" border>仅看自己</el-radio>
    </div>
    <avue-crud
      :data="data"
      :option="options"
      @row-del="remove"
      :page.sync="page"
      v-model="obj"
      @on-load="changPage"
      :upload-preview="preview"
      @search-change="search"
      :before-open="beforeOpen"
    >
      <template slot-scope="scope" slot="contract_name">
        <!-- :content="_.get(scope.row, 'contract_name')" -->
        <el-tooltip
          effect="dark"
          :content="
            `${scope.row.teacher.name}-${scope.row.bank_type || ''}-${
              scope.row.post
            }-${scope.row.terminal_name || ''}`
          "
          placement="top-start"
        >
          <div class="text-truncate3 cursor-pointer">
            <!-- {{ _.get(scope.row, "contract_name") }} -->
            {{
              `${scope.row.teacher.name}-${scope.row.bank_type || ""}-${
                scope.row.post
              }-${scope.row.terminal_name || ""}`
            }}
          </div>
        </el-tooltip>
      </template>

      <template slot-scope="scope" slot="contract_signing_time">
        <!-- :content="_.get(scope.row, 'contract_name')" -->
        <el-tooltip
          effect="dark"
          placement="top-start"
          v-if="scope.row.customer && scope.row.customer.contractTimes"
        >
          <div
            class="text-truncate3 cursor-pointer"
            v-if="scope.row.customer.contractTimes.length"
          >
            <!-- {{ _.get(scope.row, "contract_name") }} -->
            {{ $filters.formatDateT(scope.row.customer.contractTimes[0]) }}
            -
            {{ $filters.formatDateT(scope.row.customer.contractTimes[1]) }}
          </div>
        </el-tooltip>
      </template>

      <template slot="customer" slot-scope="scope">
        <el-tooltip
          effect="dark"
          :content="_.get(scope.row, 'customer.name')"
          placement="top-start"
        >
          <div
            class="text-truncate cursor-pointer"
            @click="showContract(scope.row)"
          >
            {{ _.get(scope.row, "customer.name") }}
          </div>
        </el-tooltip>
      </template>
      <template slot-scope="scope" slot="teacher">
        <div>{{ _.get(scope.row, "teacher.name") }}</div>
      </template>
      <template slot-scope="scope" slot="user">
        <div>{{ _.get(scope.row, "user.realname") }}</div>
      </template>
    </avue-crud>

    <el-dialog
      title="年度合同"
      :visible.sync="isContract"
      width="50%"
      :before-close="closeContrct"
    >
      <div v-if="contract.has" class="p-3">
       <div class="d-flex">
        <div>合同图片:</div>
        <div class="ml-2">
          <el-image
            :preview-src-list="contract.contractImg"
            class="cursor-pointer"
            style="width:150px"
            :src="item"
            v-for="(item, index) in contract.contractImg"
            :key="index"
          ></el-image>
        </div>

       </div>

        <div class="mt-2">
          <span>合同时间:</span>

          <span class='fs-16'>
            {{ $filters.formatDateT(contract.contractTimes[0]) }}
            -
            {{
              $filters.formatDateT(contract.contractTimes[1])
            }}</span
          >
        </div>
      </div>
      <div v-else class='p-3 d-flex justify-content-center'>
        <div class='p-5 fs-1 bold'>暂未上传合同</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isContract = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      obj: {},
      permission: "all",
      filter: {},
      contract: {},
      isContract: false,
      query: {
        populate: ["customer"],
        limit: 10,
        sort: { contract_signing_time: -1 },
        where: {
          contract_number: {
            $ne: null,
          },
          customer: "",
          teacher: "",
          user: "",
        },
      },
      page: {
        total: 0,
      },
      data: [],
    };
  },
  computed: {
    options() {
      return {
        wname: null,
        where: null,
        border: true,
        addBtn: false,
        editBtn: false,
        delBtn: false,
        viewBtn: true,
        header: false,
        searchMenuSpan: 5,
        menuWidth: 160,
        index: true,
        indexLabel: "序号",
        translate: false,
        dialogClickModal: false,
        column: [
          {
            label: "合同编号",
            // search: true,
            prop: "contract_number",
          },
          {
            label: "合同名称",
            prop: "contract_name",
            slot: true,
            width: 500,
          },
          {
            label: "年度合同",
            prop: "contract_signing_time",
            slot: true,
            width: 200,
            display:false,
            showColumn:false,
            hide:true,
          },
          {
            label: "客户名称",
            prop: "customer",
            props: {
              label: "name",
              value: "_id",
            },
            dicUrl: `customers/list`,
            search: true,
            searchFilterable: true,
            slot: true,
            span: 12,
            rules: [
              {
                required: true,
                message: "请选择客户名称",
                trigger: "blur",
              },
            ],
            type: "select",
          },
          {
            label: "讲师",
            prop: "teacher",
            type: "select",
            props: {
              label: "name",
              value: "_id",
            },
            dicUrl: `teachers/option`,
            span: 12,
            slot: true,
            filterable: true,
            searchFilterable: true,
            search: true,
            rules: [
              {
                message: "请选择讲师",
                required: true,
                trigger: "blur",
              },
            ],
          },
          //   {
          //     label: "联系人",
          //     filterable: true,
          //     prop: "contact",
          //     type: "select",
          //     span: 8,
          //     props: {
          //       label: "name",
          //       value: "name",
          //       // res: 'contacts',
          //       // children: 'contacts'
          //     },
          //     dicData: this.contacts,
          //     showColumn: false,
          //   },
          {
            label: "服务类型",
            prop: "service_type",
            span: 8,
          },
          {
            label: "合同金额",
            prop: "order_amount",
            type: "number",
            span: 4,
          },
          {
            label: "签订人",
            props: {
              label: "realname",
              value: "_id",
            },
            prop: "user",
            type: "select",
            dicUrl: `users/option`,
            slot: true,
            search: true,
            searchFilterable: true,
            span: 4,
          },
          {
            label: "合同图片",
            prop: "contract",
            type: "upload",
            dataType: "array",
            listType: "picture-card",
            action: "upload",
            span: 24,
            hide: true,
            showColumn: false,
          },
          {
            label: "合同附件",
            prop: "file",
            type: "upload",
            loadText: "附件上传中，请稍等",
            span: 18,
            showColumn: false,
            action: "upload",
            hide: true,
          },
        ],
      };
    },
  },
  watch: {
    permission() {
      this.fetch();
    },
  },
  methods: {
    beforeOpen(done, type) {
      const customer = this.obj.customer || {};
      const teacher = this.obj.teacher || {};
      const user = this.obj.user || {};
      this.obj.customer = customer._id;
      this.obj.teacher = teacher._id;
      this.obj.user = user._id;
      done();
    },
    showContract(obj) {
      this.isContract = true;
      this.contract = {
        ...obj.customer,
        has: obj.customer?.contractImg?.length > 0,
      };
    },
    closeContrct() {
      this.isContract = false;
    },
    preview(file, column, done) {
      const ext = file.url.split(".").pop();
      if (["jpg", "jpeg", "png", "gif"].includes(ext)) {
        done();
      } else {
        window.open(file.url);
      }
    },
    async changPage({ pageSize, currentPage }) {
      this.query.page = currentPage;
      this.query.limit = pageSize;
      this.fetch();
    },
    async search(where, done) {
      done();
      if (where.contract_number) {
        where.contract_number = { $regex: where.contract_number };
        this.wname = where.contract_number;
        this.where = where.contract_number.$regex;
      }

      where.contract_name = { $ne: null };

      if (where.customer) {
        this.filter.customer = where.customer;
      }

      if (where.user) {
        this.filter.user = where.user;
      }

      if (where.teacher) {
        this.filter.teacher = where.teacher;
      }
      this.query.where = {
        ...this.query.where,
        ...where,
      };
      this.fetch();
    },
    remove() {},
    async update(row, index, done) {
      await this.$http.put(`schedules/${row._id}`, data);
      this.$message.success("更新成功");
      this.fetch();
      done();
    },
    async fetchPermission() {
      if (this.permission !== "all") {
        let where = {};
        where.user = this.user._id;
        where.contract_number = { $ne: null };
        if (this.where) {
          where.contract_number = { $regex: this.where };
        }
        this.query.where = {
          ...this.query.where,
          ...where,
        };
      } else {
        if (!this.where) {
          this.query.where = { contract_number: { $ne: null } };
        }
      }
    },
    async fetch() {
      this.fetchPermission();
      const res = await this.$http.get("contracts", {
        params: {
          query: {
            ...this.query,
            where: {
              ...this.query.where,
              ...this.filter,
            },
          },
        },
      });
      this.page.total = res.data.total;
      this.data = res.data.data;
    },
  },
};
</script>

<style>
.contract .el-radio {
  margin-right: 0 !important;
}
</style>
