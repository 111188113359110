<template>
  <div>
    <el-popover placement="bottom-start" width="100" trigger="click">
      <div v-if="!IsPC" class="jump" slot="reference">
        <i class="iconfont icon-caidan fs-5"></i>
        <div style="line-height: 0px; transform: scale(0.9)">菜单</div>
      </div>
      <div>
        {{$route.path}}
        <div
          @click="$router.push('/')"
          class="
            d-flex
            justify-content-between
            align-items-center
            cursor-pointer
            border-bottom
            pb-2
          "
        >
          <div :class="{ menuActive: $route.path == '/' }">
            <i class="iconfont icon-shouye1 pr-4" style="width: 40px"></i>
            <span>首页</span>
          </div>
          <i class="el-icon-arrow-right"></i>
        </div>
        <div
          @click="$router.push('/customers/list')"
          class="
            d-flex
            justify-content-between
            align-items-center
            cursor-pointer
            border-bottom
            py-2
          "
        >
          <div :class="{ menuActive: $route.path == '/customers/list' }">
            <i class="iconfont icon-kehu pr-4" style="width: 40px"></i>
            <span>客户管理</span>
          </div>
          <i class="el-icon-arrow-right"></i>
        </div>
        <div
          @click="$router.push('/schedules/list')"
          class="
            d-flex
            justify-content-between
            align-items-center
            cursor-pointer
            border-bottom
            py-2
          "
        >
          <div :class="{ menuActive: $route.path == '/schedules/list' }">
            <i class="iconfont icon-rili pr-4" style="width: 40px"></i>
            <span>档期管理</span>
          </div>
          <i class="el-icon-arrow-right"></i>
        </div>
        <div
          @click="$router.push('/teachers/list')"
          class="
            d-flex
            justify-content-between
            align-items-center
            cursor-pointer
            border-bottom
            py-2
          "
        >
          <div :class="{ menuActive: $route.path == '/teachers/list' }">
            <i class="iconfont icon-jiangshi pr-4" style="width: 40px"></i>
            <span>讲师管理</span>
          </div>
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
    </el-popover>
  </div>
</template>

<script>
export default {
  props: {
    // parseItems:Function,
    // doImport:Function
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style>
</style>