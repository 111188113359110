<template>
  <div class="w-100  schedule_board " :class="{'fs-10':!IsPC}">
    年排课：<span class="count">{{ info.course_days }}天</span>，
     已实施：<span class="count">{{ info.implement_count }}天</span>，
    待实施：<span class="count">{{ info.un_implement_count }}天</span>，
    预约：<span class="count">{{ info.reserve_count }}天</span><span v-if="IsPC">，</span>
    <span  v-if="IsPC"> 已回款：<span class="count">{{ $filters.formatMoneyW(info.received_count) }}</span></span>
    . <span v-if="IsPC">未回款：<span class="count">{{ $filters.formatMoneyW(info.remain_count) }}</span></span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          key: "course_days",
          name: "排课天数",
          count: 2,
          unit: "天",
        },
        {
          key: "reserve_count",
          name: "已预约",
          count: 2,
          unit: "个",
        },
        {
          key: "implement_count",
          name: "已实施",
          count: 2,
          unit: "个",
        },
        {
          key: "un_implement_count",
          name: "未实施",
          count: 2,
          unit: "个",
        },
        {
          key: "received_count",
          name: "已回款",
          count: 2,
          unit: "￥",
        },
        {
          key: "remain_count",
          name: "未回款",
          count: 2,
          unit: "￥",
        },
      ],

      info: {},

     
    };
  },
  props: {
    user_id: String,
  },
  
  watch: {
    user_id() {
      if (this.user_id) {
        this.fetchData();
      }
    },
  },

  methods: {
    async fetchData() {
      if (!this.user_id.trim()) {
        return false;
      }

      const { data } = await this.$http.get(
        "teachers/schedule_data/" + this.user_id
      );

    this.info = data;

      Object.keys(data).forEach((key) => {
        const index = this.list.findIndex((v) => v.key == key);
        if (index > -1) {
          this.$set(this.list, index, {
            ...this.list[index],
            count: data[key],
          });
        }
      });
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<style>
@media only screen and (max-width: 768px) {
  .teacher-board .item .content .count {
    font-size: 15px;
  }

  .teacher-board .item .content .unit-text {
    font-size: 12px;
  }

  .teacher-board .name {
    font-size: 12px;
    /* font-weight: bold; */
  }
}
.schedule_board {
  font-size: 14px;
  text-align:left;
}

.teacher-board .item {
  width: 18%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0rem;
}

.teacher-board .item .content {
  display: flex;
  align-items: flex-end;
}

.count {
  color: #3a87ad !important;
}

.teacher-board .item .content .unit-text {
  font-size: 14px;
}

.teacher-board .name {
  margin-top: 0.8rem;
  font-size: 14px;
  /* font-weight: bold; */
}
.teacher-board .item .content .count {
  margin: 0 0.2rem;
  color: #409eff;
  font-size: 26px;
}
</style>
