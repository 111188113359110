<template>
  <div>
    <vue-editor
      :value="value"
      @input="(val) => getContent(val)"
      useCustomImageHandler
      @image-added="handleImageAdded"
    ></vue-editor>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
export default {
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  components: {
    VueEditor,
  },
  data() {
    return {
      //   content: "<h1>Some initial content</h1>",
    };
  },
  methods: {
    getContent(val) {
      this.$emit("input", val);
    },
    async handleImageAdded(file, Editor, cursorLocation) {
      var formData = new FormData();
      formData.append("file", file);
      const res = await this.$http.post("/upload", formData);
      Editor.insertEmbed(cursorLocation, "image", res.data.url);
      //获取光标位置
      let length = Editor.selection.savedRange.index;
      //把光标位置 移动内容后面
      Editor.setSelection(length + 1);
      this.$message.success("上传成功");
    },
  },
};
</script>

<style>
</style>